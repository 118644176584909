export const ButtonBasicShadow = ({
  align = "none",
  fullWidth = true,
  label = "",
  onClick = () => true,
  icon = null,
  classes = "",
  color = "text-blue-600",
}) => {
  const alignClasses = () => {
    if (align === "left") return "justify-start";
    if (align === "right") return "justify-end";
    return;
  };
  return (
    <button
      className={`${fullWidth ? "w-full sm:px-10" : "w-auto"}
    flex items-center h-10 xl:h-10 rounded-4xl shadow-straight xl:shadow-straight-lg ${color} ${classes} ${alignClasses()}
    `}
      label={label}
      onClick={onClick}
    >
      <span className="text-base flex justify-start space-x-2">
      <div className="line-clamp-1 text-left">
          {label}
        </div>
        <div className="w-6">{icon}</div>
      </span>
    </button>
  );
};
