import React from "react";
import { ProviderLogo } from "./ProviderLogo";
import { DealLink } from "./DealLink";
import { DealDetail } from "./DealDetail";
import { formatPrice } from "../../utils/formatPrice";
import PartnerRate from "../../models/availability/PartnerRate";
export const CheapestRate = ({
  rate = new PartnerRate(),
  buttonHover = true,
  nights = 1
}) => {
  return (
    <div className="flex flex-col justify-end xl:h-full relative">
      <div className="flex justify-between w-full z-20">
        <div className="flex flex-col items-start justify-center space-y-1">
          <DealDetail
            price={formatPrice(Math.round(rate.priceTotalStay))}
            cheapest={true}
            priceNight={formatPrice(Math.round(rate.pricePerNight))}
            nights={nights}
          />
          <ProviderLogo partner={rate.partner} bounding={false} />
        </div>
        <DealLink
          provider={rate.partner}
          rate={rate.priceTotalStay}
          cheapest={true}
          url={rate.url}
          buttonHover={buttonHover}
        />
      </div>
    </div>
  );
};
