import { isMobile } from "react-device-detect";
import Accordian from "../Accordion/Accordian";
import { perksJs } from "../../config/nft";
interface IPerks {
  imageUrl: string;
  imageAlt: string;
}

const Perks: React.FC<IPerks> = ({ imageUrl, imageAlt }) => {
  return (
    <div
      id="nextSection"
      className="px-5 sm:px-10 xl:px-0 max-w-xxl mx-auto mt-10 mb-20"
    >
      <div className="flex sm:px-20 mb-sm">
        <p className="text-5xl">
          <span className="text-black font-medium">{"Perks "}</span>
          <span className="text-peakahBlue font-medium">{"& "}</span>
          <span className="text-peakahGreen font-medium">{"Benefits "}</span>
        </p>
      </div>

      <div className="sm:px-20">
        <div className="flex flex-col md:justify-between lg:flex-row lg:p-5 py-6 bg-white shadow-light rounded-4xl">
          <div className="flex flex-col lg:space-y-2 lg:w-1/2 px-5 sm:px-10">
            {perksJs.map((callout) => (
              <Accordian
                heading={callout.heading}
                description={callout.description}
                colour="bg-brightBlue"
                key={callout.heading}
              />
            ))}
          </div>
          <div className="flex flex-col lg:w-1/2 lg:-mt-32 -mb-40 lg:mb-0 pt-6 lg:pt-0 items-center justify-center">
            <img
              src={imageUrl}
              alt={imageAlt}
              height={isMobile ? "300" : "800"}
            />
            <a
              href="https://magic-cup-709.notion.site/Peaky-Birds-Litepaper-38031e84c2dd48c8ad77c8a786f208f7"
              target="_blank"
              rel="noopener noreferrer"
              className="border border-peakahGreen p-2 rounded-2xl gap-2 text-center text-peakahGreen font-medium hover:opacity-90 hover:shadow-lg md:hover:scale-105 duration-500"
            >
              Read full Litepaper!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perks;
