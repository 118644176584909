import { ButtonBasic } from "../../Elements/Button/ButtonBasic";
import { IconClose } from "../../Elements/Icon/IconClose";
import { BottomStickySlideIn } from "../BottomStickySlideIn";
import { TopBarSlideIn } from "../TopBarSlideIn";
import { ModalContents } from "./ModalContents";
export const FullScreenModal = ({
  bottomStickyPosition,
  contents,
  enabled = false,
  onClickLastPosition = () => true,
  onClickScrim = () => true,
  middlePosition,
  middlePositionIcon,
  lastPosition,
}) => {
  return (
    <>
      <TopBarSlideIn
        enabled={enabled}
        middlePosition={middlePosition}
        middlePositionIcon={middlePositionIcon}
        lastPosition={
          lastPosition || (
            <ButtonBasic
              fullWidth={false}
              align="right"
              icon={<IconClose />}
              label="Close"
              onClick={onClickLastPosition}
            />
          )
        }
      />
      <ModalContents enabled={enabled} onClickScrim={onClickScrim}>
        {contents}
      </ModalContents>
      <BottomStickySlideIn enabled={enabled}>
        {bottomStickyPosition}
      </BottomStickySlideIn>
    </>
  );
};
