import React, { useState, useContext, useRef } from "react";
import { GuestSelector } from "../GuestSelector";
import { MobileDatePicker } from "../DatePicker/MobileDatePicker";
// Remove These -->
import "../../styles/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
// Remove These ^^^

import { SearchContext } from "../../context/SearchContext/SearchContext";
import { DestinationField } from "../DestinationField/DestinationField";
import { ButtonPrimary } from "../Elements/Button/ButtonPrimary";
import { ProviderLogos } from "../Home/ProviderLogos";
import { IconSearch } from "../Elements/Icon/IconSearch";
import { ButtonToggle } from "../Elements/Button/ButtonToggle";
import { useOnClickOutside } from "../../utils/onClickOutside";
export const SearchBarMobile = ({
  displayAllInputsOnLoad = true,
  displayFreeCancellation = false,
  displayPartnerLogos = true,
  searchWithoutSubmit = false,
  displayAsChild = false,
  freeCancellationFilterEnabled = false,
  onSearch = () => true,
  toggleFreeCancellation,
  darkMode=false,
}) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const { adults, children, rooms } = searchContext;

  const [displayErrors, setDisplayErrors] = useState(false);
  const [displayAllInputs, setDisplayAllInputs] = useState(
    displayAllInputsOnLoad
  );
  const container = useRef();
  useOnClickOutside(
    container,
    () => !displayAllInputsOnLoad && setDisplayAllInputs(false)
  );
  const updateCount = (type, count) => {
    if (count < 0) return;
    setSearchContext({ ...searchContext, [type]: count });
  };

  const search = () => {
    if (!searchContext.destination) return setDisplayErrors(true);
    if (searchContext.destination) {
      onSearch(true);
      !displayAllInputsOnLoad && setDisplayAllInputs(false);
    }
    return false;
  };

  return (
    <div ref={container} className="xl:hidden">
      <div
        className={`${
          displayAsChild ? "" : "rounded-4xl p-5 shadow-straight"
        } space-y-4 ${darkMode ? "bg-darkModeDarkGray" : "bg-white"}`}
      >
        <DestinationField
          displayLabelMobile={false}
          displayErrors={displayErrors}
          onFocus={() => setDisplayAllInputs(true)}
          placeholder="Where to?"
          darkMode={darkMode}
        />
        {displayAllInputs && (
          <>
            <GuestSelector
              adults={adults}
              children={children}
              rooms={rooms}
              updateCount={updateCount}
              darkMode={darkMode}
            />
            <MobileDatePicker
              label="Dates"
              dark={true}
              onDatesSelected={() => searchWithoutSubmit && search}
              darkMode={darkMode}
            />
            {!searchWithoutSubmit && (
              <ButtonPrimary
                onClick={search}
                label="Search"
                icon={<IconSearch classes="text-white" />}
              />
            )}
          </>
        )}
        {displayFreeCancellation && (
          <div className="flex justify-center w-full lg:w-auto">
            <ButtonToggle
              label="Free Cancellation Only"
              checked={freeCancellationFilterEnabled}
              value={"freeCancellation"}
              onChange={({ checked, value }) =>
                toggleFreeCancellation(checked, value)
              }
            />
          </div>
        )}
        {displayPartnerLogos && <ProviderLogos />}
      </div>
    </div>
  );
};
