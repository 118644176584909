export const ButtonPillSmall = ({
  icon,
  iconAfterLabel,
  label = "",
  onClick = () => true,
}) => {
  return (
    <button
      className="bg-white hover:bg-veryLightGray transition-colors ease-in-out flex items-center justify-between p-1 px-2 w-auto rounded-4xl text-xs text-blue-500 space-x-1 shadow-md"
      onClick={onClick}
    >
      <div>{icon}</div>
      <span>{label}</span>
      <div className="pl-1">
        {iconAfterLabel}
      </div>
    </button>
  );
};
