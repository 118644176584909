import React from 'react';
import { HeaderMin } from '../Header/HeaderMin'

export class MobileOverlay extends React.Component {
	render() {
		return (
			<div className={`fixed top-0 bottom-0 left-0 right-0 w-full z-60 overflow-y-scroll h-screen ${!this.props.darkMode ? 'bg-veryLightBlue' : 'bg-navyBlue bg-opacity-90'}` }>
				<div className={'relative pb-64'}>
					<div className={`sticky top-0 z-50 ${!this.props.darkMode ? 'bg-veryLightBlue' : ' '}` }>
                		<HeaderMin darkMode={this.props.darkMode} label={this.props.label} clicked={this.props.clicked}/>
					</div>
					<div className={`px-6 h-full ${!this.props.darkMode ? 'bg-veryLightBlue' : ' '}` }>
						{this.props.children}
					</div>
				</div>
            </div>
		)
	}
}