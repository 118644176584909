import FeaturedLocationsService from "../../services/FeaturedLocationsService";
import randomNumber from "../../utils/randomNumberWithinRange";
export const SearchState = (site: string) => {
  const daysInFuture = (days = 1) =>
  new Date().getTime() + 1000 * 60 * 60 * 24 * days;
  const featuredLocationData = FeaturedLocationsService.getFeaturedLocations(site, false);
  const randomIndex = randomNumber(featuredLocationData.length);
  const randomFeaturedLocation = featuredLocationData[randomIndex];

  const searchDetails = {
    activeResults: false,
    coordinates: {
      lat: randomFeaturedLocation.latitude,
      lng: randomFeaturedLocation.longitude,
    },
    destination: randomFeaturedLocation.name,
    destinationShortName: randomFeaturedLocation.name,
    startDate: new Date(daysInFuture(14)),
    endDate: new Date(daysInFuture(15)),
    adults: 2,
    children: 0,
    rooms: 1,
    radius: 2000, // km radius
    queryStringUsed: false, // Flags whether URL query string params have been used yet or not
    searchWhenMoveMap: true,
    mapMoved: false,
  };
  return searchDetails
};