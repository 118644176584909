import ICMSPageRequest from "./ICMSPageRequest";
import ApiService from "../ApiService";
import PageResponse from "../../models/api/cms/PageResponse";
import LandingPageResponse from "../../models/api/cms/LandingPageResponse";
import LandingPagesCarouselResponse from "../../models/api/cms/LandingPagesCarouselResponse";
import SitemapResponse from "../../models/api/cms/SitemapResponse";
import ArticleGalleryCardResponse from "../../models/api/cms/ArticleGalleryCardResponse";
import ArticlePageResponse from "../../models/api/cms/ArticlePageResponse";
const CMS_API_HOST = process.env.REACT_APP_API_HOST as string;

export default class CMSService {
  static getPage = async (url: string): Promise<PageResponse> => {
    const request: ICMSPageRequest = { url };
    const response = await ApiService.getRequest(
      CMS_API_HOST,
      "pages",
      request
    );
    try {
      if (response.status !== 200) throw Error("API response not 200");
      if (response.error) throw Error(response.error);
      return new PageResponse(response.status, response.data, response.error);
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  static getLandingPage = async (
    url: string,
    category: string
  ): Promise<LandingPageResponse> => {
    const request: ICMSPageRequest = { url, category };
    const response = await ApiService.getRequest(
      CMS_API_HOST,
      "landingPages",
      request
    );
    try {
      if (response.status !== 200) throw Error("API response not 200");
      if (response.error) throw Error(response.error);
      return new LandingPageResponse(
        response.status,
        response.data,
        response.error
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  static getLandingPagesCarousel = async (
    category: string
  ): Promise<LandingPagesCarouselResponse> => {
    const response = await ApiService.getRequest(
      CMS_API_HOST,
      "landingPages/carousel",
      {
        category,
      }
    );
    try {
      if (response.status !== 200) throw Error("API response not 200");
      if (response.error) throw Error(response.error);
      return new LandingPagesCarouselResponse(
        response.status,
        response.data,
        response.error
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  static getSitemap = async (): Promise<SitemapResponse> => {
    const response = await ApiService.getRequest(CMS_API_HOST, "sitemap");
    try {
      if (response.status !== 200) throw Error("API response not 200");
      if (response.error) throw Error(response.error);
      return new SitemapResponse(
        response.status,
        response.data,
        response.error
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  static getArticle = async (
    url: string,
    category: string
  ): Promise<ArticlePageResponse> => {
    const request: ICMSPageRequest = { url, category };
    const response = await ApiService.getRequest(
      CMS_API_HOST,
      "articles",
      request
    );
    try {
      if (response.status !== 200) throw Error("API response not 200");
      if (response.error) throw Error(response.error);
      return new ArticlePageResponse(
        response.status,
        response.data,
        response.error
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  static getArticleGallery = async (
    category: string
  ): Promise<ArticleGalleryCardResponse> => {
    const response = await ApiService.getRequest(
      CMS_API_HOST,
      "articles/gallery",
      {
        category,
      }
    );
    try {
      if (response.status !== 200) throw Error("API response not 200");
      if (response.error) throw Error(response.error);
      return new ArticleGalleryCardResponse(
        response.status,
        response.data,
        response.error
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  };
}
