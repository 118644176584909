import React from "react";
import { ReviewDetails } from "../ReviewDetails";
import { ListingDistance } from "./ListingDistance";

export const MoreInfoDrawer = ({
    raise = false,
    display = false,
    reviewScore = 0,
    reviewWord = "",
    reviewCount = 1,
    destination = "",
    distance = "",
  }) => {
  return (
    <div
      className={`${raise ? "z-20 " : "z-0"} ${
        display
          ? "transform -translate-y-2"
          : "transform -translate-y-24 sm:-translate-y-32"
      }
        absolute motion-safe:transition-transform duration-500 ease-in-out w-full`}
    >
      <div
        className={`${
          display ? "shadow-straight" : "shadow-none"
        } px-4 pt-3 pb-3 space-y-2 transition-shadow ease-in-out duration-200 mx-4 bg-white rounded-br-4xl rounded-bl-4xl`}
      >
        <ReviewDetails
          compact={true}
          reviewScore={reviewScore}
          reviewWord={reviewWord}
          reviewCount={reviewCount}
          displayToolTip={false}
        />
        <ListingDistance destination={destination} distance={distance} />
      </div>
    </div>
  );
};
