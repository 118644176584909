import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";
import { SearchBar } from "../SearchBar";
import { useContext } from "react";
import { UrlContext } from "../../context/UrlContext/UrlContext";

export const TopBar = ({ onSearch = () => true }) => {
  const [urlContext, setUrlContext] = useContext(UrlContext);
  return (
    <div className="w-full bg-white hidden xl:flex justify-between items-center xl:px-10 xl:py-3 py-2 xl:space-x-10 2xl:space-x-4">
      <div className="flex w-auto justify-center xl:justify-start">
        <Link to={`${urlContext}`}>
          <Logo darkMode={false} />
        </Link>
      </div>
      <div className="w-full hidden xl:block 2xl:w-2/3 2xl:max-w-6xl">
        <SearchBar
          displayLabels={false}
          displayPartnerLogos={false}
          onSearch={onSearch}
        />
      </div>
    </div>
  );
};
