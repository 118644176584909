import React from "react";
import { Link } from "react-router-dom";
import { NewsletterSignup } from "../NewsletterSignup";
import { Logo } from "../Elements/Logo/Logo";

export class Footer extends React.Component {
  render() {
    return (
      <div className="flex flex-col items-center max-w-xxl mx-auto px-5 pb-5 sm:px-10 xl:px-5">
        <div
          className={`${
            this.props.theme
              ? "bg-darkModeDarkGray"
              : "border-t border-gray-400 bg-navyBlue"
          } text-white pt-10 md:pt-12 pb-20 sm:pb-8 p-10 rounded-3xl`}
        >
          <div className="max-w-xxl mx-auto px-2 sm:px-0 lg:px-6">
            <div className="md:flex md:items-center">
              <div className="w-full flex lg:w-1/2 md:items-center">
                <p className="hidden md:block leading-relaxed text-sm md:text-base text-peakahGreen font-medium sm:pr-6 xl:pr-32">
                  SUBSCRIBE
                </p>
              </div>
              <div className="w-full flex lg:w-1/2 md:items-center">
                <p className="hidden md:block leading-relaxed text-xs text-white font-medium sm:pr-6 xl:pr-32">
                  Email
                </p>
              </div>
            </div>
            <div className="md:flex md:items-top sm:pb-4">
              <div className="w-full flex lg:w-1/2 md:items-center">
                <p className="leading-relaxed text-base md:text-2xl text-white font-medium sm:pr-6 xl:pr-20">
                  Become a member to receive exclusive offers and the best
                  travel tips!
                </p>
              </div>
              <div className="w-full lg:w-1/2 md:items-center">
                <NewsletterSignup theme={this.props.theme} />
              </div>
            </div>
            <div className="border-peakahBlue border-b mt-8"></div>
            <div className="flex flex-col md:flex-row mb-2 mt-8 md:mt-12 md:pt-4 w-full">
              <div className="w-full md:lg:w-1/2 flex items-start pb-6 md:mr-4 md:pb-0 md:pt-0">
                <div className="flex flex-col gap-4">
                  <Logo />
                  <p
                    className={`${
                      this.props.theme ? "text-darkModeLightGray" : "text-white"
                    } leading-relaxed text-xs sm:pr-6 xl:pr-24`}
                  >
                    Peakah.com compares thousands of accommodation options
                    spanning Hotels, Holiday Rentals, as well as alternative
                    accommodation types including everything from tree houses,
                    caravan parks, luxury apartments, log cabins, tiny homes and
                    more.
                  </p>
                </div>
              </div>
              <div className="flex flex-row w-full lg:w-1/2">
                <div className="flex flex-col w-1/2 lg:w-1/3 pt-5 sm:pt-0">
                  <div className="text-white font-semibold text-lg">
                    Sitemap
                  </div>
                  <ul
                    className={`flex w-full list-none text-xs flex-col leading-7 pt-4 ${
                      this.props.theme ? "text-darkModeLightGray" : "text-white"
                    }`}
                  >
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/how-it-works">How it works</Link>
                    </li>
                    <li>
                      <Link to="/articles">Articles</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/sitemap">Sitemap</Link>
                    </li>
                  </ul>
                </div>
                <div className="w-1/2 lg:w-1/3 flex flex-col pt-5 sm:pt-0">
                  <div className="text-white font-semibold text-lg">Social</div>
                  <ul
                    className={`flex w-full list-none text-xs flex-col leading-7 pt-4 ${
                      this.props.theme ? "text-darkModeLightGray" : "text-white"
                    }`}
                  >
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/peakah42"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/peakahtravel/"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/peaky_birds"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/peakah-com"
                      >
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://discord.gg/peakybirds"
                      >
                        Discord
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="pt-5 sm:pt-0 hidden md:block md:w-1/3 w-full">
                  <div className="text-white font-semibold text-lg">
                    Disclaimer
                  </div>
                  <ul
                    className={`flex w-full list-none text-xs flex-col leading-7 pt-4  ${
                      this.props.theme ? "text-darkModeLightGray" : "text-white"
                    }`}
                  >
                    <li>&copy; Copyright {new Date().getFullYear()}</li>
                    <li>Peakah Pty Ltd</li>
                    <li>All Rights Reserved</li>
                  </ul>
                </div>
              </div>
              <div className="pt-5 block md:hidden">
                <div className="text-white font-semibold text-lg">
                  Disclaimer
                </div>
                <ul
                  className={`flex w-full list-none text-xs flex-col leading-7 pt-4 ${
                    this.props.theme ? "text-darkModeLightGray" : "text-white"
                  }`}
                >
                  <li>&copy; Copyright {new Date().getFullYear()}</li>
                  <li>Peakah Pty Ltd</li>
                  <li>All Rights Reserved</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
