import React from 'react'
import { ButtonBasicSmall } from '../Elements/Button/ButtonBasicSmall'
import { IconDollar } from '../Elements/Icon/IconDollar'
import { IconInfo } from '../Elements/Icon/IconInfo'
import { IconMap } from '../Elements/Icon/IconMap'

export const ListingMobileLinks = (props) => (
    <div className="flex justify-between items-center w-full stroke-peakahGreen">
        <div>
            <ButtonBasicSmall label="View Map" displayDownArrow={false} toggle={() => props.openMapCard()}>
                <IconMap width={14} height={22} />
            </ButtonBasicSmall>
        </div>
        <div className="justify-center">
            <ButtonBasicSmall label="Info" displayDownArrow={true} open={props.moreInfoOpened} position="justify-center" toggle={() => props.toggleDisplayMoreInfo()}>
                <IconInfo width={14} height={20} />
            </ButtonBasicSmall>
        </div>
        <div className={!props.morePrices ? 'w-1/3' : ''}>
            {props.morePrices &&
                <ButtonBasicSmall label="More Prices" displayDownArrow={true} open={props.morePricesOpened} toggle={() => props.toggleDisplayMorePrices()}>
                    <IconDollar width={14} height={20} />
                </ButtonBasicSmall>
            }
        </div>
    </div>
)