import IFeaturedLocation from '../../models/api/cms/IFeaturedLocation';
import { Heading2 } from '../Elements/Heading/Heading2';
import { FeaturedLocation } from './FeaturedLocation';
import { FeaturedLocationSkeleton } from './FeaturedLocationSkeleton';

interface ILocationInner {
  loaded: boolean;
  data?: IFeaturedLocation[];
  locationChange: (index: number) => void;
  subHeading?: string;
  introduction?: string | null;
}

export const LocationInner: React.FC<ILocationInner> = ({
  subHeading,
  loaded,
  data,
  introduction = null,
  locationChange,
}) => {
  return (
    <div className="mb-10 lg:mb-0">
      <Heading2 classes="leading-normal text-navyBlue text-base lg:text-2xl my-10 sm:my-20 sm:pt-5">
        {subHeading}
      </Heading2>
      {introduction && (
        <div dangerouslySetInnerHTML={{ __html: introduction }} />
      )}
      {loaded &&
        data &&
        data.map((location, index) => (
          <div
            key={index}
            className={
              index + 1 !== data.length
                ? 'border-b border-lightBlue mt-5'
                : 'mt-5 sm:mt-10'
            }
          >
            {!loaded && <FeaturedLocationSkeleton />}
            {loaded && (
              <FeaturedLocation
                locationCount={data.length}
                key={index}
                location={location.Locations_id}
                index={index}
                imageUrl={location.imageUrl}
                locationChange={locationChange}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default LocationInner;
