export const MapNotifications = ({ message = "" }) => {
  if (message === "") return null;
  return (
    <div className="w-full absolute z-10 flex items-center justify-center text-center ">
      <div className="w-auto mt-16 xl:mt-4 mx-auto bg-gray-600 rounded-4xl shadow-straight xl:shadow-straight-lg px-6 text-white font-semibold h-10 flex items-center justify-between">
        {message}
      </div>
    </div>
  );
};
