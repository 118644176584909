import React, { useState, useEffect } from "react";
import { SkeletonButton } from "../../Elements/Skeletons/SkeletonButton";
import { TransitionSlideIn } from "../../Transition/TransitionSlideIn";
import { ListingSkeletonImage } from "./ListingSkeletonImage";
export const ListingSkeleton = () => {
  const [mounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    <TransitionSlideIn animate={mounted}>
      <div className="relative w-full h-full xl:h-60 shadow-straight lg:transition-shadow lg:ease-in-out lg:duration-200 p-5 xl:p-0 rounded-4xl bg-white text-navyBlue flex flex-col xl:flex-row justify-between sm:h-full">
        <div className="xl:w-72 xl:h-60 xl:flex-shrink-0 xl:mt-0">
          <ListingSkeletonImage />
        </div>
        <div className="w-full h-full pt-20 xl:pt-0 xl:p-5 flex flex-col justify-end xl:justify-center items-end xl:mt-6">
          <SkeletonButton />
        </div>
      </div>
    </TransitionSlideIn>
  );
};
