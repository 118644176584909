const today = new Date();
const tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24)
const twoWeeks = new Date(today.getTime() + 1000 * 60 * 60 * 336)
const twoWeeksTomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 360)
const endDateMin = new Date();

export {
    today,
    tomorrow,
    twoWeeks,
    twoWeeksTomorrow,
    endDateMin
}