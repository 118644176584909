import React from "react";
import { IconStar } from "../Elements/Icon/IconStar";
import { IconStarHalf } from "../Elements/Icon/IconStarHalf";

export const Stars = ({ stars = 0 }) => {
  if (stars === 0) return null;
  const wholeStars = Math.floor(stars);

  let elements = [];
  const isDecimal = (n) => n - wholeStars !== 0;

  for (let index = 0; index < wholeStars; index++) {
    elements.push(
      <IconStar
        key={index}
        width={14}
        height={14}
        classes="fill-current text-white mr-1"
        viewBox="0 0 11.41 10.86"
        stroke="none"
      />
    );
  }

  if (isDecimal(stars))
    elements = [
      ...elements,
      <IconStarHalf
        width={14}
        height={14}
        classes="fill-current text-white mr-1"
        viewBox="0 0 10 10.86"
        stroke="none"
        key={elements.length}
      />,
    ];

  return (
    <div>
      <div className="h-4 flex justify-start">{elements}</div>
    </div>
  );
};
