import PropertyAttribute from "../../../models/availability/PropertyAttribute";
import ListingInfoItem from "../../../models/view/Listing/ListingInfoItem";
import { IconBeds } from "../../Elements/Icon/IconBeds";
import { IconCar } from "../../Elements/Icon/IconCar";
import { IconMap } from "../../Elements/Icon/IconMap";
import { IconPencil } from "../../Elements/Icon/IconPencil";
import { IconUsers } from "../../Elements/Icon/IconUsers";
import { IconWiFi } from "../../Elements/Icon/IconWifi";
import { ListingInfoBlock } from "./ListingInfoBlockSingle";

const InfoBlocks = ({
  infoBlocksData = [{ label: "", metric: "" | 0, icon: () => {} }],
}) => (
  <ul className="hidden xl:block">
    {infoBlocksData
      .filter((block) => block.enabled)
      .slice(0, 4)
      .map((block, index) => {
        const item = new ListingInfoItem({
          label: block.label,
          metric: block.metric,
          icon: block.icon,
          fallbackLabel: block.fallbackLabel,
          fallBackLabelIfZero: block.fallBackLabelIfZero,
        });
        return (
          <li key={index}>
            <ListingInfoBlock
              label={item.label}
              metric={item.metric}
              icon={item.icon}
              displayFallbackLabel={
                item.metric === 0 && item.fallBackLabelIfZero
              }
              fallbackLabel={item.fallbackLabel}
            />
          </li>
        );
      })}
  </ul>
);

export const ListingInfoBlocks = ({
  propertyAttributes = [PropertyAttribute],
  reviewWord = "",
  reviewScore = 0.0,
  destination = "",
  distance = 1,
  maxGuests = 0,
  roomCount = 0,
}) => {
  const listingInfoData = [
    {
      label: `From ${destination}`,
      metric: distance,
      icon: <IconMap />,
      enabled: true,
    },
    {
      label: reviewWord,
      fallbackLabel: "No Reviews Yet",
      metric: reviewScore,
      icon: <IconPencil />,
      fallBackLabelIfZero: true,
      enabled: true,
    },
    {
      label: "Bedrooms",
      metric: roomCount,
      icon: <IconBeds />,
      enabled: roomCount > 0,
    },
    {
      label: "Guests",
      metric: maxGuests,
      icon: <IconUsers />,
      enabled: maxGuests > 0,
    },
    {
      label: `Free Wifi`,
      metric: null,
      icon: <IconWiFi />,
      enabled: propertyAttributes.find(
        (attribute) => attribute.name === "freeWiFi"
      ),
    },
    {
      label: `Free Parking`,
      metric: null,
      icon: (
        <IconCar
          stroke="none"
          classes="text-peakahGreen fill-current"
          width={24}
          height={32}
          viewBox="7.5 0 19.2 15.6"
        />
      ),
      enabled: propertyAttributes.find(
        (attribute) => attribute.name === "freeParking"
      ),
    },
  ];
  return <InfoBlocks infoBlocksData={listingInfoData} />;
};
