import { Icon } from "./Icon";
export const IconBeds = (props) => (
  <Icon
    {...props}
    strokeWidth={0}
    viewBox="0 0 22.2 17.7"
    classes="fill-current text-peakahGreen"
  >
    <path
      d="M21.2,8.9h-1.3V2.4C19.9,1,18.6,0,17,0H5.2c-1.7,0-3,1-3,2.4v6.5H1c-0.6,0-1,0.4-1,1v6.8c0,0.6,0.4,1,1,1h20.2
                c0.6,0,1-0.4,1-1V9.9C22.2,9.3,21.7,8.9,21.2,8.9z M12.1,8.5c0-0.1,0-0.1,0-0.2c0.3-0.8,1.5-1.4,2.8-1.4c1.4,0,2.7,0.7,2.9,1.6
	            C16.9,9,13.3,9,12.1,8.5z M10,8.5C8.9,9,5.3,9,4.3,8.5c0.2-0.9,1.5-1.6,2.9-1.6c1.3,0,2.5,0.6,2.9,1.4C10.1,8.4,10,8.4,10,8.5z
	            M5.2,2H17c0.6,0,0.9,0.3,1,0.4v3.3c-0.8-0.5-1.8-0.7-2.9-0.7c-1.6,0-3,0.6-3.9,1.4c-0.9-0.9-2.3-1.4-3.9-1.4
	            c-1.1,0-2.1,0.3-2.9,0.7l0-3.3C4.3,2.3,4.6,2,5.2,2z M20.2,15.7H2v-4.8h1.3c0,0,0,0,0,0s0,0,0,0h3.8c0,0,0.1,0,0.1,0
	            c0.1,0,0.2,0,0.3,0h7.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0h3.6c0,0,0,0,0,0s0,0,0,0h1.3V15.7z"
    />
  </Icon>
);
