import { ReactNode } from 'react';

interface IHeading4 {
  classes: string;
  children: ReactNode;
}
export const Heading4: React.FC<IHeading4> = ({ classes = '', children }) => {
  return (
    <h3 className={`${classes} leading-normal font-medium text-3xl text-blue-500`}>
      {children}
    </h3>
  );
};
