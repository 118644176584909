import { useEffect } from "react";
import { CurrencySelector } from "../Currency/CurrencySelector";
import { ButtonSecondary } from "../Elements/Button/ButtonSecondary";
import { ButtonToggle } from "../Elements/Button/ButtonToggle";
import { IconSliders } from "../Elements/Icon/IconSliders";
import { SortByFilter } from "../SortByFilter";

export const ToolBar = ({
  freeCancellationFilterEnabled = true,
  display = true,
  toggleFreeCancellation = () => true,
  toggleMoreFilters = () => true,
  sortBy = "",
  sortChange = () => "",
  currency = "",
  currencyChange = () => "",
}) => {
  return (
    <div
      className={`${
        display ? "translate-y-0" : "-translate-y-20"
      } transition-transform ease-in-out duration-200 transform hidden xl:flex justify-between py-5 px-10 sticky -top-2 z-50 bg-blue-100`}
    >
      <div>
        <ButtonSecondary
          label="More Filters"
          fullWidth={false}
          icon={<IconSliders />}
          onClick={toggleMoreFilters}
        />
      </div>
      <div className="flex justify-center w-auto">
        <ButtonToggle
          label="Free Cancellation Only"
          checked={freeCancellationFilterEnabled}
          value={"freeCancellation"}
          onChange={({ checked, value }) =>
            toggleFreeCancellation(checked, value)
          }
        />
      </div>
      <div className="flex gap-2">
        <CurrencySelector
          selected={currency}
          selectionChanged={(selectedValue) => currencyChange(selectedValue)}
        />
        <SortByFilter
          selected={sortBy}
          selectionChanged={(selectedValue) => sortChange(selectedValue)}
        />
      </div>
    </div>
  );
};
