import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { LabelSmall } from "../Elements/Label/LabelSmall";
import { GuestSelector } from "../GuestSelector";
import { DatePickerCheckIn } from "../DatePicker/DatePickerCheckIn";
import { DatePickerCheckOut } from "../DatePicker/DatePickerCheckOut";
import { today, endDateMin } from "../../utils/defaultDates";
// Remove These -->
import "../../styles/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
// Remove These ^^^

import { SearchContext } from "../../context/SearchContext/SearchContext";
import { DestinationField } from "../DestinationField/DestinationField";
import { IconSearch } from "../Elements/Icon/IconSearch";
import { ButtonPrimaryNoLabel } from "../Elements/Button/ButtonPrimaryNoLabel";
import { ProviderLogos } from "../Home/ProviderLogos";
import { UrlContext } from "../../context/UrlContext/UrlContext";
import { SiteParams } from "../../config/constants/SiteParams";

export const SearchBar = ({
  displayLabels = true,
  displayPartnerLogos = true,
  onSearch = () => true,
  darkMode = false,
}) => {
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const { startDate, endDate, adults, children, rooms } = searchContext;
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");
  const [displayErrors, setDisplayErrors] = useState(false);
  const history = useHistory();

  const handleStartChange = (date) => {
    let newEndDateMin = new Date(date.getTime() + 1000 * 60 * 60 * 24);
    if (endDate <= date) {
      setSearchContext({ ...searchContext, endDate: newEndDateMin });
    }
    setSearchContext({
      ...searchContext,
      endDate: endDate <= date ? newEndDateMin : endDate,
      startDate: date,
    });
  };

  const location = useLocation();

  useEffect( () => {
    if (urlContext !== "/" ) {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url)
		}
	}, [urlContext])

  const handleSearchAttempt = () => {
    if (!searchContext.destination) {
      setDisplayErrors(true);
      return null;
    }
    

    if (searchContext.destination && location.pathname !== `${urlContext}/search`) {
      history.push({ pathname: `${url}/search` });
    } else return onSearch();
  };

  const updateEndDate = (date) => {
    setSearchContext({ ...searchContext, endDate: date });
  };

  const updateCount = (type, count) => {
    if (count < 0) return;
    setSearchContext({ ...searchContext, [type]: count });
  };

  return (
    <div>
      <div className="flex space-x-3">
        <div className="w-1/4">
          <DestinationField
            displayErrors={displayErrors}
            displayLabel={displayLabels}
            darkMode={darkMode}
          />
        </div>
        <div className="w-1/4">
          {displayLabels && <LabelSmall title="Guests" darkMode={darkMode}/>}
          <GuestSelector
            adults={adults}
            children={children}
            rooms={rooms}
            updateCount={(type, count) => updateCount(type, count)}
            darkMode={darkMode}
          />
        </div>
        <div className="w-1/4">
          <DatePickerCheckIn
            label={displayLabels ? "Check in" : null}
            minDate={today}
            startDate={startDate}
            endDate={endDate}
            selected={startDate}
            displayErrors={displayErrors}
            onChange={(date) => handleStartChange(date)}
            darkMode={darkMode}
          />
        </div>
        <div className="w-1/4">
          <DatePickerCheckOut
            label={displayLabels ? "Check out" : null}
            startDate={startDate}
            endDate={endDate}
            minDate={endDateMin}
            selected={startDate}
            displayErrors={displayErrors}
            onChange={(date) => updateEndDate(date)}
            darkMode={darkMode}
          />
        </div>
        <div className="flex items-end md:pb-2">
          <ButtonPrimaryNoLabel
            onClick={handleSearchAttempt}
            icon={<IconSearch classes="text-white" />}
          />
        </div>
      </div>
      {displayPartnerLogos && (
        <div className="pt-2">
          <ProviderLogos />
        </div>
      )}
    </div>
  );
};
