import React from "react";
import { Icon } from "./Icon";
export const IconGlobe = (props) => (
  <Icon 
    width={40}
    height={40}
    viewBox="0 0 22.263 27.539"
    strokeWidth={0}
    fill="currentColor"
    classes="text-peakahGreen"
    {...props} 
>
    <path
      d="M460.45,206.08a12.308,12.308,0,0,1-5.9,22.749v1.483h2.929a.664.664,0,0,1,.568.421l.586,2.051a.615.615,0,0,1-.568.751h-8.2a.614.614,0,0,1-.567-.751l.586-2.051a.6.6,0,0,1,.567-.421h2.929v-1.483a12.313,12.313,0,0,1-4.339-1.016.586.586,0,1,1,.476-1.071,11.132,11.132,0,0,0,10.317-19.664.6.6,0,0,1-.283-.659.625.625,0,0,1,.906-.339Zm-6.49.5a9.97,9.97,0,1,1-4,.842s.005-.008.009-.009h.009a9.9,9.9,0,0,1,3.982-.833Zm0,1.172a8.774,8.774,0,0,0-3.213.6,5.609,5.609,0,0,0-.595,2.92,3.441,3.441,0,0,0,.787,2.023,5.672,5.672,0,0,0,1.089,1.135,2.577,2.577,0,0,1,1.044,1.4,14.876,14.876,0,0,0,.494,2.206,1.661,1.661,0,0,0,.4.641.77.77,0,0,0,.577.2.63.63,0,0,0,.476-.192,2.277,2.277,0,0,0,.467-.659,4.123,4.123,0,0,0,.43-2,10.961,10.961,0,0,1,.1-2.243,4,4,0,0,1,1.126-1.932c.4-.447.622-.717.622-1.172a2.837,2.837,0,0,0-.924-2.444,8.785,8.785,0,0,0-2.884-.485Zm-4.778,1.41a8.815,8.815,0,0,0-3.342,10.756,7.3,7.3,0,0,1,2.527-.448h.037c1.482,0,2.589.49,3.03,1.4a2.439,2.439,0,0,1-.851,2.82l-.018.018a4.748,4.748,0,0,1-.723.595,8.787,8.787,0,0,0,8.917-15.123,5.756,5.756,0,0,1,.183,1.5,2.9,2.9,0,0,1-.915,1.95,2.916,2.916,0,0,0-.852,1.382,10.884,10.884,0,0,0-.082,2.023,5.419,5.419,0,0,1-.558,2.527,3.3,3.3,0,0,1-.732,1.007,1.91,1.91,0,0,1-1.254.485,2.061,2.061,0,0,1-1.355-.494,2.715,2.715,0,0,1-.714-1.089,13.839,13.839,0,0,1-.558-2.408,2.491,2.491,0,0,0-.659-.751,6.269,6.269,0,0,1-1.309-1.4,4.591,4.591,0,0,1-.97-2.664,8.844,8.844,0,0,1,.2-2.087Zm-.778,11.48a8.3,8.3,0,0,0-2.023.348,8.812,8.812,0,0,0,2.307,2.591v-.009a.779.779,0,0,0,.192-.073,2.9,2.9,0,0,0,.879-.641l.018-.018c.681-.608.761-1.113.595-1.455s-.745-.742-1.968-.742Zm8.624,10.839h-6.134l-.256.879h6.646Z"
      transform="translate(-444 -205.995)"
    />
  </Icon>
);
