import ListingType from "../../models/availability/ListingType";
import { ButtonBasic } from "../Elements/Button/ButtonBasic";
import { IconClose } from "../Elements/Icon/IconClose";
import { IconRotateLeft } from "../Elements/Icon/IconRotateLeft";
import { FilterBar } from "./FilterBar";

export const FilterBarSide = ({
  amenitiesFilters = {},
  enabled = false,
  enableTopOffset = false,
  amenitiesChange = () => ({ enabled: false, id: "" }),
  closeFilters = () => true,
  listingTypes = [ListingType],
  priceChange = () => 0,
  ratingChange = () => 0,
  resetFilters = () => true,
  starChange = () => [],
  typeChange = () => [],
  priceMax = 0,
  priceMin = 0,
}) => {
  return (
    <div
      className={`${
        enabled
          ? "translate-x-0"
          : "translate-x-full"
      } hidden md:block transform transition-transform ease-in-out duration-300 absolute right-0 top-0 h-full w-full lg:w-80 lg:pt-20 bg-white shadow-straight z-70 lg:z-10 lg:pb-48 overflow-y-scroll`}
    >
      <div
        className={`${
          enableTopOffset ? "xl:pt-20 xl:mt-2" : ""
        } flex justify-between sticky top-0 bg-white py-2 px-10 lg:px-0`}
      >
        <ButtonBasic
          fullWidth={true}
          align="left"
          icon={<IconRotateLeft />}
          label="Reset"
          onClick={() => resetFilters()}
        />
        <ButtonBasic
          fullWidth={true}
          align="right"
          icon={<IconClose darkMode={true} />}
          label="Close"
          onClick={() => closeFilters()}
        />
      </div>
      <div className="px-10">
        <FilterBar
          amenitiesChange={({ enabled, id }) =>
            amenitiesChange({ enabled, id })
          }
          amenitiesFilters={amenitiesFilters}
          listingTypes={listingTypes}
          priceChange={(price) => priceChange(price)}
          priceMax={priceMax}
          priceMin={priceMin}
          starChange={(stars) => starChange(stars)}
          ratingChange={(rating) => ratingChange(rating.value)}
          typeChange={(type) => typeChange(type)}
        />
      </div>
    </div>
  );
};
