import ImagesRequest from "../../models/api/ImagesRequest";
import ImagesResponse from "../../models/api/ImagesResponse";
import ApiService from "../ApiService";
const API_URL = process.env.REACT_APP_API_HOST as string;

export default class SearchImagesService {
  static getImages = async (provider: string, providerId: string) => {
    const query = new ImagesRequest({ provider, providerId });
    const imagesFromApi = await ApiService.getRequest(API_URL, "images", query);
    const response = new ImagesResponse({data: imagesFromApi})
    return response
  };
}
