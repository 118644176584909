import { useContext } from "react";
import { SearchContext } from "../../../../context/SearchContext/SearchContext";

const room1QueryString = (adults, children) => {
  let room1 = "";
  const adultString = "A,";
  const childString = "12,";
  room1 = adultString.repeat(adults);
  room1 += childString.repeat(children);
  room1 = room1.slice(0, -1);
  return room1;
};

const buildQueryString = ({ startDate, endDate, rooms, adults, children }) => {
  const queryParams = {
    aid: 1977293,
    checkin_monthday: startDate.getDate(),
    checkin_month: startDate.getMonth() + 1,
    checkin_year: startDate.getFullYear(),
    checkout_monthday: endDate.getDate(),
    checkout_month: endDate.getMonth() + 1,
    checkout_year: endDate.getFullYear(),
    group_adults: adults,
    group_children: children,
    no_rooms: rooms,
    room1: room1QueryString(adults, children),
  };
  return new URLSearchParams(queryParams).toString();
};

export const BookingComDealLink = (url = "") => {
  const [searchContext] = useContext(SearchContext);
  const { startDate, endDate, rooms, adults, children } = searchContext;
  const queryString = buildQueryString({
    startDate,
    endDate,
    rooms,
    adults,
    children,
  });
  return `${url}?${queryString}`;
};
