import ListingType from "./ListingType";
import PartnerRate from "./PartnerRate";
import Coordinates from "./Coordinates";
import PropertyAttribute from "./PropertyAttribute";

class Hotel {
  constructor({
    id,
    coordinates,
    distance = 0.0,
    maxGuests = 0,
    name = "",
    roomCount = 0,
    photo = "",
    propertyAttributes = [PropertyAttribute],
    rates = [PartnerRate],
    reviewCount = 0,
    reviewScore = 0.0,
    reviewWord = "",
    stars = 4,
    listingType = ListingType,
    imageGalleryPartnerDetails = PartnerDetails,
  }) {
    this.id = id;
    this.coordinates = new Coordinates(coordinates?.coordinates);
    this.distance = distance;
    this.distanceText = this.distanceAsString(distance);
    this.maxGuests = maxGuests;
    this.name = name;
    this.roomCount = roomCount;
    this.photo = photo;
    this.propertyAttributes = propertyAttributes.map(
      (propertyAttribute) => new PropertyAttribute(propertyAttribute)
    );
    this.rates = rates.map((rate) => new PartnerRate(rate));
    this.reviewCount = reviewCount;
    this.reviewScore = reviewScore;
    this.reviewWord = reviewWord;
    this.stars = stars;
    this.listingType = new ListingType(listingType);
    this.imageGalleryPartnerDetails = new PartnerDetails(
      imageGalleryPartnerDetails
    );
  }

  distanceAsString = (distance) =>
    distance > 1000
      ? `${Number(distance / 1000).toFixed(2)} km`
      : `${Math.round(distance)} m`;
}

class PartnerDetails {
  constructor({ partner = "", partnerId = "" }) {
    this.partner = partner;
    this.partnerId = partnerId;
  }
}

export default Hotel;
