import ISitemapLink from './SitemapLink';

export default class SitemapResponse {
  constructor(
    public status: number,
    public data: {
      urls: ISitemapLink[];
    },
    public error?: Error
  ) {}
}
