export const BottomStickySlideIn = ({ children, enabled = false }) => {
  return (
    <div
      className={`${
        enabled ? "-translate-y-10" : "translate-y-20 sm:translate-y-10 h-0"
      } fixed bottom-0 transform transition-transform ease-in-out z-90 flex justify-center w-full `}
    >
      <div
        className={`rounded-5xl w-auto bg-white shadow-light`}
      >
        {children}
      </div>
    </div>
  );
};
