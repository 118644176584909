import { Footer } from "../components/Footer";
import Head from "../components/Head/Head";
import Hero from "../components/NFT/Hero";
import TravelToEarn from "../components/NFT/TravelToEarn";
import Perks from "../components/NFT/Perks";
import Growth from "../components/NFT/Growth";
import Partnerships from "../components/NFT/Partnerships";
import Flock from "../components/NFT/Flock";

const NFTPage: React.FC = () => {
  return (
    <>
      <Head
        metaData={{
          title: "",
          description: "",
        }}
      />
      <Hero />
      <div className="bg-none bg-top 2xl:bg-cover 2xl:bg-nftBackground 2xl:bg-no-repeat">
        <Perks
          imageUrl="https://cdn.peakah.com/images/b896943e-8c82-4fb1-bca8-aa3a4125d7c5.png"
          imageAlt="Perks & Benefits"
        />
        <TravelToEarn />
        <Growth
          imageUrl="https://cdn.peakah.com/images/131c630f-5053-48f9-b92d-9f2ae87d78f8.png"
          imageAlt="Growth & Gamification"
        />
      </div>
      <Flock
        heroUrl="https://cdn.peakah.com/images/e4ac5c1a-2b14-4987-9c94-576f769ee390.png"
        imageUrl="https://cdn.peakah.com/images/3ca91492-7c55-4b96-a380-fa52ac31db07.png"
        imageAlt="Join the FLock"
      />
      <Partnerships
        imageUrl="https://cdn.peakah.com/images/1bb21c10-bdf8-4328-9bea-a1feb11b3f9f.png"
        description="Our partnerships have been targeted at global brands from day one. Key activations across the Peakah Ecosystem will lead to exponential growth."
      />
      <Footer />
    </>
  );
};
export default NFTPage;
