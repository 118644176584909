import React, { useState, useEffect, useContext } from "react";
import { Header } from "../components/Header";
import { StickySearch } from "../components/Search/StickySearch";
import { Carousel } from "../components/Carousel";
import FeaturedLocationsService from "../services/FeaturedLocationsService";
import FeaturedLocation from "../models/view/FeaturedLocation";
import { FeaturedBlocks } from "../components/Content/FeaturedBlocks";
import { InstagramFeedModule } from "../components/Instagram/InstagramFeedModule";
import InstagramFeedService from "../services/InstagramFeedService";
import InstagramFeed from "../models/view/InstagramFeed";
import { Footer } from "../components/Footer";
import { Redirect } from "react-router-dom";
import Head from "../components/Head/Head";
import { globalMetaData } from "../config/constants/Strings";
import { content } from "../config/content/homeContent";
import CMSService from "../services/CMSApi/CMSService";
import LandingPagesCarouselSlide from "../models/api/cms/LandingPagesCarouselSlide";
import { ContentBlocks } from "../components/Content/ContentBlocks";
import { ArticleCarousel } from "../components/Article/ArticleCarousel";
import ArticleGalleryCard from "../models/api/cms/ArticleGalleryCard";
import { UrlContext } from "../context/UrlContext/UrlContext";
import { SiteParams } from "../config/constants/SiteParams";
import { Heading4 } from "../components/Elements/Heading/Heading4";
import { useDarkMode } from "../context/DarkModeContext/DarkModeContext";
import { headerImages, headerText } from "../config/header";

export const Home = () => {
  const [stickyDisplay, setStickyDisplay] = useState(false);
  const initialFeaturedLocationState = new FeaturedLocation({});
  const [featuredLocationData, setFeaturedLocationData] = useState([
    initialFeaturedLocationState,
  ]);
  const initialInstagramFeedState = new InstagramFeed({});
  const [instagramFeed, setInstagramFeed] = useState(initialInstagramFeedState);
  const [redirectToSearchResults, setRedirectToSearchResults] = useState(false);
  const [carouselContent, setCarouselContent] =
    useState<LandingPagesCarouselSlide[]>();
  const [galleryContent, setGalleryContent] = useState<ArticleGalleryCard[]>();
  // Used to randomise the location found in AUS and US site
  const [urlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");
  const [region, setRegion] = useState("AUS");
  const [CMSurl, setCMSurl] = useState("staycations");
  const { theme } = useDarkMode();

  useEffect(() => {
    setCMSurl(SiteParams.find((obj) => obj.url === urlContext)?.cms || CMSurl);
    setRegion(SiteParams.find((obj) => obj.url === urlContext)?.code || region);
  }, [urlContext, CMSurl, region]);

  const stickyDisplayHandler = () => {
    if (window.scrollY > 350) {
      setStickyDisplay(true);
    } else {
      setStickyDisplay(false);
    }
  };

  const getCarouselContent = async () => {
    if (CMSurl) {
      const contentResponse = await CMSService.getLandingPagesCarousel(CMSurl);
      setCarouselContent(contentResponse.data);
    }
  };

  const getGalleryContent = async () => {
    const contentResponse = await CMSService.getArticleGallery("aus");
    setGalleryContent(
      contentResponse.data?.sort(
        (a, b) =>
          new Date(b.date_created).getTime() -
          new Date(a.date_created).getTime()
      )
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", () => stickyDisplayHandler());

    const featuredLocationData =
      FeaturedLocationsService.getFeaturedLocations(region, true);
    setFeaturedLocationData(featuredLocationData);

    const instagramFeedData = InstagramFeedService.getFeed();
    setInstagramFeed(instagramFeedData);

    getCarouselContent();
    getGalleryContent();

    return () => {
      window.removeEventListener("scroll", () => stickyDisplayHandler());
    };
  }, [CMSurl, region]);

  const scrollToForm = () => {
    window.scrollTo(0, 120);
  };

  useEffect(() => {
    if (urlContext !== "/") {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url);
    }
  }, [urlContext, url]);

  if (redirectToSearchResults) {
    return <Redirect push to={`${url}/search`} />;
  }

  return (
    <div className={`${theme ? "bg-darkModeBlack" : ""} select-none`}>
      <Head
        metaData={{
          description: globalMetaData.description,
          title: globalMetaData.title,
        }}
      />
      <div className="">
        <div className="max-w-xxl mx-auto px-5 sm:px-10 xl:px-16">
          <Header
            onSearch={() => setRedirectToSearchResults(true)}
            toggleFreeCancellation={false}
            heroBg={headerImages}
            header={headerText}
          />
        </div>
        <div className="max-w-xxl mx-auto px-5 sm:px-10 xl:px-16">
          <Heading4
            classes={`p-5 sm:p-10 xl:px-0 xl:pt-20 xl:pb-10 text-center ${
              theme ? "text-white" : ""
            }`}
          >
            Our top picks
          </Heading4>
          <FeaturedBlocks blocks={featuredLocationData} />
        </div>

        {carouselContent && galleryContent && (
          <div className="max-w-xxl mx-auto px-5 sm:px-10 xl:px-16 pt-8 sm:pt-0 pb-5">
            <Heading4
              classes={`p-5 sm:p-10 xl:px-0 xl:pt-20 pb-5 text-center ${
                theme ? "text-white" : ""
              }`}
            >
              Destination deep dives
            </Heading4>
            <div className="flex flex-col lg:grid lg:grid-cols-2 gap-5">
              <div className="bg-veryLightGreen p-5 rounded-2xl">
                <ArticleCarousel
                  heading="The latest travel news and insights"
                  images={galleryContent}
                />
              </div>
              <div className="bg-blue-200 p-5 rounded-2xl">
                <Carousel
                  heading={`Need a holiday ${
                    region === "AUS" ? "Australia" : "America"
                  }? Get to know your State`}
                  images={carouselContent}
                />
              </div>
            </div>
          </div>
        )}

        <div className="max-w-xxl mx-auto px-5 sm:px-10 xl:px-16 pt-8 sm:pt-0 pb-5">
          <ContentBlocks content={content.aboutBlocks} darkMode={theme} />
        </div>

        <div className="max-w-xxl mx-auto px-5 sm:px-10 xl:px-16 pt-8 pb-10 sm:pt-0 lg:pb-20">
          <InstagramFeedModule
            images={instagramFeed.images}
            account={instagramFeed.account}
            darkMode={theme}
          />
        </div>
        <StickySearch display={stickyDisplay} click={scrollToForm} />
      </div>
      <Footer theme={theme} />
    </div>
  );
};
