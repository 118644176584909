import React, { useContext, useEffect, useState } from "react";
import { Heading2 } from "../Elements/Heading/Heading2";
import { Card } from "./Card";
import ArticleGalleryCard from "../../models/api/cms/ArticleGalleryCard";
import { ButtonPrimary } from "../Elements/Button/ButtonPrimary";
import { UrlContext } from "../../context/UrlContext/UrlContext";
import { SiteParams } from "../../config/constants/SiteParams";

interface IArticleCards {
  heading: string;
  images: ArticleGalleryCard[];
  exclude?: string;
  showMore?: boolean;
  cardLimit: number;
}

export const ArticleCards: React.FC<IArticleCards> = ({
  heading,
  images,
  exclude,
  showMore,
  cardLimit,
}) => {
  const [contentImages, setContentImages] = useState<ArticleGalleryCard[]>();
  const [showAll, setShowAll] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [cardNumber, setCardNumber] = useState(cardLimit);
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (urlContext !== "/") {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url);
    }
  }, [urlContext]);


  useEffect(() => {
    if (!images) return undefined;
    const slides = images.filter((image) => image.url !== exclude);
    setTimeout(() => {
      setContentImages(slides);
    }, 1000);
    return () => {
      setContentImages(undefined);
    };
  }, [exclude, images]);

  useEffect(() => {
    if (contentImages !== undefined) {
      if (contentImages.length <= cardNumber) {
        setShowAll(true);
      } else {
        setShowButton(true);
      }
    }
  }, [contentImages, cardNumber]);
  return (
    <>
      <div className="px-5 pt-5 sm:px-10 lg:pt-0">
        <Heading2 classes="pb-5 lg:pb-10 leading-normal font-light text-2xl text-navyBlue lg:text-4xl lg:text-left">
          {heading}
        </Heading2>
        <div className="flex items-center grid grid-cols-1 md:grid-cols-2 gap-8 ">
          {contentImages &&
            contentImages
              .slice(0, cardNumber)
              .map((item, index) => (
                <Card
                  alt={item.galleryImageAlt}
                  url={`${url}/articles/${item.url}`}
                  image={item.galleryImageUrl}
                  name={item.heading}
                  published={item.date_created}
                  key={index}
                  carousel={false}
                />
              ))}
        </div>
      </div>
      {showMore && !showAll && showButton && (
        <div className="flex justify-center items-center py-10">
          <ButtonPrimary
            onClick={() => {
              setCardNumber(cardNumber + cardLimit);
            }}
            fullWidth={false}
            label={"Load more"}
            classes="h-20 font-bold"
          />
        </div>
      )}
    </>
  );
};
