import React from "react";
import { IconChevronRight } from "../Elements/Icon/IconChevronRight";
import { LinkPrimary } from "../Elements/Link/LinkPrimary";
import { LinkSecondary } from "../Elements/Link/LinkSecondary";
import { BookingComDealLink } from "../Search/ReferralCTA/bookingCom/bookingComDealLink";
import { trackSearchCTA } from "../Search/ReferralCTA/tracking";

export const DealLink = ({
  buttonHover = true,
  cheapest = false,
  provider = "",
  rate = 0.0,
  url = "",
  label="View Deal"
}) => {
  if (provider === "bookingCom") url = BookingComDealLink(url);
  return (
    <div className="flex items-center flex-shrink-0">
      {cheapest ? (
        <LinkPrimary
          label={label}
          url={url}
          onClick={() => trackSearchCTA(provider, rate)}
          fullWidth={false}
          buttonHover={buttonHover}
        />
      ) : (
        <LinkSecondary
          icon={
            <IconChevronRight
              classes="text-blue-500"
              width={15}
              height={15}
              strokeWidth={3}
            />
          }
          label="View Deal"
          url={url}
          onClick={() => trackSearchCTA(provider, rate)}
        />
      )}
    </div>
  );
};
