export const CarouselIndexIndicator = ({
  length = 0,
  activeIndex = 4,
  classes = "",
  darkMode = false,
}) => (
  <div
    className={`${
      classes ? classes : "flex lg:hidden"
    } justify-center space-x-2 my-5 items-center`}
  >
    {[...Array(length)].map((item, index) => (
      <div
        key={index}
        className={`${
          darkMode
            ? activeIndex === index
              ? "bg-blue-500 h-5 w-5"
              : "bg-gray-300 bg-opacity-50"
            : activeIndex === index
            ? "bg-white h-5 w-5"
            : "bg-gray-300 bg-opacity-100"
        } h-3 w-3 rounded-full`}
      ></div>
    ))}
  </div>
);
