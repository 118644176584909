class ListingInfoItem {
    fallbackLabel: string;
    fallBackLabelIfZero: boolean;
    label: string;
    metric: number | string;
    icon: any
    constructor({
        fallbackLabel = "",
        fallBackLabelIfZero = false,
        label = "",
        metric = 0.0,
        icon = () => {}
    })
    {
        this.fallbackLabel = fallbackLabel;
        this.fallBackLabelIfZero = fallBackLabelIfZero;
        this.label = label;
        this.metric = metric;
        this.icon = icon
    }
}

export default ListingInfoItem;