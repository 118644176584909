export const ListingInfoBlock = ({
  metric = 0,
  label = "",
  icon,
  displayFallbackLabel = false,
  fallbackLabel = "",
}) => (
  <div className="text-blue-500 flex space-x-1 items-center">
    <div className="h-8 flex items-center mr-1">{icon}</div>
    <div className="line-clamp-1">
      <span className="text-sm font-semibold">{!displayFallbackLabel && metric} </span>
      <span className="text-sm">{displayFallbackLabel ? fallbackLabel : label}</span>
    </div>
  </div>
);
