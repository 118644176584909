import React, { useEffect, useState } from "react";
import { AnimationBus } from "../Elements/Animation/AnimationBus";
export const ExtendingSearchCallOut = ({
  loadingMore = false,
  destination = "",
  resultsExist = false,
  maxRadiusExceeded = false,
  clicked = () => true,
}) => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (maxRadiusExceeded && resultsExist) setMessage(`You've reached the end of our accommodation within 65km of ${destination}.`)
    if (maxRadiusExceeded && !resultsExist) setMessage(`Sorry we can't find any accommodation with availability within 65km of ${destination}.`);
    return () => setMessage(null);
  }, [maxRadiusExceeded, resultsExist, destination]);

  return (
    <div className="flex justify-center flex-col items-center w-full">
      {loadingMore && (
        <div className="flex flex-col items-center justify-center">
          <AnimationBus display={loadingMore} />
          <div className="text-center">
            You’re way off the grid! Searching for more accommodation near by..
          </div>
        </div>
      )}
      {message}
      {maxRadiusExceeded && (
        <span
          className="underline lg:no-underline cursor-pointer lg:cursor-default font-semibold mt-2"
          onClick={() => clicked(true)}
        >
          Please adjust your filters to see more results.
        </span>
      )}
    </div>
  );
};
