export const globalMetaData: Record<string, string> = {
  description:
    "Find the best hotel & holiday home rental deals all in 1 place. First metasearch platform showing all hotels, motels, holiday homes & alternative accommodation.",
  title: "Leading Accommodation Comparison Site | Best Deals | Peakah",
  ogImage: "https://cdn.peakah.com/images/og-image.jpg",
  charset: "utf-8",
  facebookDomainVerification: "72sn8c8dcqwd2otgd6mini3hbuv78t",
  favicon: "favicon.ico",
  themeColor: "#F4F9FA",
  viewPort:
    "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
};
