import React from "react";
import { Checkbox } from "../Checkbox";
export const SelectionOption = ({
  optionId = "",
  first = false,
  last = false,
  checked = false,
  name = "",
  value,
  selected,
  handleCheckboxChange = () => ({ checked: true }),
}) => {
  return (
    <li
      key={optionId}
      className={`text-sm flex items-center lg:hover:bg-veryLightBlue transition-all duration-200 ease-in-out cursor-pointer 
                ${first ? "lg:rounded-tl-4xl lg:rounded-tr-4xl" : ""}
                ${last ? "lg:rounded-bl-4xl lg:rounded-br-4xl" : ""}
                ${checked ? "lg:bg-veryLightBlue" : ""}
            `}
    >
      <label className="flex w-full justify-between items-center py-2 lg:py-4 lg:pl-3 sm:pl-6 sm:pr-4 cursor-pointer">
        <div className="">
          <Checkbox
            value={value}
            onChange={(change) => handleCheckboxChange(change)}
            checked={selected === value}
          />
        </div>
        <div className="flex items-center w-full">
          <span className={`${selected === value ? "lg:font-semibold" : ""}`}>
            {name}
          </span>
        </div>
      </label>
    </li>
  );
};
