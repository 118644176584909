import { useState } from "react";

interface IAccordian {
  heading: string;
  description: string;
  colour?: string;
}

const Accordian: React.FC<IAccordian> = ({ heading, description, colour }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((current) => !current);
  return (
    <div
      className={`border p-4 rounded-2xl gap-2 items-center ${colour} text-white hover:shadow-xl duration-500 `}
      onClick={toggleExpanded}
    >
      <div className="flex justify-between items-center font-medium cursor-pointer text-left select-none">
        {heading}
        <span className="transition group-open:rotate-180">
          <svg
            fill="none"
            height="24"
            shapeRendering="geometricPrecision"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M6 9l6 6 6-6"></path>
          </svg>
        </span>
      </div>
      <div
        className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
          expanded ? "max-h-80" : "max-h-0"
        }`}
      >
        <div className="p-4 text=lef">{description}</div>
      </div>
    </div>
  );
};

export default Accordian;
