import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HeaderInner } from '../components/HeaderInner';
import { Footer } from '../components/Footer';
import CMSService from '../services/CMSApi/CMSService';
import ISitemapLink from '../models/api/cms/SitemapLink';

const SitemapHTML: React.FC = () => {
  const [sitemap, setSitemap] = useState<ISitemapLink[]>();

  const getContent = async () => {
    const contentResponse = await CMSService.getSitemap();
    setSitemap(contentResponse.data.urls);
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div>
      <HeaderInner
        heroBg={{
          source: 'https://cdn.peakah.com/images/home-bg-2021.png',
          alt: '',
        }}
        heading="Sitemap"
      />
      <div className="px-5 py-10 sm:px-10 max-w-xxl mx-auto flex items-start">
        <div className="w-full lg:w-6/12 text-sm text-navyBlue leading-neat">
          {sitemap?.map((item) => (
            <ul>
              <Link to={item.url}>{item.name} </Link>
            </ul>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SitemapHTML;
