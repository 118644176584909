class ImagesRequest {
  provider: string;
  providerId: string;
  constructor({ provider = '', providerId = '' }) {
    this.provider = provider;
    this.providerId = providerId;
  }
}

export default ImagesRequest;
