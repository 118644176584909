export default class ArticlePage {
  constructor(
    public id: number,
    public title: string,
    public heading: string,
    public status: string,
    public category: string,
    public url: string,
    public content: string,
    public date_created: Date,
    public date_updated: Date,
    public user_updated: string,
    public meta_description: string,
    public meta_title: string,
    public heroImageUrl: string,
    public heroImageAlt: string,
    public galleryImageUrl: string,
    public galleryImageAlt: string,
  ) {}
}
