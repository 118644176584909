export const ButtonPrimaryNoLabel = ({
  onClick = () => true,
  icon = null,
}) => (
  <button
    className="font-medium text-white bg-peakahGreen h-10 w-13 md:w-10 rounded-full shadow-greenBox "
    onClick={onClick}
  >
    <span className="text-sm flex justify-center items-center text-white space-x-2">
      {icon}
    </span>
  </button>
);
