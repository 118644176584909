import { IconChevronLeft } from "../Elements/Icon/IconChevronLeft";
import { IconChevronRight } from "../Elements/Icon/IconChevronRight";

export const CarouselControlButton = ({
  clicked,
  direction = "right",
  darkMode = false,
}) => (
  <div
    onClick={() => clicked()}
    className={`${
      direction === "right"
        ? "-translate-x-5 transform"
        : "translate-x-5 transform"
    } 
    ${darkMode ? "bg-gray-700 bg-opacity-90" : "bg-white"} 
    w-10 h-10 rounded-full shadow-straight flex items-center justify-center cursor-pointer hover:bg-gray-100 hover:shadow-lg`}
  >
    {direction === "right" ? (
      <IconChevronRight
        classes={` ${darkMode ? "text-white" : "text-peakahGreen"}`}
        width={18}
        height={18}
        strokeWidth={2}
      />
    ) : (
      <IconChevronLeft
        classes={` ${darkMode ? "text-white" : "text-peakahGreen"}`}
        width={18}
        height={18}
        strokeWidth={2}
      />
    )}
  </div>
);
