interface ICallout {
  heading?: string;
  description: string;
  extra?: string;
  leftIcon?: React.ReactNode;
  imageUrl?: string;
  colour: string;
}

const Callout: React.FC<ICallout> = ({
  heading,
  description,
  extra,
  leftIcon,
  imageUrl,
  colour,
}) => {
  return (
    <div
      className={`border p-4 flex rounded-2xl gap-2 items-center ${colour} text-white hover:opacity-90 hover:shadow-lg md:hover:scale-105 duration-500`}
    >
      {imageUrl && <img src={imageUrl} alt={heading} width="80" />}
      {leftIcon && <div>{leftIcon}</div>}
      <p className="text-white">
        {heading && (
          <span className="text-peakahGreen font-medium">{heading}: </span>
        )}
        {description}
        {extra && <span className="text-peakahGreen font-medium">{extra}</span>}
      </p>
    </div>
  );
};

export default Callout;
