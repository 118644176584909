import React from "react";
import partners from "../../config/partners.json";

export const ProviderLogo = ({
  partner = "",
  bounding = false
}) => {
  if (partner === "") return null
  return (
    <div className="flex items-center">
      <img
        className="object-contain h-auto w-24 lg:w-32"
        src={logo(partner, bounding)}
        alt={`${partner} logo`}
      />
    </div>
  );
};

const logo = (partner = "", bounding = false) => {
  if (partner === "") return null
  const logoWithoutBounding = partners.find(
    (partnerConfig) => partnerConfig.reference === partner
  ).logoNoBounding;
  const logoWithBounding = partners.find(
    (partnerConfig) => partnerConfig.reference === partner
  ).logo;
  return bounding ? logoWithBounding : logoWithoutBounding;
};
