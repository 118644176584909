import { Helmet } from "react-helmet";
import { globalMetaData } from "../../config/constants/Strings";
import { MetaData } from "../../types";

interface IHead {
  charset?: string;
  facebookDomainVerification?: string;
  favicon?: string;
  themeColor?: string;
  viewPort?: string;
  metaData: MetaData;
}

const Head: React.FC<IHead> = ({
  charset = globalMetaData.charset,
  facebookDomainVerification = globalMetaData.facebookDomainVerification,
  favicon = globalMetaData.favicon,
  themeColor = globalMetaData.themeColor,
  viewPort = globalMetaData.viewPort,
  metaData = {
    description: globalMetaData.description,
    title: globalMetaData.title,
    ogImage: globalMetaData.ogImage,
  },
}) => {
  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta charSet={charset} />
      <meta name="viewport" content={viewPort} />
      <meta name="theme-color" content={themeColor} />
      <meta property="og:title" content={metaData.title} />
      {metaData.ogImage && <meta property="og:image" content={metaData.ogImage} /> }
      <meta property="og:description" content={metaData.description} />
      <meta
        name="facebook-domain-verification"
        content={facebookDomainVerification}
      />
      <link rel="icon" href={`/${favicon}`} />
    </Helmet>
  );
};

export default Head;
