import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface DarkModeContextType {
  theme: boolean;
  toggleDarkMode: () => void;
}

const DARK_MODE_STORAGE_KEY = "theme";

const DarkModeContext = createContext<DarkModeContextType | undefined>(
  undefined
);

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};

interface DarkModeProviderProps {
  children: ReactNode;
}

export const DarkModeProvider: React.FC<DarkModeProviderProps> = ({
  children,
}) => {
  const [theme, setTheme] = useState<boolean>(() => {
    const storedTheme = localStorage.getItem(DARK_MODE_STORAGE_KEY);
    return storedTheme ? JSON.parse(storedTheme) : false;
  });

  const toggleDarkMode = () => {
    const newTheme = !theme;
    setTheme(newTheme);
  };

  useEffect(() => {
    localStorage.setItem(DARK_MODE_STORAGE_KEY, JSON.stringify(theme));
  }, [theme]);

  return (
    <DarkModeContext.Provider value={{ theme, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
