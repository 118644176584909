import instagramConfig from "../config/instagram.json";
import InstagramFeed from "../models/view/InstagramFeed";
export default class InstagramFeedService {
  static getFeed = () => {
    const feed = new InstagramFeed({
      data: instagramConfig.imageFeedResponse,
      account: instagramConfig.account,
    });
    return feed;
  };
}
