import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";
import { Headline } from "./Headline";
import { MainNavigation } from "../Navigation/MainNavigation";
import Links from "../../config/links.json";
import { UrlContext } from "../../context/UrlContext/UrlContext";

export const HeaderInner = ({
  heading = "",
  heroBg = { source: "", alt: "" },
}) => {
  const [urlContext, setUrlContext] = useContext(UrlContext);
  return (
    <div className="relative w-full h-64 sm:h-72 xl:h-90">
      <img
        className="object-cover object-center h-64 sm:h-72 xl:h-90 absolute w-full"
        src={heroBg.source}
        alt={heroBg.alt}
      />
      <div className="px-5 pt-6 bg-opacity-40 bg-black h-64 sm:h-72 xl:h-90 absolute w-full"></div>
      <div className="max-w-xxl mx-auto px-5 sm:px-10 md:px-10 relative h-64 sm:h-72 xl:h-90">
        <div className="flex w-full pt-10">
          <div className="w-1/2">
            <Link to={`${urlContext}`}>
              <Logo />
            </Link>
          </div>
          <div className="w-1/2">
            <MainNavigation links={Links} />
          </div>
        </div>
        <div className="sm:block text-left pb-4 bottom-0">
          <Headline classes="text-white leading-tight sm:text-4xl lg:text-5xl bottom-0 absolute pb-10">
            {heading}
          </Headline>
        </div>
      </div>
    </div>
  );
};
