const HighlightedMatchedText = ({
  string = "",
  matchedSubStrings = [{ length: 0, offset: 0 }],
}) => {
  const firstMatchedSubString = matchedSubStrings[0];
  const { offset = 0, length = 0 } = firstMatchedSubString;
  const beginningString = `${string.substring(offset, length)}`;
  const endString = `${string.substring(length, string.length)}`;
  return (
    <>
      <span className="font-semibold text-peakahBlue">{beginningString}</span>
      {endString}
    </>
  );
};

export const DestinationResults = ({ results = [{}], onClick = () => "" }) => {
  return (
    <div className="absolute z-50 overflow-hidden rounded-br-4xl rounded-bl-4xl px-5 pb-5 pt-2 bg-white border-t-0 border-blue-300 border w-full h-auto flex flex-col space-y-2">
      {results.map((result, index) => (
        <div
          key={index}
          className="w-full text-base cursor-pointer max-w-xs truncate"
          onClick={() => onClick(result.place_id)}
        >
          <HighlightedMatchedText
            string={result.description}
            matchedSubStrings={result.matched_substrings}
          />
        </div>
      ))}
    </div>
  );
};
