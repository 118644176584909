import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";
import { useContext } from "react";
import { UrlContext } from "../../context/UrlContext/UrlContext";

export const HeaderBasic = ({ darkMode = false }) => {
  const [urlContext, setUrlContext] = useContext(UrlContext);
  return (
    <div className="pt-8 lg:pt-10 w-full flex justify-center lg:justify-between">
      <Link to={`${urlContext}`}>
        <Logo darkMode={darkMode} />
      </Link>
    </div>
  );
};
