class InstagramFeed {
  account: string;
  images: InstagramFeedImage[];
  constructor({ data = [{ link: '', src: '' }], account = '' }) {
    this.account = account;
    this.images = data.map((image) => new InstagramFeedImage(image));
  }
}

class InstagramFeedImage {
  src: string;
  link: string;
  constructor({ src = '', link = '' }) {
    this.src = src;
    this.link = link;
  }
}

export default InstagramFeed;
