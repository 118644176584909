import React from "react";
import { Icon } from "./Icon";
export const IconPriceTag = (props) => (
  <Icon
    width={40}
    height={40}
    viewBox="0 0 22.263 27.539"
    strokeWidth={0}
    fill="currentColor"
    classes="text-peakahGreen"
    {...props}
  >
    <path
      d="M604.463,225.173l-7.879-13.637a.593.593,0,0,0-.4-.287l-9.139-1.92-1.748-3.037a.59.59,0,1,0-1.031.573l1.748,3.037-2.922,8.881a.577.577,0,0,0,.057.487l7.879,13.638a.634.634,0,0,0,.373.286,1.241,1.241,0,0,1,.143.029.483.483,0,0,0,.286-.086l12.406-7.162A.6.6,0,0,0,604.463,225.173Zm-12.692,6.647-7.449-12.893,2.75-8.366,8.624,1.8,7.449,12.893Z"
      transform="translate(-583.06 -205.995)"
    />
    <path
      d="M604.583,237.9l-7.248,4.211a.606.606,0,0,0-.229.8l4.9,8.48a.633.633,0,0,0,.373.287,1.242,1.242,0,0,1,.143.029.484.484,0,0,0,.286-.086l7.248-4.183a.633.633,0,0,0,.287-.372.646.646,0,0,0-.057-.459l-4.9-8.48A.605.605,0,0,0,604.583,237.9ZM602.72,250.28l-4.3-7.449,6.217-3.581,4.3,7.449Z"
      transform="translate(-592.95 -228.524)"
    />
    <path
      d="M599.765,232.576a2.005,2.005,0,1,0-2.006-2.005A2.011,2.011,0,0,0,599.765,232.576Zm0-2.808a.8.8,0,0,1,0,1.6.82.82,0,0,1-.8-.8A.8.8,0,0,1,599.765,229.768Z"
      transform="translate(-593.46 -221.965)"
    />
    <path
      d="M612.342,259.014a.574.574,0,0,0,.516.287.484.484,0,0,0,.287-.086l3.237-1.862a.59.59,0,1,0-.573-1.031l-3.238,1.862A.6.6,0,0,0,612.342,259.014Z"
      transform="translate(-603.717 -241.556)"
    />
    <path
      d="M611.544,249.136,608.307,251a.6.6,0,0,0-.229.8.575.575,0,0,0,.516.287.484.484,0,0,0,.287-.086l3.238-1.862a.605.605,0,0,0,.229-.8A.589.589,0,0,0,611.544,249.136Z"
      transform="translate(-600.713 -236.462)"
    />
    <path
      d="M616.5,266.333a.575.575,0,0,0,.516.286.483.483,0,0,0,.286-.086l3.266-1.834a.59.59,0,1,0-.573-1.031l-3.266,1.862A.613.613,0,0,0,616.5,266.333Z"
      transform="translate(-606.674 -246.755)"
    />
  </Icon>
);
