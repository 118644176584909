import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SearchImagesService from "../../../services/SearchApi/SearchImagesService";
import SwiperCore, { Navigation } from "swiper";
import { ButtonCircleArrow } from "../Button/ButtonCircleArrow";
import { isMobile } from "react-device-detect";
SwiperCore.use([Navigation]);
export const CardImageContainer = ({
  source = "",
  id = 0,
  children = {},
  compact = false,
  onClick = () => 0,
  partner = "",
  partnerId = "",
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselArray, setCarouselArray] = useState([source]);
  const container = useRef(false);
  const [hide, setHide] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [message, setMessage] = useState("Loading");

  useEffect(() => {
    const observerOptions = {
      rootMargin: "400px",
      threshold: 0.1,
    };

    const cachedRef = container.current;
    const observer = new IntersectionObserver(
      ([e]) => setHide(e.intersectionRatio < 0.1),
      observerOptions
    );

    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [source]);

  useEffect(() => {
    id < 12 && setHide(false);
  }, [id]);

  useEffect(() => {
    setCarouselArray([source]);
    setImagesLoaded(false);
    setActiveIndex(0);
    setMessage("Loading");
  }, [source]);

  const getImages = async () => {
    const imagesResponse = await SearchImagesService.getImages(partner, partnerId);
    try {
      if (imagesResponse.images.length > 1 && imagesResponse.success) {
        imagesResponse.images.shift();
        setCarouselArray([...carouselArray, ...imagesResponse.images]);
        setImagesLoaded(true);
      } else {
        throw new Error("Sorry, no more images to display");
      }
    } catch (error) {
      setMessage("Sorry, no more images to display");
      setImagesLoaded(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (activeIndex === 1 && !imagesLoaded) {
      getImages();
    }
  }, [activeIndex, carouselArray]);

  const gradientImage = (imageUrl = "") => {
    if (isMobile)
      return `linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5)), url(${imageUrl}`;
    return `url(${imageUrl}`;
  };

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div ref={container} className="h-full relative">
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        className={`${
          compact
            ? "h-32 sm:h-40 w-40 rounded-4xl rounded-bl-none sm:rounded-bl-4xl rounded-tr-none rounded-br-none"
            : "h-68 xl:h-60 rounded-4xl xl:rounded-tr-none xl:rounded-br-none"
        }  bg-veryLightGray`}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(e) => setActiveIndex(e.activeIndex)}
      >
        {carouselArray.map((image, index) => (
          <SwiperSlide key={index}>
            <div
              onClick={() => onClick(index)}
              className="h-full transition-all duration-200 ease-in-out bg-veryLightGray cursor-pointer bg-cover bg-center relative"
              style={{
                backgroundImage: hide ? "none" : gradientImage(image),
                cursor: "zoom-in",
              }}
            />
          </SwiperSlide>
        ))}
        {!imagesLoaded && (
          <SwiperSlide>
            <div
              className={`${
                compact
                  ? "pb-3 justify-end"
                  : "xl:pb-10 xl:justify-end justify-center"
              } h-full flex items-center flex-col w-full`}
            >
              {message}
            </div>
          </SwiperSlide>
        )}
        <div
          ref={navigationPrevRef}
          className={`${
            compact ? "ml-1" : "ml-5"
          } w-auto h-full hidden xl:flex items-center absolute top-0 left-0 z-20 transform group-hover:translate-x-0 -translate-x-13 transition-transform ease-in-out duration-300`}
        >
          <ButtonCircleArrow darkMode={true} direction="left" />
        </div>
        <div
          ref={navigationNextRef}
          className={`${
            compact ? "mr-1" : "mr-5"
          } w-auto h-full hidden xl:flex items-center absolute top-0 right-0 z-20 transform group-hover:translate-x-0 translate-x-13 transition-transform ease-in-out duration-300`}
        >
          <ButtonCircleArrow darkMode={true} direction="right" />
        </div>
      </Swiper>
      <div className="absolute top-0 w-full h-full">{children}</div>
    </div>
  );
};
