
import { useState } from 'react';

const getQuery = () => {
    return typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : new URLSearchParams()
};

const getQueryStringVal = (key) => {
  return getQuery().get(key);
};

export const useQueryParam = ( key, defaultVal) => {
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);

  const updateUrl = (newVal) => {
    setQuery(newVal);

    const query = getQuery();

    newVal.trim() !== '' ? query.set(key, newVal) : query.delete(key);

  };

  return [query, updateUrl];
};