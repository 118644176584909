import { ButtonPrimary } from "../Elements/Button/ButtonPrimary";
import { Heading4 } from "../Elements/Heading/Heading4";
import { IconInstagram } from "../Elements/Icon/IconInstagram";

export const InstagramFeedModule = ({
  heading = "Follow us for some amazing travel inspiration!",
  images = [],
  account = "",
  darkMode = false,
}) => {
  return (
    <>
      <Heading4
        classes={`p-5 sm:p-10 xl:px-0 xl:pt-20 pb-5 text-center ${
          darkMode ? "text-white" : ""
        }`}
      >
        {heading}
      </Heading4>
      <div className="flex flex-col lg:flex-col">
        <div className="w-full grid grid-cols-3 lg:grid-cols-6 lg:grid-rows-1 gap-3 lg:gap-4">
          {images.map((image, index) => (
            <a
              href={image.link}
              key={index}
              rel="nofollow noreferrer"
              target="_blank"
            >
              <img
                className="rounded-4xl object-cover w-full"
                src={image.src}
                alt={account}
              />
            </a>
          ))}
        </div>
        <div className="w-full flex justify-center pt-8">
          <div className="lg:block">
            <ButtonPrimary
              onClick={() =>
                window.open(`https://www.instagram.com/${account}`, "_blank")
              }
              fullWidth={false}
              label={account}
              icon={
                <IconInstagram classes="text-white" fill="#fff" stroke="none" />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
