import React from "react";
import * as EmailValidator from "email-validator";
import { ButtonPrimary } from "../Elements/Button/ButtonPrimary";
import config from "../../config/general.json";

export class NewsletterSignup extends React.Component {
  url = config.subscriptionEndpoint;

  state = {
    email: "",
    invalid: false,
    submitting: false,
    submitted: false,
    success: false,
  };

  updateEmail = (e) => {
    this.setState({ email: e.target.value });
    this.validateEmail(e.target.value);
  };

  validateEmail = (email) => {
    const valid = EmailValidator.validate(email);
    if (valid) {
      this.setState({ invalid: false });
    }
    return valid;
  };

  post = () => {
    const { email } = this.state;
    if (this.validateEmail(email)) {
      this.setState({ submitting: true });
      var formdata = new FormData();
      formdata.append("EMAIL", email);
      const requestOptions = {
        method: "POST",
        mode: "no-cors",
        body: formdata,
      };
      fetch(this.url, requestOptions)
        .then((response) => {
          this.setState({ submitting: false, submitted: true, success: true });
        })
        .catch((error) => {
          this.setState({ submitting: false, submitted: true, success: false });
        });
    } else {
      console.log("Invalid!!");
      this.setState({ invalid: true });
    }
  };

  render() {
    const { invalid, submitting, submitted, success } = this.state;
    return (
      <>
        {!submitting && !submitted && (
          <div
            className={`${
              this.props.stacked
                ? "w-full"
                : "sm:flex-row sm:space-x-3 md:w-full"
            } flex flex-col`}
          >
            <div
              className={`${
                this.props.stacked ? "w-full" : "sm:w-3/4"
              } relative w-full`}
            >
              <div className="absolute inset-y-0 left-0 sm:left-2 w-13 sm:w-16 flex justify-center items-center pointer-events-none">
                <img
                  src="/images/icons/mail.svg"
                  alt="Map Pin Icon"
                  className=""
                />
              </div>
              <input
                className={`${
                  this.props.theme
                    ? "placeholder-darkModeLightGray text-darkModeLightGray bg-darkModeGray"
                    : "text-navyBlue bg-veryLightBlue border border-veryLightBlue placeholder-peakahBlue"
                } h-10 w-full rounded-4xl p-4 pl-12 sm:pl-16 text-base`}
                placeholder={"Email Address"}
                onChange={this.updateEmail}
              />
            </div>
            <ButtonPrimary
              label="Subscribe"
              fullWidth={false}
              onClick={this.post}
            />
          </div>
        )}
        {invalid && (
          <div className="text-red-100 italic">Please enter a valid email</div>
        )}
        {submitting && <div className="text-white italic">Sending...</div>}
        {submitted && success && (
          <div className="text-white italic">Subscription successful!</div>
        )}
        {submitted && !success && (
          <div className="text-red-100 italic">An unknown error occurred</div>
        )}
        <p className="text-white flex justify-center sm:justify-end pt-4 sm:pr-3 text-xs font-light">
          By signing up, you agree to our terms of use &amp; privacy policy
        </p>
      </>
    );
  }
}
