export const DealDetail = ({
  cheapest = false,
  priceNight = 0.0,
  price = 0.0,
  nights = 1,
  compact = false,
}) => {
  return (
    <div
      className={`
      ${cheapest ? "lg:flex-col" : "lg:items-center"}
      ${compact ? "flex-col items-start space-x-0" : "items-center space-x-2"}
      flex lg:flex-shrink-0 lg:space-x-0`}
    >
      <span
        className={`
        ${compact ? "text-xs font-semibold" : "text-xl"}
        ${cheapest && !compact ? "font-semibold text-2xl" : "text-xl"}
      `}
      >
        ${priceNight}
      </span>
      {cheapest && (
        <span
          className={`text-xs ${
            compact ? "text-xs" : "lg:text-sm"
          } text-blue-500 lg:flex-shrink-0`}
        >
          {nights} night{nights > 1 ? "s" : ""} ${price}
        </span>
      )}
    </div>
  );
};
