import { CardFeaturedLocation } from "../Elements/Card/CardFeaturedLocation";
import { CardFeaturedLocationPrimary } from "../Elements/Card/CardFeaturedLocationPrimary";
import { SearchContext } from "../../context/SearchContext/SearchContext";
import { useContext, useEffect, useState } from "react";
import { twoWeeks, twoWeeksTomorrow } from "../../utils/defaultDates";
import { Redirect, useHistory } from "react-router-dom";
import { CarouselNoControls } from "../Carousel/CarouselNoControls";
import { UrlContext } from "../../context/UrlContext/UrlContext";
import { SiteParams } from "../../config/constants/SiteParams";

const PrimaryLocation = ({ location = {}, searchByLocation = () => {}}) => (
  <div className="">
    <CardFeaturedLocationPrimary
      location={location}
      clicked={() => searchByLocation(location)}
    />
  </div>
);

const SecondaryLocations = ({ locations = [], searchByLocation = () => {} }) =>
  locations.map((location, index) => (
    <div key={index}>
      <CardFeaturedLocation
        location={location}
        clicked={() => searchByLocation(location)}
      />
    </div>
  ));

export const FeaturedBlocks = ({ blocks = [] }) => {
  const primaryLocation = blocks.find(
    (block) => block.primary && block.site !== "placeholder"
  );
  const secondaryLocations = blocks.filter((block) => !block.primary);
  const marketingLocation = blocks.find(
    (block) => block.primary && block.site === "placeholder"
  );
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [redirectToSearch, setRedirectToSearch] = useState(false);
  const [redirectToPlaceholder, setRedirectToPlaceholder] = useState(false);
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");
  const history = useHistory();

  const setLocationContext = ({ latitude, longitude, radius, name, site }) => {
    if (site === "placeholder") {
      setRedirectToPlaceholder(true);
    } else {
      setSearchContext({
        ...searchContext,
        coordinates: { lat: latitude, lng: longitude },
        radius: radius,
        destination: name,
        destinationShortName: name,
        startDate: twoWeeks,
        endDate: twoWeeksTomorrow,
      });
      setRedirectToSearch(true);
    }
  };

  useEffect(() => {
    if (urlContext !== "/") {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url);
    }
  }, [urlContext]);

  useEffect(() => {
    if (redirectToSearch) history.push({ pathname: `${url}/search` });
    return () => {
      setRedirectToSearch(false);
    };
  }, [redirectToSearch]);

  if (redirectToPlaceholder) {
    return <Redirect push to={`${url}/web3`} />;
  }

  return (
    <>
      <div className="lg:hidden block">
        <CarouselNoControls
          slides={blocks}
          clicked={(location) => setLocationContext(location)}
          wide={true}
        />
      </div>
      <div className="hidden lg:flex lg:flex-row gap-5">
        <div className="w-1/2 grid gap-5">
          <div className="grid grid-cols-2 gap-5">
            <SecondaryLocations
              locations={secondaryLocations.slice(0, 2)}
              searchByLocation={(location) => setLocationContext(location)}
            />
          </div>
          <PrimaryLocation
            location={primaryLocation}
            searchByLocation={(location) => setLocationContext(location)}
          />
        </div>
        <div className="w-1/2 grid gap-5">
          <PrimaryLocation
            location={marketingLocation}
            searchByLocation={(location) => setLocationContext(location)}
          />
          <div className="grid grid-cols-2 gap-5">
            <SecondaryLocations
              locations={secondaryLocations.slice(2, 4)}
              searchByLocation={(location) => setLocationContext(location)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
