export const LinkSecondary = ({
  label = "",
  onClick = () => true,
  icon = null,
  url = ""
}) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <a
    href={url}
    target="_blank"
    className="font-medium text-blue-500 flex items-center"
    label={label}
    onClick={onClick}
  >
    <span className="flex space-x-1 items-center">
      <span>
        {label}
      </span>
      {icon}
    </span>
  </a>
);
