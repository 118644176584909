import React, { useState, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { Listing } from "./Listing.js";
import { ListingGridSkeletons } from "./ListingGridSkeletons.js";
export const ListingGrid = (props) => {
  const {
    hotels = [],
    coordinates,
    loading = true,
    loadMore = () => 0,
    destination = "",
    nights = 1,
  } = props;
  const destinationLongitude = parseFloat(coordinates.lng);
  const destinationLatitude = parseFloat(coordinates.lat);
  const [openDrawer, setOpenDrawer] = useState(null);
  const [infiniteScrollLimit, setInfiniteScrollLimit] = useState(24);
  const [loadingMore, setLoadingMore] = useState(false);

  const bottomOfScrollHandler = (index) => {
    if (hotels.length === index && !loadingMore) {
      loadMore(index);
    }
    if (index === infiniteScrollLimit) {
      setInfiniteScrollLimit(infiniteScrollLimit + 12);
    }
  };

  useEffect(() => {
    setLoadingMore(false);
  }, [hotels.length]);

  return (
    <div className="sm:max-w-2xl xl:max-w-screen-lg sm:mx-auto xl:mx-0 grid p-3 mt-10 sm:mt-0 sm:pt-10 md:px-0 xl:px-10 grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-5 relative">
      {loadingMore ? "Fetching more results" : ""}
      {loading && <ListingGridSkeletons listings={isMobileOnly ? 2 : 4 } />}
      {hotels.length > 0 &&
        hotels
          .slice(0, infiniteScrollLimit)
          .map((listing, listing_id) => (
            <Listing
              enteredView={() => bottomOfScrollHandler(listing_id + 1)}
              hotel={listing}
              openMapCard={(id) => {
                props.cardOnMap(id);
                props.activateCard(id)
              }}
              hoveringOver={(id) => props.activateCard(id)}
              loading={loading}
              key={listing_id}
              id={listing_id}
              openDrawer={openDrawer}
              drawerOpened={(id) => setOpenDrawer(id)}
              clickImage={(index) => props.openGallery(listing, index)}
              nights={nights}
              destinationLongitude={destinationLongitude}
              destinationLatitude={destinationLatitude}
              destination={destination}
              compact={false}
            />
          ))}
    </div>
  );
};
