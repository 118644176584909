import React from 'react';
import { Logo } from '../Elements/Logo/Logo';
import { ButtonClose } from '../Elements/Button/ButtonClose'

export class HeaderMin extends React.Component {
	render() {
		return (
				<div className="max-w-xxl px-5 py-3 flex justify-between w-full items-center">
					<Logo darkMode={this.props.darkMode}/>
					<ButtonClose darkMode={this.props.darkMode} label={this.props.label} clicked={this.props.clicked}/>
				</div>
		)
	}
}