import React from "react";
import { Icon } from "./Icon";
export const IconPlane = (props) => (
  <Icon
    width={40}
    height={40}
    viewBox="0 0 22.263 27.539"
    strokeWidth={0}
    fill="currentColor"
    classes="text-peakahGreen"
    {...props}
  >
    <path
      d="M335.305,222.015a.768.768,0,0,0-.395-.649l-9.034-5.047a.771.771,0,0,1-.395-.687l.091-5.544a.793.793,0,0,0-.137-.45l-.073-.108a3.584,3.584,0,0,0-2.692-1.809,3.656,3.656,0,0,0-2.709,1.8l-.076.108a.76.76,0,0,0-.14.459l.091,5.565a.776.776,0,0,1-.395.687l-9,5.03a.768.768,0,0,0-.395.649l-.055,1.938a.771.771,0,0,0,.921.777l7.982-1.6a.772.772,0,0,1,.921.757v4.1a.758.758,0,0,1-.225.544l-4.08,4.08a.774.774,0,0,0,.032,1.122l.815.722a.766.766,0,0,0,.775.149l.228-.085c.158-.056.482-.178.9-.336.941-.351,3.186-1.192,4.165-1.52a.786.786,0,0,1,.485,0c.874.286,2.75.991,3.744,1.365.628.234,1.134.424,1.353.5l.243.085a.775.775,0,0,0,.8-.181l.742-.745a.771.771,0,0,0,0-1.09l-4.062-4.062a.758.758,0,0,1-.225-.544v-4.115a.771.771,0,0,1,.921-.757l8.014,1.61a.774.774,0,0,0,.924-.777Zm-10.273-.377a.545.545,0,0,0-.71.509v6.45a.541.541,0,0,0,.169.371l4.174,4.176-.272.272c-.78-.272-5.328-2.037-5.734-2.037-.342,0-4.957,1.765-5.705,2.037l-.3-.272,4.174-4.176a.481.481,0,0,0,.17-.371v-6.45a.521.521,0,0,0-.643-.509l-9.165,1.832.035-1.189,9.537-5.328a.559.559,0,0,0,.272-.509l-.1-6.246c1.222-1.733,2.274-1.733,3.463,0l-.1,6.246a.561.561,0,0,0,.307.509l9.537,5.328.035,1.189Z"
      transform="translate(-309.989 -207.72)"
    />
  </Icon>
);
