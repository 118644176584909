import React, { useContext, useRef, useEffect, useState } from 'react';
import { LabelSmall } from '../Elements/Label/LabelSmall';
import { SearchContext } from '../../context/SearchContext/SearchContext';
import { DestinationResults } from './DestinationResults';
import { IconMap } from '../Elements/Icon/IconMap';
import { useOnClickOutside } from '../../utils/onClickOutside';
import SearchCountries from '../../config/constants/SearchCountries';

export const DestinationField = ({
  displayErrors = false,
  displayLabel = true,
  displayLabelMobile = false,
  onFocus = () => true,
  placeholder = 'Where to?',
  darkMode = false
}) => {
  const destinationRef = useRef(null);
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [searchValue, setSearchValue] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [autocompleteSessionToken, setAutocompleteSessionToken] = useState(null);
  const [autoCompletePredictions, setAutocompletePredictions] = useState([]);
  const [placeDetailsService, setPlaceDetailsService] = useState(null);
  const [selectedPlaceDetails, addSelectedPlaceDetail] = useState(null);
  const [destinationInputFocused, setDestinationInputFocused] = useState(false);
  const [destinationValue, setDestinationValue] = useState(searchContext.destination);

  const placeDetailsResult = useRef();
  const container = useRef();

  const handlePlaceChange = (place_id = '') => {
    try {
      placeDetailsService.getDetails(
        {
          placeId: place_id,
          fields: ['geometry', 'formatted_address', 'name'],
        },
        (place) => {
          setAutocompletePredictions([]);
          addSelectedPlaceDetail(place);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      const autocomplete = new window.google.maps.places.AutocompleteService();
      const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
      const placesService = new window.google.maps.places.PlacesService(placeDetailsResult.current);
      setAutocompleteSessionToken(sessionToken);
      setAutocompleteService(autocomplete);
      setPlaceDetailsService(placesService);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const displaySuggestions = (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        console.error(status, predictions);
      }
      setAutocompletePredictions(predictions);
    };

    if (!searchValue) setAutocompletePredictions([]);

    if (autocompleteService && searchValue) {
      autocompleteService.getPlacePredictions(
        {
          input: searchValue,
          sessionToken: autocompleteSessionToken,
          componentRestrictions: {
            country: [
              SearchCountries.Australia,
              SearchCountries.UnitedKingdom,
              SearchCountries.UnitedStates,
              SearchCountries.Indonesia,
            ],
          },
        },
        (predictions = [], status = '') => displaySuggestions(predictions, status)
      );
    }

    return () => {};
  }, [searchValue]);

  const searchContextDestinationCached = useRef(searchContext.destination);

  useEffect(() => {
    if (displayErrors && !searchContext.destination && destinationRef) {
      destinationRef.current.focus();
    }
    if (searchContext.destination !== searchContextDestinationCached.current)
      setDestinationValue(searchContext.destination);
  }, [displayErrors, searchContext.destination]);

  useEffect(() => {
    if (selectedPlaceDetails) {
      setDestinationValue(selectedPlaceDetails.formatted_address);
      setSearchContext({
        ...searchContext,
        destination: selectedPlaceDetails.formatted_address,
        destinationShortName: selectedPlaceDetails.name,
        coordinates: {
          lat: selectedPlaceDetails.geometry.location.lat(),
          lng: selectedPlaceDetails.geometry.location.lng(),
        },
        radius: searchContext.radius,
      });
    }
  }, [selectedPlaceDetails]);

  useOnClickOutside(container, () => setSearchValue(null));

  useEffect(() => {
    if (destinationInputFocused) {
      setDestinationValue('');
      onFocus();
    } else {
      setDestinationValue(searchContext.destination);
    }
  }, [destinationInputFocused]);

  return (
    <div ref={container}>
      {displayLabel && (
        <LabelSmall displayLabelMobile={displayLabelMobile} title='Destination' className='pb-2 md:mb-0' darkMode={darkMode}/>
      )}
      <div className='relative md:mt-2'>
        <div className='absolute inset-y-0 left-0 w-13 flex justify-center items-center pointer-events-none'>
          <IconMap />
        </div>
        <input
          className={`h-10 text-sm ${darkMode ? "placeholder-darkModeLightGray text-darkModeLightGray bg-darkModeGray" :"text-peakahBlue focus:bg-white border placeholder-peakahBlue"} pl-12 w-full  outline-none truncate ..." ${
            displayErrors && !searchContext.destination & (!autoCompletePredictions?.length > 0)
              ? ' border-navyBlue placeholder-opacity-50 '
              : ' border-white'
          }
          ${
            autoCompletePredictions?.length > 0
              ? 'rounded-bl-none rounded-br-none rounded-4xl border-b-0 bg-white'
              : 'rounded-4xl bg-veryLightBlue'
          }
          `}
          placeholder={placeholder}
          ref={destinationRef}
          onChange={(e) => {
            setSearchValue(e.currentTarget.value);
            setDestinationValue(e.currentTarget.value);
          }}
          onFocus={() => setDestinationInputFocused(true)}
          onBlur={() => setDestinationInputFocused(false)}
          value={destinationValue}
        />

        {autoCompletePredictions?.length > 0 && (
          <DestinationResults results={autoCompletePredictions} onClick={(place_id) => handlePlaceChange(place_id)} />
        )}
      </div>
      {displayErrors && !searchContext.destination && (
        <div className='text-xs text-navyBlue italic pl-5 pt-2'>Where would you like to go?</div>
      )}
      <div ref={placeDetailsResult}></div>
    </div>
  );
};
