import React from 'react'
import { SkeletonParagraph } from '../Elements/Skeletons/SkeletonParagraph'
import { SkeletonImage } from '../Elements/Skeletons/SkeletonImage';
import { SkeletonHeading } from '../Elements/Skeletons/SkeletonHeading';

export const FeaturedLocationSkeleton = (props) => {
    return (
        <div className="animate-pulse">
            <SkeletonHeading />
            <SkeletonImage />
            <div className="mb-5 sm:mb-10">
                <SkeletonParagraph />
                <SkeletonParagraph />
            </div>
        </div>
    )
}