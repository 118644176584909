import { useState } from "react";
import { IconMap } from "../Elements/Icon/IconMap";
import { IconPencil } from "../Elements/Icon/IconPencil";
import { ScrimBasic } from "../Elements/Scrim/ScrimBasic";
import { TopDrawer } from "../Modules/TopDrawer";
import { TopBarSticky } from "../Search/TopBarSticky";
import { SearchBarMobile } from "./SearchBarMobile";

export const SearchBarMobileSticky = ({
  onSearch = () => true,
  display = true,
  leftIcon = <IconMap />,
  leftIconActiveState = <IconPencil />,
  leftText = "",
  leftTextActiveState = "Edit Search",
  rightText = "",
}) => {
  const [activeState, setActiveState] = useState(false);
  return (
    <>
      <TopBarSticky
        displayDrawer={activeState}
        leftIcon={leftIcon}
        leftText={leftText}
        onClickLeft={() => setActiveState(!activeState)}
        onClickRight={() => setActiveState(!activeState)}
        rightText={rightText}
        display={display}
      />
      <TopDrawer
        enabled={activeState}
        leftIcon={leftIconActiveState}
        leftText={leftTextActiveState}
        onClickRight={() => setActiveState(false)}
      >
        <SearchBarMobile
          displayAllInputsOnLoad={true}
          displayAsChild={true}
          displayPartnerLogos={false}
          searchWithoutSubmit={true}
          onSearch={() => {
            onSearch(true);
            setActiveState(false);
          }}
        />
      </TopDrawer>
      <ScrimBasic onClick={() => setActiveState(false)} enabled={activeState} />
    </>
  );
};
