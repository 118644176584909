export const LinkPrimary = ({
  fullWidth = true,
  buttonHover = true,
  label = "",
  onClick = () => true,
  icon = null,
  url = "",
}) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <a
    href={url}
    target="_blank"
    className={`
      ${fullWidth ? "w-full" : "w-auto"}
      ${
        buttonHover
          ? "text-white bg-peakahGreen group-hover:bg-peakahGreen xl:group-hover:text-white xl:text-peakahGreen xl:border xl:border-peakahGreen xl:bg-white"
          : "text-white bg-peakahGreen"
      } xl:transition-colors xl:ease-in-out xl:duration-500 font-medium py-2 px-6 rounded-full xl:shadow-none xl:group-hover:shadow-greenBox shadow-greenBox`}
    label={label}
    onClick={onClick}
  >
    <span className="text-base flex justify-center items-center space-x-2 h-full">
      <span>{label}</span>
      {icon}
    </span>
  </a>
);
