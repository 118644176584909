import { ButtonBasicShadow } from "../Elements/Button/ButtonBasicShadow";
import { IconRefresh } from "../Elements/Icon/IconRefresh";

const SearchAgainButton = ({ onClick, enabled = false }) => {
  return (
    <div className="w-full absolute z-10">
      {enabled && (
        <ButtonBasicShadow
          onClick={() => onClick()}
          classes="mt-16 xl:mt-4 mx-auto bg-white px-6 text-peakahGreen font-semibold h-10 flex items-center justify-between"
          label="Search here"
          fullWidth={false}
          icon={<IconRefresh width="17" strokeWidth={2.5} />}
        />
      )}
    </div>
  );
};

export default SearchAgainButton;
