import React from 'react';
import { Heading2 } from '../Elements/Heading/Heading2'
export class TagLine extends React.Component {
    render() {
		return (
            <Heading2 classes={this.props.classes}>
              {this.props.children}
            </Heading2>
        )
	}
}