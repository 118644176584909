export const DealDetailSingleLine = ({
  priceNight = 0.0,
  price = 0.0,
  nights = 1,
}) => {
  return (
    <div className="flex items-center space-x-2 text-blue-500">
      <span className="font-semibold text-2xl">${priceNight}</span>
      <span className="text-sm ">
        {nights} night{nights > 1 ? "s" : ""} ${price}
      </span>
    </div>
  );
};
