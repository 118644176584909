export const ButtonPrimary = ({
  fullWidth = true,
  label = "",
  onClick,
  icon = null,
  classes = "",
}) => (
  <button
    className={`${
      fullWidth ? "w-full" : "w-auto"
    } ${classes} font-medium text-white bg-peakahGreen h-10 px-4 md:px-6 rounded-4xl shadow-greenBox`}
    label={label}
    onClick={onClick}
  >
    <span className="text-base flex justify-center items-center text-white space-x-2">
      <span>{label}</span>
      {icon}
    </span>
  </button>
);
