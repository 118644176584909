import React from "react";
import { Icon } from "./Icon";
export const IconReviewsBadge = (props) => (
  <Icon
    width={40}
    height={40}
    viewBox="0 0 22.263 27.539"
    strokeWidth={0}
    fill="currentColor"
    classes="text-peakahGreen"
    {...props}
  >
    <path
      d="M739.222,215.262H735.3l2.16-2.105a.588.588,0,0,0-.326-1l-4.972-.722-2.224-4.506a.588.588,0,0,0-1.054,0l-2.224,4.506-4.972.722a.588.588,0,0,0-.326,1l2.16,2.105h-3.943a2.059,2.059,0,0,0-2.057,2.057v14.722a2.059,2.059,0,0,0,2.057,2.057h19.646a2.059,2.059,0,0,0,2.057-2.057V217.319A2.059,2.059,0,0,0,739.222,215.262ZM726,216.038l-2.967-2.891,4.1-.6a.588.588,0,0,0,.442-.321l1.833-3.715,1.833,3.715a.587.587,0,0,0,.443.321l4.1.6-2.967,2.891a.588.588,0,0,0-.169.52l.7,4.083-3.667-1.928a.588.588,0,0,0-.547,0l-3.667,1.928.7-4.083A.588.588,0,0,0,726,216.038Zm14.1,16a.883.883,0,0,1-.882.882H719.577a.883.883,0,0,1-.882-.882V217.319a.883.883,0,0,1,.882-.882h5.149l.232.226-.849,4.952a.588.588,0,0,0,.853.619l4.447-2.338,4.448,2.338a.588.588,0,0,0,.853-.619l-.849-4.952.232-.226h5.131a.883.883,0,0,1,.882.882Z"
      transform="translate(-717.52 -206.599)"
    />
    <path
      d="M748.581,266.924H736.974a.588.588,0,0,0,0,1.175h11.607a.588.588,0,0,0,0-1.175Z"
      transform="translate(-730.898 -249.374)"
    />
    <path
      d="M748.581,280.607H736.974a.588.588,0,0,0,0,1.175h11.607a.588.588,0,0,0,0-1.175Z"
      transform="translate(-730.898 -259.076)"
    />
  </Icon>
);
