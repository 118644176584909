import { isMobile } from "react-device-detect";
import { IconDiscord } from "../Elements/Icon/IconDiscord";
import { IconTwitter } from "../Elements/Icon/IconTwitter";

interface IFlock {
  heroUrl: string;
  imageUrl: string;
  imageAlt: string;
}

const Flock: React.FC<IFlock> = ({ heroUrl, imageUrl, imageAlt }) => {
  const peakahFence = `linear-gradient(rgba(0, 0, 0, -0.1), rgba(0, 0, 0, 0) 50%), url(${heroUrl}`;
  return (
    <>
      <div className="px-5 sm:px-10 xl:px-0 max-w-xxl mx-auto mt-10">
        <div className="flex flex-col items-center justify-center mb-5">
          <img src={imageUrl} alt={imageAlt} width={isMobile ? "250" : "150"} />
          <div className="flex flex-row gap-10 mt-5">
            {/* Discord */}
            <a
              href="https://discord.gg/peakybirds"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconDiscord />
            </a>
            {/* Twitter */}
            <a
              href="https://twitter.com/peaky_birds"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter />
            </a>
          </div>
        </div>
      </div>
      <div
        className="bg-cover bg-bottom bg-no-repeat h-80 h-screen 2xl:h-[32rem]"
        style={{
          backgroundImage: peakahFence,
        }}
      />
    </>
  );
};

export default Flock;
