import { ButtonBasic } from "../Elements/Button/ButtonBasic";
import { IconClose } from "../Elements/Icon/IconClose";

export const CovidCallOut = ({
  enabled = false,
  onClick = () => true
}) => {

  return (
    <>
      {enabled && (
        <div className="z-80 w-full bg-orange text-white pt-4 md:pt-2 p-2 flex flex-col lg:flex-row justify-between text-center items-center">
          <div></div>
          <div>
            COVID-19 Update: Be sure to check each individual provider's
            cancellation policy before booking!
          </div>
          <ButtonBasic
            color="text-white"
            icon={<IconClose classes="text-white" />}
            label={"Close"}
            fullWidth={false}
            onClick={onClick}
          />
        </div>
      )}
    </>
  );
};
