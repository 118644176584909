import { ButtonBasic } from "../Elements/Button/ButtonBasic";
import { IconClose } from "../Elements/Icon/IconClose";
import { ScrimBasic } from "../Elements/Scrim/ScrimBasic";

export const BottomDrawer = ({
  enabled = false,
  extraDrawer,
  fullScreen = false,
  fullWidth = false,
  children,
  iconLeft,
  leftLabel = "",
  onClickLeft = () => true,
  onClickRight = () => true,
  onClickScrim = () => true
}) => (
  <div className="w-full xl:hidden flex justify-center">
    <div
      className={`
    ${enabled ? "translate-y-0" : "translate-y-full"}
    ${fullScreen ? "top-0 mt-5 h-screen" : "bottom-0 h-auto"}
     transform transition-transform ease-in-out duration-300 fixed w-full md:max-w-3xl bg-white rounded-4xl rounded-bl-none rounded-br-none shadow-straight z-80 overflow-y-scroll`}
    >
      <div
        className="flex justify-between items-center sticky top-0 bg-white py-1 border-b border-b-gray-30 mx-5">
        <ButtonBasic
          classes="font-medium"
          align="left"
          fullWidth={false}
          icon={iconLeft}
          label={leftLabel}
          onClick={onClickLeft}
        />
        <ButtonBasic
          classes="font-normal"
          align="right"
          fullWidth={false}
          icon={<IconClose darkMode={true} />}
          label="Close"
          onClick={onClickRight}
        />
      </div>
      <div
        className={`
          ${fullScreen ? "pb-20" : "pb-4"}
          ${extraDrawer ? "pb-40" : ""}
          ${fullWidth ? "p-0" : "p-2 px-6"}
           text-blue-500`}
      >
        {enabled && children}
      </div>
    </div>
    <div className="flex justify-center bottom-0 left-0 right-0 fixed z-80">
      <div
        className={`${
          enabled && extraDrawer ? "translate-y-0 px-5 py-4" : "translate-y-20"
        } w-full md:max-w-3xl transition-transform transform ease-in-out duration-300 bg-white shadow-top`}
      >
        {enabled && extraDrawer}
      </div>
    </div>
    <ScrimBasic onClick={onClickScrim} enabled={enabled} />
  </div>
);
