import React from "react";
import { DealLink } from "./DealLink";
import { formatPrice } from "../../utils/formatPrice";
import { DealDetailSingleLine } from "./DealDetailSingleLine";
import { ProviderLogo } from "./ProviderLogo";
import PartnerRate from "../../models/availability/PartnerRate";
export const CheapestRateSingleLine = ({
  rate = PartnerRate,
  buttonHover = true,
}) => {
  return (
    <div className="flex items-center justify-between space-x-5 py-5 px-10">
      <DealDetailSingleLine
        price={formatPrice(rate.priceTotalStay)}
        priceNight={formatPrice(rate.pricePerNight)}
        nights={rate.nights}
      />
      <ProviderLogo bounding={true} partner={rate.partner} />
      <DealLink
        provider={rate.partner}
        rate={rate.pricePerNight}
        cheapest={true}
        url={rate.url}
        buttonHover={buttonHover}
        label="View Deal"
      />
    </div>
  );
};
