const headerImages = [
  "https://cdn.peakah.com/images/bf2279a4-785c-4087-ae10-93bb872c777a.jpg",
  "https://cdn.peakah.com/images/5de120a6-56c0-4ae1-bb3a-a7852321f456.jpg",
  "https://cdn.peakah.com/images/89deb01a-5c40-4c02-a1e1-7693c95fad54.jpg",
];
const headerText = [
  "Compare all accommodation",
  "From Hotels to Holiday Homes",
  "Find your perfect place",
];

export { headerImages, headerText };
