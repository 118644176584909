import { formatPrice } from "../../utils/formatPrice";

export const MapMarker = ({
  active = false,
  rate = 0.0,
  id = 0,
  onClick = () => true,
}) => (
  <div
    onClick={onClick}
    className={`${active ? "z-20" : ""} hover:z-30 flex flex-col items-center stroke-current text-peakahGreen absolute font-body`}
  >
    <div
      className={`${
        active
          ? "bg-peakahGreen text-white"
          : "border border-peakahGreen text-peakahGreen"
      } hover:bg-peakahGreen rounded-full px-2 py-px bg-white hover:z-30 text-sm hover:text-white cursor-pointer transition-colors duration-200 ease-in-out`}
    >
      ${formatPrice(rate)}
    </div>
  </div>
);
