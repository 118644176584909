export const ButtonToggle = ({
  checked = false,
  disabled = false,
  onChange = () => ({ checked: true, value: "" | 0 }),
  label = "",
  value = 0,
}) => {
  return (
    <label className="flex items-center cursor-pointer space-x-4">
      <div
        className={`${
          checked ? "bg-peakahGreen" : "bg-blue-200"
        } w-10 h-6 rounded-4xl p-1 flex transition-all ease-in-out`}
      >
        <div
          className={`${
            checked ? "transform translate-x-4" : "transform translate-x-0"
          } h-4 w-4 bg-white rounded-full transition-transform ease-in-out duration-300`}
        ></div>
      </div>
      <span className="capitalize text-blue-500 text-sm lg:text-base font-medium">
        {label}
      </span>
      <input
        hidden
        name={label}
        type="checkbox"
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={(event) =>
          onChange({
            checked: event.target.checked,
            value: event.target.value,
          })
        }
      />
    </label>
  );
};
