import React from "react";
import { Checkbox } from "../../Elements/Fields/Checkbox"
export const SelectionOption = ({
  checked = false,
  id = "",
  label = "",
  value,
  onChange = () => ({ checked: true, value: "" }),
}) => {
  return (
    <li key={id} className="text-sm flex items-center space-x-1 cursor-pointer">
      <label className="flex w-full items-center py-1 cursor-pointer">
        <div className="">
          <Checkbox
            value={value}
            onChange={onChange}
            checked={checked}
          />
        </div>
        <div className="flex items-center w-full">
          <span>
            {label}
          </span>
        </div>
      </label>
    </li>
  );
};
