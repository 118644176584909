export const LabelSmall = ({
  hiddenMobile = true,
  title = "",
  darkMode = false,
}) => (
  <div
    className={`${hiddenMobile ? "hidden xl:flex" : "flex"} text-xs ${
      darkMode ? "text-white" : "text-peakahBlue"
    }`}
  >
    {title}
  </div>
);
