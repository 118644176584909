import { IconCheck } from "../Icon/IconCheck";
export const Checkbox = ({
  checked = false,
  disabled = false,
  onChange = () => ({ checked: true, value: "" }),
  label = "",
  value = 0,
}) => {
  return (
    <label className="flex items-center flex-row cursor-pointer">
      <div
        className={`${checked ? "bg-peakahGreen border-peakahGreen" : ""}
        text-white transition-colors duration-200 ease-in-out w-5 h-5 mr-3 border-lightBlue border rounded-full flex items-center justify-center`}
      >
        {checked && <IconCheck />}
      </div>
      <span className="capitalize text-blue-600 text-base">{label}</span>
      <input
        hidden
        name={label}
        type="checkbox"
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={(event) =>
          onChange({ checked: event.target.checked, value: event.target.value })
        }
      />
    </label>
  );
};
