import React, { useContext, useEffect, useRef, useState } from "react";
import { CarouselSlide } from "./CarouselSlide";
import { CarouselControls } from "./CarouselControls";
import LandingPagesCarouselSlide from "../../models/api/cms/LandingPagesCarouselSlide";
import { UrlContext } from "../../context/UrlContext/UrlContext";
import { SiteParams } from "../../config/constants/SiteParams";
import { CarouselIndexIndicator } from "./CarouselIndexIndicator";

interface ICarousel {
  heading: string;
  images: LandingPagesCarouselSlide[];
  exclude?: string;
}

export const Carousel: React.FC<ICarousel> = ({ heading, images, exclude }) => {
  const carouselRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [slideWidth, setCarouselSlideWidth] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [carouselImages, setCarouselImages] =
    useState<LandingPagesCarouselSlide[]>();
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!images) return undefined;
    const slides = images.filter((image) => image.url !== exclude);
    setTimeout(() => {
      setCarouselImages(slides);
    }, 1000);
    return () => {
      setCarouselImages(undefined);
    };
  }, [exclude, images]);

  useEffect(() => {
    if (urlContext !== "/") {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url);
    }
  }, [urlContext]);

  return (
    <>
      <div className="text-xl md:text-2xl text-blue-500 font-medium pb-8 text-center md:px-10 pb-5 pt-5 lg:px-0 lg:py-10 leading-normal">
        {heading}
      </div>
      <div className="relative">
        <div
          className="flex overflow-x-scroll scrolling-touch carousel py-5 lg:py-0"
          ref={carouselRef}
        >
          <div className="hidden absolute lg:flex left-0 ml-0 h-full items-center">
            <CarouselControls
              direction="left"
              clicked={() => {
                carouselRef.current.scrollLeft -= slideWidth;
                setActiveSlide(activeSlide > 0 ? activeSlide - 1 : 0);
              }}
            />
          </div>
          <div className="hidden absolute lg:flex right-0 mr-0 h-full items-center">
            {carouselImages && (
              <CarouselControls
                direction="right"
                clicked={() => {
                  carouselRef.current.scrollLeft += slideWidth;
                  setActiveSlide(
                    activeSlide < carouselImages.length - 2
                      ? activeSlide + 1
                      : carouselImages.length - 2
                  );
                }}
              />
            )}
          </div>
          {carouselImages &&
            carouselImages.map((item, index) => (
              <CarouselSlide
                alt={item.carouselImageAlt}
                url={`${url}/staycations/${item.url}`}
                image={item.carouselImageUrl}
                name={item.name}
                key={index}
                firstToDisplay={index === 0}
                loaded={(width: number) => setCarouselSlideWidth(width)}
                wide={false}
              />
            ))}
        </div>
        {carouselImages && (
          <CarouselIndexIndicator
            classes="hidden lg:flex"
            length={carouselImages.length - 1}
            activeIndex={activeSlide}
            darkMode={true}
          />
        )}
      </div>
    </>
  );
};
