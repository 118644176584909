import React, { useState, useRef, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { LabelSmall } from "../Elements/Label/LabelSmall";
import { MobileOverlay } from "../Modules/MobileOverlay";
import { formatDateShort } from "../../utils/formatDate";
import { GuidanceText } from "./GuidanceText";
import { useOnClickOutside } from "../../utils/onClickOutside";
import { SearchContext } from "../../context/SearchContext/SearchContext";
import { IconCalendar } from "../Elements/Icon/IconCalendar";
import { IconChevronDown } from "../Elements/Icon/IconChevronDown";
import { endDateMin } from "../../utils/defaultDates";
export const MobileDatePicker = ({
  dark = false,
  displayLabelMobile = true,
  label = "",
  onDatesSelected = () => true,
  darkMode=false
}) => {
  const [display, setDisplay] = useState(false);
  const [warning, setWarning] = useState(null);
  const [closeOverlay, setCloseOverlay] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => closeComponent());

  const [searchContext, setSearchContext] = useContext(SearchContext);

  const startDate = searchContext.startDate;
  const endDate = searchContext.endDate;
  const [localState, setLocalState] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  const startDateLabel = startDate ? formatDateShort(startDate) : "Check in";
  const endDateLabel = endDate ? formatDateShort(endDate) : "Check out";

  const closeComponent = () => {
    setCloseOverlay(true);
    setTimeout(() => {
      setCloseOverlay(false);
    }, 400);
    setTimeout(() => {
      setDisplay(!display);
    }, 300);
  };

  const handleDateChange = (date) => {
    if (!localState.startDate && !localState.endDate)
      setLocalState({ ...localState, startDate: date });
    if (!localState.endDate) {
      const endDateBeforeStartDate = date < localState.startDate;
      if (endDateBeforeStartDate) {
        setWarning("Check out date must be after check in date");
        return null;
      }
      setLocalState({ ...localState,  endDate: date });
      setSearchContext({
        ...searchContext,
        startDate: localState.startDate,
        endDate: date,
      });
      onDatesSelected();
      // Then close the display with a fade out animation first
      closeComponent();
      return true
    }
    if (localState.startDate && localState.endDate) {
      // Starting new date range so save the start and delete the end
      setLocalState({ startDate: date, endDate: null });
    }
    return true
  };

  useEffect(() => {
    setLocalState({
      endDate: searchContext.endDate,
      startDate: searchContext.startDate,
    });
  }, [searchContext.startDate, searchContext.endDate]);

  return (
    <div className="md:mt-2">
      <LabelSmall
        displayLabelMobile={displayLabelMobile}
        title={label}
      />
      <div
        className="relative mt-2 cursor-pointer"
        onClick={() => setDisplay(!display)}
      >
        <div className="z-20 absolute inset-y-0 left-0 w-13 flex items-center justify-center">
          <IconCalendar />
        </div>
        <div className={`flex items-center h-10 text-base ${darkMode? "text-darkModeLightGray bg-darkModeGray" : "text-peakahBlue bg-veryLightBlue border outline-none border-white"} pl-12 w-full rounded-4xl`}>
          {startDateLabel} - {endDateLabel}
        </div>
        <div className="z-20 h-10 absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none md:hidden lg:flex">
          <IconChevronDown classes="text-peakahBlue" />
        </div>
      </div>
      {display && (
        <MobileOverlay
          darkMode={dark}
          label="Close"
          clicked={() => setDisplay(!display)}
        >
          <div className={closeOverlay ? "animate-fadeOut" : " "}>
            <p
              className={`${
                dark ? "text-white" : "text-peakahBlue"
              } p-4 text-center w-full h-20 flex items-center justify-center`}
            >
              <GuidanceText
                startDate={localState.startDate}
                endDate={localState.endDate}
                warning={warning}
              />
            </p>
            <div ref={ref}>
              <DatePicker
                onChange={(date) => handleDateChange(date)}
                selected={localState.startDate}
                startDate={localState.startDate}
                endDate={localState.endDate}
                inline={true}
                dateFormat="d MMM"
                minDate={endDateMin}
                darkMode={darkMode}
              />
            </div>
          </div>
        </MobileOverlay>
      )}
    </div>
  );
};
