import React, { useState, useEffect, useRef } from "react";
import Hotel from "../../models/availability/Hotel"
import { CardImage } from "../Elements/Card/CardImage";
import { IconClose } from "../Elements/Icon/IconClose";
import { IconWedge } from "../Elements/Icon/IconWedge";
import { CheapestRateCompact } from "./CheapestRateCompact";

export const ListingCompact = (props) => {
  const {
    hoveringOver = () => 0,
    loading = true,
    nights = 1,
    hotel = new Hotel(),
    id,
    fixedToBottom = false,
    onBoundingClientRectChange = () => {},
    onTouchEndCapture = () => true,
    onTouchStartCapture = () => true,
  } = props;
  const typeName = hotel.type === 1 ? "Hotel" : "Alternative";
  const [mounted, setIsMounted] = useState(false);
  const [loaded, setIsLoaded] = useState(false);
  const containerRef = useRef(null)
  useEffect(() => {
    setIsMounted(() => true);
  }, []);

  useEffect(() => {
    const boundingRect = containerRef.current.getBoundingClientRect()
    onBoundingClientRectChange(boundingRect)
  }, [containerRef, hotel.id])

  useEffect(() => {
    !loading && setIsLoaded(() => true);
  }, [loading]);

  const animationClasses = () => {
    if (mounted && !fixedToBottom)
      return "opacity-100 transform -translate-y-42 -translate-x-42";
    if (!mounted && !fixedToBottom)
      return "opacity-0 transform -translate-y-20 -translate-x-42";
    if (mounted && fixedToBottom)
      return "opacity-100 rounded-br-none rounded-bl-none";
    if (!mounted && fixedToBottom)
      return "opacity-0 transform translate-y-20 rounded-br-none ";
  };

  return (
    <div
      onTouchStartCapture={onTouchStartCapture}
      onTouchEndCapture={onTouchEndCapture}
      onMouseOver={() => hoveringOver(hotel.id)}
      className={`${fixedToBottom ? "fixed bottom-0 w-full" : "relative"} transition-all duration-300 ease-in-out z-30 
      font-body w-full sm:w-96 h-32 sm:h-40 shadow-straight-lg rounded-4xl bg-white text-navyBlue flex justify-between group
      ${animationClasses()}`}
      ref={containerRef}
    >
      <div className="h-full">
        <CardImage
          typeName={typeName}
          type={hotel.type}
          loaded={loaded}
          image={hotel.photo}
          clickImage={() => props.clickImage()}
          stars={hotel.stars}
          roomCount={hotel.roomCount}
          maxGuests={hotel.maxGuests}
          reviewScore={hotel.reviewScore}
          compact={true}
          id={id}
          name={hotel.name}
          partner={hotel.imageGalleryPartnerDetails.partner}
          partnerId={hotel.imageGalleryPartnerDetails.partnerId}
        />
      </div>
      <div className="relative h-full w-full flex-col flex justify-between p-3 pb-4">
        <div className="text-sm font-semibold leading-6 text-blue-500 line-clamp-2 w-5/6">
          {hotel.name}
        </div>
        <div className="w-full">
          <CheapestRateCompact
            rate={hotel.rates[0]}
            nights={nights}
            buttonHover={false}
          />
        </div>
        <div
          onClick={() => {
            setIsMounted(false);
            setTimeout(() => props.clickClose(), 300);
          }}
          className="p-3 absolute top-0 right-0 z-70 cursor-pointer"
        >
          <IconClose classes="text-peakahGreen" />
        </div>
      </div>
      <div className="absolute bottom-0 -mb-6 w-full hidden sm:flex justify-center">
        <IconWedge fill="white" stroke="none" />
      </div>
    </div>
  );
};
