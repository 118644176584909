export const content = {
  metaDescription:
    "Find the best hotel &amp; holiday home rental deals all in 1 place. First metasearch platform showing all hotels, motels, holiday homes &amp; alternative accommodation.",
  metaTitle: "Leading Accommodation Comparison Site | Best Deals | Peakah",
  aboutBlocks: [
    {
      label: "Why Peakah?",
      heading: "Finding your best deals for Hotels & Vacation Rentals",
      icon: "globe",
      text: "We compare everything! It's not just Holiday Homes and Hotel options! For the first time, search and find your ideal accommodation by comparing prices from across the web. Filter your search criteria and enter your desired dates. Let us do the rest!",
    },
    {
      label: "See Reviews",
      heading: "Reviews can help find your ideal accommodation",
      icon: "reviewsBadge",
      text: "Want the best places to stay? peakah.com shows you the average rating and reviews from each accommodation site, helping you book the right place for your getaway!",
    },
    {
      label: "Book now",
      heading: "Find out how easy it is to book with peakah.com",
      icon: "plane",
      text: "When you're happy with an offer, simply click on the 'View Deal' tab. You'll be forwarded to the relevant online travel agent, where you can complete your booking with the deal you found on peakah.com - it's that simple!",
    },
  ],
};

export interface IFeaturedLocation {
  name: string;
  site: string;
  description: string;
  image: string;
  latitude: number;
  longitude: number;
  order: number;
  primary: boolean;
  radius: number;
}

export const featuredLocations: IFeaturedLocation[] = [
  {
    name: "Melbourne, VIC",
    site: "AUS",
    description:
      "Situated on Queensland's Sunshine Coast, Noosa has it all from stunning beaches, lakes & scenic National Parks. A perfect destination to take a well earned break.",
    image: "https://cdn.peakah.com/images/af607c16-7266-4310-bc1e-606850f17b5c.jpg",
    latitude: -37.815219,
    longitude: 144.960782,
    order: 0,
    primary: true,
    radius: 5000,
  },
  {
    name: "Peaky Birds",
    site: "placeholder",
    description: "Placeholder for sponsored advertisement for the website",
    image: "https://cdn.peakah.com/images/7edfabd9-9b74-47ee-aca0-c585e3138a0c.png",
    latitude: 0,
    longitude: 0,
    order: 3,
    primary: true,
    radius: 5000,
  },
  {
    name: "Sydney, NSW",
    site: "AUS",
    description:
      "Situated on Queensland’s Sunshine Coast, Noosa has it all from stunning beaches, lakes & scenic National Parks. A perfect destination to take a well earned break.",
    image:
      "https://cdn.peakah.com/images/6bb353e9-666b-48c9-b503-a34e1b397ec4.jpg",
    latitude: -33.864326,
    longitude: 151.204634,
    order: 1,
    primary: false,
    radius: 5000,
  },
  {
    name: "Brisbane, QLD",
    site: "AUS",
    description:
      "Situated on Queensland’s Sunshine Coast, Noosa has it all from stunning beaches, lakes & scenic National Parks. A perfect destination to take a well earned break.",
    image: "https://cdn.peakah.com/images/b9532add-5340-40b1-aa87-a4377d3c8677.jpg",
    latitude: -27.468511,
    longitude: 153.026965,
    order: 2,
    primary: false,
    radius: 5000,
  },
  {
    name: "Whitsundays, QLD",
    site: "AUS",
    description:
      "Situated on Queensland’s Sunshine Coast, Noosa has it all from stunning beaches, lakes & scenic National Parks. A perfect destination to take a well earned break.",
    image: "https://cdn.peakah.com/images/af3c4416-381a-4c72-a7e0-a34eda3121be.jpg",
    latitude: -20.269020,
    longitude: 148.717474,
    order: 3,
    primary: false,
    radius: 5000,
  },
  {
    name: "Gold Coast, QLD",
    site: "AUS",
    description:
      "Situated on Queensland’s Sunshine Coast, Noosa has it all from stunning beaches, lakes & scenic National Parks. A perfect destination to take a well earned break.",
    image: "https://cdn.peakah.com/images/b8c28152-cb7e-4c65-8566-ed521c077066.jpg",
    latitude: -28.021522,
    longitude: 153.425998,
    order: 4,
    primary: false,
    radius: 5000,
  },
  {
    name: "San Francisco, CA",  
    site: "US",
    description:
      "Picturesque city known for its hills, cable cars, and Victorian architecture. ",
    image: "https://cdn.peakah.com/images/b10b1773-18b6-4059-9133-1b5739dec10a.jpg",
    latitude: 37.7749,
    longitude: -122.4194,
    order: 0,
    primary: true,
    radius: 5000,
  },
  {
    name: "Austin, TX",
    site: "US",
    description:
      "Known for its vibrant music scene and outdoor activities..",
    image: "https://cdn.peakah.com/images/45bc617b-1fe6-4072-bd39-7e178668e387.jpg",
    latitude: 30.2672,
    longitude: -97.7431,
    order: 1,
    primary: false,
    radius: 5000,
  },
  {
    name: "Miami, FL",
    site: "US",
    description:
      "Dynamic and diverse city with a unique cultural mix of Latin American, Caribbean, and American influences.",
    image: "https://cdn.peakah.com/images/63d641e6-780e-4dfe-89f7-3d23b4494006.jpg",
    latitude: 25.76168,
    longitude: -80.19179,
    order: 2,
    primary: false,
    radius: 5000,
  },
  {
    name: "Waikiki Beach, HI",
    site: "US",
    description:
      "Features soft white sand, clear turquoise waters, and is surrounded by stunning high-rise hotels and towering palm trees.",
    image: "https://cdn.peakah.com/images/fa6b7102-c02b-4ed5-bf40-f451f7173d11.jpg",
    latitude: 21.2761,
    longitude: -157.8273,
    order: 3,
    primary: false,
    radius: 5000,
  },
  {
    name: "Central Park, NY",
    site: "US",
    description:
      "Situated at the south of New York, here lies lady liberty hershelf.",
    image: "https://cdn.peakah.com/images/15481620-3c03-4126-b628-8a22bf92e09a.jpg",
    latitude: 40.78286,
    longitude: -73.96536,
    order: 4,
    primary: false,
    radius: 5000,
  },
];
