export const BadgeRating = ({
  reviewScore = 0.0,
  suffix = "",
  small = false,
}) => {
  const color = (reviewScore) => {
    if (reviewScore === "0.0" || reviewScore === 0) return "hidden";
    if (reviewScore >= 9) return "bg-purple";
    if (reviewScore >= 8.5) return "bg-brightBlue";
    if (reviewScore >= 7.5) return "bg-brightGreen";
    if (reviewScore < 7.5) return "bg-orange";
  };
  return (
    <span
      className={`${
        small
          ? "px-1.5 py-0 text-xxs"
          : "px-3 py-1 text-xs md:text-xs"
      } rounded-4xl text-white shadow-sm ${color(reviewScore)}`}
    >
      {reviewScore}
      {suffix}
    </span>
  );
};
