import React from "react";
import { Link } from "react-router-dom";

export const MainNavigation = (props) => (
    <div className="hidden sm:flex text-white justify-end space-x-10 text-base">
      {props.links.map((link, index) => (
        <Link key={index} to={link.link}>
          {link.name}
        </Link>
      ))}
    </div>
  );
