export const formatDashDate = (date) => {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();

    return [date.getFullYear(),
    (mm > 9 ? '-' : '-0') + mm,
    (dd > 9 ? '-' : '-0') + dd
    ].join('');
};

export const formatDateShort = (date) => {
    if (date === undefined || date === null) return null    
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${day} ${month}`
};