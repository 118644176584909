import React from "react";
import { ButtonPrimary } from "../Elements/Button/ButtonPrimary";
import { IconSearch } from "../Elements/Icon/IconSearch";
export const StickySearch = ({
  click,
  display = false,
  name = "",
}) => {
  return (
    <>
      {display && (
        <div className="bottom-0 fixed w-full flex lg:hidden justify-center p-5 z-20">
          <div>
            <ButtonPrimary
              label={`Search ${name ? name : ""}`}
              onClick={click}
              classes="px-5"
              icon={<IconSearch classes="text-white" />}
            />
          </div>
        </div>
      )}
    </>
  );
};
