import React, { useState, useEffect } from "react";
import { Headline } from "./Headline";

function HeaderBanner({ ...props }) {
  const [active, setActive] = useState(
    props.forceActiveImage ? parseInt(props.forceActiveImage) : 0
  );
  const [firstTransitionIsDone, setFirstTransitionIsDone] = useState(false);
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // setting image transition
  useEffect(() => {
    if (props.forceActiveImage) return;
    if (!props.cycle) return;
    const timeout = setTimeout(() => {
      async function startImageTransition() {
        if (firstTransitionIsDone) await wait(props.transition);
        setActive(active === props.images.length - 1 ? 0 : active + 1);
        setFirstTransitionIsDone(true);
      }
      if (props.cycle) {
        startImageTransition();
      }
    }, props.interval);

    return () => clearTimeout(timeout);
  }, [active, firstTransitionIsDone, props]);

  // force active image
  useEffect(() => {
    if (!props.forceActiveImage) return;
    setActive(parseInt(props.forceActiveImage));
  }, [active, props.forceActiveImage]);

  return (
    <div className="relative overflow-hidden w-full h-full " {...props}>
      {/* Looping each image and text */}
      {props.images.map((image, index) => (
        <div
          className="absolute w-full h-full bg-cover bg-center"
          key={`${image}-${index}`}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) 30%), url(${image})`,
            opacity: active === index ? 1 : 0,
            transition: `opacity ${props.transition}ms`,
            ...props.imageStyles,
          }}
          {...props.imageProps}
        >
          <div className="w-full mx-auto h-full flex items-center absolute ">
            <div className="text-center items-center mx-auto p-10 pt-8 lg:pt-16">
              <Headline classes="font-semibold text-white text-3xl xl:text-4xl">
                {props.texts[index]}
              </Headline>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

HeaderBanner.defaultProps = {
  forceActiveImage: null,
  cycle: true,
  interval: 5000,
  transition: 5000,
};

export default HeaderBanner;
