import React from "react";
import { ProviderLogo } from "../ProviderLogo";
import { DealLink } from "../DealLink";
import { DealDetail } from "../DealDetail";
import { formatPrice } from "../../../utils/formatPrice";
import PartnerRate from "../../../models/availability/PartnerRate";

export const MorePrices = ({ rates = [new PartnerRate()], nights = 1 }) => {
  return rates.map((rate, index) => {
    if (index === 0 || index > 2) return null;
    return (
      <div key={index} className="flex justify-between w-full py-2">
        <DealDetail
          price={formatPrice(rate.priceTotalStay)}
          cheapest={false}
          priceNight={formatPrice(rate.pricePerNight)}
          nights={nights}
        />

        <ProviderLogo partner={rate.partner} bounding={true} />

        <DealLink
          provider={rate.partner}
          rate={rate.priceTotalStay}
          cheapest={false}
          index={index}
          url={rate.url}
        />
      </div>
    );
  });
};
