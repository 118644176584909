import { IconChevronDown } from "../Icon/IconChevronDown";
import { IconChevronLeft } from "../Icon/IconChevronLeft";
import { IconChevronRight } from "../Icon/IconChevronRight";
import { IconChevronUp } from "../Icon/IconChevronUp";

export const ButtonCircleArrow = ({
  darkMode = false,
  direction = "right",
  onClick = () => true,
}) => {
  const iconMap = {
    left: (
      <IconChevronLeft classes={darkMode ? "text-white" : "text-blue-400"} />
    ),
    right: (
      <IconChevronRight classes={darkMode ? "text-white" : "text-blue-400"} />
    ),
    up: <IconChevronUp classes={darkMode ? "text-white" : "text-blue-400"} />,
    down: (
      <IconChevronDown classes={darkMode ? "text-white" : "text-blue-400"} />
    ),
  };

  return (
    <button
      onClick={onClick}
      className={`${
        darkMode
          ? "bg-black bg-opacity-60 hover:bg-opacity-80"
          : "bg-blue-100 border-blue-300 border"
      } rounded-full h-8 w-8 flex justify-center items-center`}
    >
      {iconMap[direction]}
    </button>
  );
};
