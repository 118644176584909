import { formatDashDate } from '../../utils/formatDate';
class AvailabilityRequest {
  lat: number;
  lng: number;
  radius: number;
  radiusTo: number;
  startDate: string;
  endDate: string;
  adults: number;
  children: number;
  rooms: number;
  nights: number;
  guests: number;
  currency: string;
  constructor({
    lat = 0.0,
    lng = 0.0,
    radius = 0,
    radiusTo = 2000,
    startDate = new Date(),
    endDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
    adults = 2,
    children = 0,
    rooms = 1,
    nights = 1,
    currency = 'AUD'
  }) {
    this.lat = lat;
    this.lng = lng;
    this.radius = radius;
    this.radiusTo = radiusTo;
    this.startDate = formatDashDate(startDate);
    this.endDate = formatDashDate(endDate);
    this.adults = adults;
    this.children = children;
    this.guests = adults + children;
    this.rooms = rooms;
    this.nights = nights;
    this.currency = currency;
  }
}

export default AvailabilityRequest;
