import React, { useContext } from "react";
import { RatingsFilter } from "./RatingsFilter";
import { FilterContext } from "../../context/FilterContext/FilterContext";
import { PriceSlider } from "./PricerSlider";
import { AccommodationTypeFilter } from "./AccomodationTypeFilter";
import { AmenitiesFilter } from "./AmenitiesFilter/AmenitiesFilter";
import ListingType from "../../models/availability/ListingType";

export const FilterBar = ({
  amenitiesFilters = {},
  priceMin = 1,
  priceMax = 200,
  amenitiesChange = () => { },
  listingTypes = [ListingType],
  priceChange = () => 0,
  starChange = () => 0,
  ratingChange = () => 0,
  typeChange = () => 0,
}) => {
  const [filterContext] = useContext(FilterContext);
  const { price, rating, star, type } = filterContext;
  return (
    <div className="flex flex-col w-full bg-white space-y-4">
      <div>
        <AccommodationTypeFilter
          listingTypes={listingTypes}
          onTypeChange={(type) => typeChange(type)}
          listingTypesFromContext={type}
        />
      </div>
      <div className="flex flex-col">
        <PriceSlider
          priceMin={priceMin}
          priceMax={priceMax}
          price={price}
          priceChange={(value) => priceChange(value)}
        />
      </div>
      <AmenitiesFilter
        onChange={({ enabled, id }) => amenitiesChange({ enabled, id })}
        amenitiesFilters={amenitiesFilters}
      />
      <RatingsFilter
        star={star}
        rating={rating}
        onStarChange={(stars) => starChange(stars)}
        onRatingChange={(rating) => ratingChange(rating)}
      />
    </div>
  );
};
