import { IconGlobe } from "../Elements/Icon/IconGlobe";
import { IconPlane } from "../Elements/Icon/IconPlane";
import { IconPriceTag } from "../Elements/Icon/IconPriceTag";
import { IconReviewsBadge } from "../Elements/Icon/IconReviewsBadge";
import { CarouselControlButton } from "../Carousel/CarouselControlButton";
import { Heading4 } from "../Elements/Heading/Heading4";

interface ICarouselControls {
  clicked: () => void;
  direction: "right" | "left";
}

export const CarouselControls: React.FC<ICarouselControls> = ({
  clicked,
  direction = "right",
}) => {
  return (
    <CarouselControlButton direction={direction} clicked={() => clicked()} />
  );
};

export default class ContentBlockInfo {
  constructor(
    public heading: string,
    public icon: string,
    public label: string,
    public text: string
  ) {}
}

interface IContentBlock {
  content: ContentBlockInfo[];
  darkMode: boolean;
}

export const ContentBlocks: React.FC<IContentBlock> = ({
  content,
  darkMode,
}) => {
  const iconMap = {
    globe: <IconGlobe />,
    plane: <IconPlane />,
    priceTag: <IconPriceTag />,
    reviewsBadge: <IconReviewsBadge />,
  };

  return (
    <>
      <Heading4
        classes={`p-5 sm:p-10 xl:px-0 xl:pt-20 pb-5 text-center ${
          darkMode ? "text-white" : ""
        }`}
      >
        Compare all types of accommodation
      </Heading4>

      <div className="hidden xl:flex xl:w-full xl:justify-between gap-4">
        {content.map((contentBlock, index) => (
          <div
            className={`${
              darkMode ? "bg-darkModeDarkGray" : "bg-white"
            } shadow-straight h-[28rem] w-112 p-10 lg:p-10 rounded-2xl text-center`}
            key={index}
          >
            <div className="flex flex-col items-center">
              {iconMap[contentBlock.icon as keyof typeof iconMap]}
              <div className="flex text-lg text-peakahGreen py-5">
                {contentBlock.label}
              </div>
              <div
                className={`text-xl ${
                  darkMode ? "text-white" : "text-blue-500"
                } font-medium pb-8`}
              >
                {contentBlock.heading}
              </div>
              <div
                className={`text-base ${
                  darkMode ? "text-darkModeLightGray" : "text-black"
                }`}
              >
                {contentBlock.text}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="xl:hidden block relative">
        <div className="flex overflow-x-scroll scrolling-touch carousel">
          {content.map((contentBlock, index) => (
            <div className="px-2">
              <div
                className={`${
                  darkMode ? "bg-darkModeDarkGray" : "bg-white"
                } shadow-straight h-[28rem] w-88 p-10 rounded-2xl text-center`}
                key={index}
              >
                <div className="flex flex-col items-center">
                  {iconMap[contentBlock.icon as keyof typeof iconMap]}
                  <div className="flex text-lg text-peakahGreen py-5">
                    {contentBlock.label}
                  </div>
                  <div
                    className={`text-xl ${
                      darkMode ? "text-white" : "text-blue-500"
                    } font-medium pb-8`}
                  >
                    {contentBlock.heading}
                  </div>
                  <div
                    className={`text-base ${
                      darkMode ? "text-darkModeLightGray" : "text-black"
                    }`}
                  >
                    {contentBlock.text}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
