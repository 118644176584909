import Hotel from "../availability/Hotel";
import ListingType from "../availability/ListingType";
import PropertyAttribute from "../availability/PropertyAttribute";
class AvailabilityResponse {
  constructor({
    error,
    status = 200,
    data = [Hotel],
    count = 0,
    message = "",
    listingTypes = [ListingType],
    propertyAttributes = [PropertyAttribute],
  }) {
    this.count = count;
    this.data = data.map((hotel) => new Hotel(hotel));
    this.listingTypes = listingTypes;
    this.message = message;
    this.propertyAttributes = propertyAttributes;
    this.status = status;
    this.error = error
  }
}

export default AvailabilityResponse;
