import { IconFrontier } from "../components/Elements/Icon/IconFrontier";
import { IconMetaverse } from "../components/Elements/Icon/IconMetaverse";
import { IconPuzzle } from "../components/Elements/Icon/IconPuzzle";
import { IconWorld } from "../components/Elements/Icon/IconWorld";

const growthJs = [
  {
    heading: "App Based Puzzle Games",
    description:
      "Easy to play games for when people travel. Will be tied to rewards system",
    leftIcon: <IconPuzzle />,
  },
  {
    heading: "Augmented Reality",
    description:
      "Travel the world & capture Peaky Birds hiding out at every tourist hotspot",
    leftIcon: <IconWorld />,
  },
  {
    heading: "Metaverse",
    description:
      "Why only travel IRL? Our ecosystem will erode the borders between universes",
    leftIcon: <IconMetaverse />,
  },
  {
    heading: "New Frontier",
    description:
      "First mover advantage will lead to possibilties never before seen in the world of travel ... until now",
    leftIcon: <IconFrontier />,
  },
];

const perksJs = [
  {
    heading: "Increased Loyalty Rewards",
    description:
      "The beta version of our world first travel rewards ecosystem aims to be released soon after the Peaky Birds launch. The goal is to have points redeemable for direct accommodation, travel products, merch and to even be utilised in the gamification of the Peaky Birds!",
  },
  {
    heading: "Ongoing Airdrops",
    description:
      "Imagine a Golden passport that earns increased rewards & potentially even a Peakah Token when the time's right.",
  },
  {
    heading: "Exclusive early access to events with global partners",
    description:
      "We have on-going discussions with event organisers in both the Web3 convention & accommodation space, however long term we aim to partner with smaller music festivals & even IRL ESports tournaments!",
  },
  {
    heading: "Early access to travel merch drops",
    description:
      "We plan to create our own style of travel streetwear. We’ve already said too much here but keep an eye out in discord as the Peaky Birds will have their input on the final designs.",
  },
  {
    heading: "Priority access to all future products & collaborations",
    description:
      "Early access to travel rewards, early betas of the game & merch. With collabs we aim to release a physical product with each i.e. with international sporting teams, we could release co-branded pieces that only holders have access to.",
  },
];

const travelJs = [
  {
    heading: "Passport",
    description:
      "Mint your membership to the Peakah ecosystem. Earn points to be redeemed in the marketplace",
    extra: "",
    imageUrl: "/images/assets/peakah-passport.svg",
  },
  {
    heading: "Peaky Birds",
    description:
      "By purchasing & holding a collectible, members will earn increased rewards + much more",
    extra: "",
    imageUrl: "/images/assets/peakah-logo.svg",
  },
  {
    heading: "Partnerships & Events",
    description:
      "Leveraging the collective community for greater perks and rewards",
    extra: "",
    imageUrl: "/images/assets/peakah-events.svg",
  },
  {
    heading: "",
    description: "Future Web3 Token launch will connect the entirety of the ",
    extra: "Peakah Ecosystem",
    imageUrl: "/images/assets/peakah-coin.svg",
  },
];

export { growthJs, perksJs, travelJs };
