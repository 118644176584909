import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import SearchImagesService from "../../services/SearchApi/SearchImagesService";

export const ImageGallery = ({ partner = "", partnerId = "" }) => {
  const [imagesArray, setImagesArray] = useState();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [message, setMessage] = useState("Loading");

  const getImages = async () => {
    const imagesResponse = await SearchImagesService.getImages(partner, partnerId);
    try {
      if (imagesResponse.images.length > 1 && imagesResponse.success) {
        setImagesArray(imagesResponse.images);
        setImagesLoaded(true);
      } else {
        throw new Error("Sorry, no more images to display");
      }
    } catch (error) {
      setMessage("Sorry, no more images to display");
      setImagesLoaded(false);
      console.error(error);
    }
  };

  const container = useRef();

  useEffect(() => {
    if (partner !== "" && partnerId !== "") {
      container.current.scrollTo(0, 0);
      getImages();
    }
    return () => {
      setImagesArray([]);
      setImagesLoaded(false);
      setMessage("Loading");
    };
  }, [partner, partnerId]);

  return (
    <div ref={container}>
      {!imagesLoaded && (
        <div className="h-full w-full flex items-center justify-center">
          {message}
        </div>
      )}
      <div className="grid grid-cols-2 gap-5 pb-56">
        {imagesLoaded &&
          imagesArray.map((image, index) => (
            <div
              key={index}
              className={`${
                index % 3 ? "col-span-1" : "col-span-2"
              } bg-veryLightGray aspect-h-9 aspect-w-16 rounded-4xl w-full bg-cover bg-center`}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          ))}
      </div>
    </div>
  );
};
