import AvailabilityRequest from "../../models/api/AvailabilityRequest";
import AvailabilityResponse from "../../models/api/AvailabilityResponse";
import ApiService from "../ApiService";
const API_URL = process.env.REACT_APP_API_HOST as string;

export default class SearchAvailabilityService {
  static getAvailability = async (requestParams: any) => {
    const request = new AvailabilityRequest(requestParams);
    const response = await ApiService.getRequest(
      API_URL,
      "availability",
      request
    );
    return new AvailabilityResponse(response);
  };
}
