import { ButtonPrimary } from '../Button/ButtonPrimary';
import { Heading3 } from '../Heading/Heading3';

interface ICardStackedContent {
  heading: string;
  description: string;
  imageUrl: string | null;
  imageAlt: string | null;
  onClick: () => void;
  destination: string;
}

const ctaLabel = (destination: string) => {
  if (destination?.length <= 9) return `Discover stays in ${destination}`;
  if (destination?.length >= 9 && destination.length < 19) return `Stays in ${destination}`;
  return destination;
};

const CardStackedContent: React.FC<ICardStackedContent> = ({
  heading,
  description,
  imageUrl,
  imageAlt,
  onClick,
  destination
}) => {
  return (
    <div>
      <Heading3 classes="leading-normal my-5 sm:my-10 text-sm text-navyBlue lg:text-2xl cursor-pointer">
        <span onClick={onClick}>{heading}</span>
      </Heading3>
      <div className="text-sm text-navyBlue leading-neat mb-5 sm:mb-10 list-disc list-outside">
        <div className='cms' dangerouslySetInnerHTML={{ __html: description }} />
        <div className="lg:hidden pb-2">
          <ButtonPrimary onClick={onClick} label={ctaLabel(destination)} />
        </div>
      </div>
      {imageUrl && (
        <img
          onClick={onClick}
          src={imageUrl}
          alt={imageAlt ?? heading}
          className="rounded shadow mb-5 cursor-pointer"
        />
      )}
    </div>
  );
};

export default CardStackedContent;
