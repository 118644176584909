import { BadgeRating } from "../Elements/Badge/BadgeRating";
import { ButtonPillSmall } from "../Elements/Button/ButtonPillSmall";
import { IconClose } from "../Elements/Icon/IconClose";
import { IconStar } from "../Elements/Icon/IconStar";
import config from "../../config/general.json";
export const FiltersFeedback = ({
  amenities = [],
  amenitiesChange = () => "",
  listingTypes = [],
  listingTypeChange = (listingType) => listingType,
  rating = 0,
  ratingChange = () => 0,
  stars = [],
  starChange = () => 0,
}) => {
  const activeAmenitiesKeys = Object.keys(amenities).filter(
    (key) => amenities[key] === true
  );

  const getAmenityLabel = (id) => {
    return config.amenitiesFilterOptions.find((option) => option.id === id)
      .label;
  };

  const AmenitiesPills = ({ keys = [""] }) => {
    return keys.map((amenityKey, index) => (
      <div className="mr-2 mb-3 flex-shrink-0" key={index}>
        <ButtonPillSmall
          label={getAmenityLabel(amenityKey)}
          iconAfterLabel={
            <IconClose
              strokeWidth={4}
              height={12}
              width={12}
              classes="text-blue-500"
            />
          }
          onClick={() => amenitiesChange(amenityKey)}
        />
      </div>
    ));
  };

  const handleListingTypeChange = (listingType) => {
    listingType.enabled = false; 
    return listingTypeChange(listingType);
  }

  const ListingTypesPills = ({ listingTypes = [] }) => {
    return listingTypes
      .filter((listingType) => listingType.enabled)
      .map((listingType) => (
        <div className="mr-2 mb-3 flex-shrink-0" key={listingType.id}>
          <ButtonPillSmall
            label={listingType.label}
            iconAfterLabel={
              <IconClose
                strokeWidth={4}
                height={12}
                width={12}
                classes="text-blue-500"
              />
            }
            onClick={() => handleListingTypeChange(listingType)}
          />
        </div>
      ));
  };

  const StarsPills = ({ stars = [] }) => {
    return stars.map((star, index) => (
      <div className="mr-2 mb-3 flex-shrink-0" key={index}>
        <ButtonPillSmall
          label={`${star} star${star > 1 ? "s" : ""}`}
          icon={
            <IconStar
              strokeWidth={1}
              height={12}
              width={12}
              classes="text-peakahGreen"
            />
          }
          iconAfterLabel={
            <IconClose
              strokeWidth={4}
              height={12}
              width={12}
              classes="text-blue-500"
            />
          }
          onClick={() => starChange(star)}
        />
      </div>
    ));
  };

  const RatingPill = ({ rating = 0 }) => {
    rating = parseFloat(rating);
    if (rating < 1) return null;
    const getRatingLabel = (rating) =>
      config.ratingsFilterOptions.find((option) => option.value === rating)
        .label;
    return (
      <div className="mr-2 mb-3 flex-shrink-0">
        <ButtonPillSmall
          label={getRatingLabel(rating)}
          icon={<BadgeRating reviewScore={rating} suffix={"+"} small={true} />}
          iconAfterLabel={
            <IconClose
              strokeWidth={4}
              height={12}
              width={12}
              classes="text-blue-500"
            />
          }
          onClick={() => ratingChange(0)}
        />
      </div>
    );
  };

  return (
    <div className="flex w-full sm:max-w-2xl xl:max-w-4xl sm:mx-auto overflow-x-scroll sm:overflow-x-visible sm:flex-wrap px-3 sm:px-10 pt-2 sm:pt-0">
      <div className="sm:hidden"></div>
      <ListingTypesPills listingTypes={listingTypes} />
      <AmenitiesPills keys={activeAmenitiesKeys} />
      <StarsPills stars={stars} />
      <RatingPill rating={rating} />
    </div>
  );
};
