import ILandingPageResponse from './ILandingPageResponse';
import LandingPage from './LandingPage';

export default class LandingPageResponse {
  public data: LandingPage;

  constructor(
    public status: number,
    data: ILandingPageResponse,
    public error?: Error
  ) {
    this.data = new LandingPage(
      data.id,
      data.status,
      data.sort,
      data.date_created,
      data.date_updated,
      data.campaign_category,
      data.url,
      data.name,
      data.heading,
      data.subHeading,
      data.heroImageUrl,
      data.heroImageAlt,
      data.carouselImage,
      data.carouselImageUrl,
      data.carouselImageAlt,
      data.introduction,
      data.featuredLocations,
      data.content_blocks,
      data.meta_description,
      data.meta_title,
      data.destination,
      data.destinationSearchRadius
    );
  }
}
