import React from "react"
import DatePicker from "react-datepicker";
import { IconChevronDown } from "../Elements/Icon/IconChevronDown";
import { IconCalendar } from "../Elements/Icon/IconCalendar";
import { LabelSmall } from '../Elements/Label/LabelSmall';
export const DatePickerCheckIn = (props) => {
    return (<div>
                {props.label && <LabelSmall title={props.label} darkMode={props.darkMode}/>}
                    <div className="relative mt-2 z-30" >
                        <div className="z-20 h-10 absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                            <IconCalendar  />
                        </div>
                        <div className="z-20 h-10 absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none md:hidden lg:flex">
                            <IconChevronDown classes={`${props.darkMode ? "text-peakahGreen" : "text-peakahBlue"}`} />
                        </div>
                        <DatePicker
                            className={`cursor-pointer h-10 text-sm pl-12 placeholder-opacity-50 w-full ${props.darkMode ? "bg-darkModeGray text-darkModeLightGray placeholder-darkModeLightGray" : "bg-veryLightBlue border outline-none text-peakahBlue placeholder-peakahBlue"} rounded-4xl mb-2 ${(props.displayErrors && !props.startDate) ? ' border-navyBlue ' : ' border-white '}`}
                            placeholderText="Check-In"
                            minDate={props.minDate}
                            selectsStart
                            startDate={props.startDate}
                            endDate={props.endDate}
                            selected={props.startDate}
                            onChange={props.onChange}
                            dateFormat="d MMM"
                            popperModifiers={{
                                preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport"
                                }
                            }}
                            showPopperArrow={false}
                        />
					</div>
                    {(props.displayErrors && !props.startDate &&
						<div className={`text-xs italic ${props.darkMode ? "text-darkModeLightGray" : "text-navyBlue"}`}>Your check-in date?</div>
                    )}
                </div>
            )
        }
