import React, { useEffect, useState } from "react";
export const ImageContainer = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [finalImage, setFinalImage] = useState(null);
  useEffect(() => {
    const image = new Image();
    image.src = props.image;
    image.onload = () => setLoaded(true);
    setFinalImage(image.src);
  }, [props.image]);
  return (
    <img
      loading="lazy"
      src={finalImage}
      alt={props.image}
      className={`transition-opacity duration-300 ${
        loaded ? "opacity-100" : "opacity-0"
      } rounded-4xl xl:rounded-none ` }
    />
  );
};
