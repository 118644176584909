import React from "react";
import { ProviderLogo } from "./ProviderLogo";
import { DealLink } from "./DealLink";
import { DealDetail } from "./DealDetail";
import { formatPrice } from "../../utils/formatPrice";
import { ButtonBasicSmall } from "../Elements/Button/ButtonBasicSmall";
import PartnerRate from "../../models/availability/PartnerRate";
export const CheapestRateLarge = ({
  rate = new PartnerRate(),
  toggleDisplayMorePrices = () => true,
  morePrices = false,
  morePricesOpened = false,
  nights = 1
}) => {
  return (
    <div className="hidden xl:flex xl:flex-col lg:items-center space-y-2.5 h-full w-full z-20">
      <DealDetail
        price={formatPrice(rate.priceTotalStay)}
        cheapest={true}
        priceNight={formatPrice(rate.pricePerNight)}
        nights={nights}
      />
      <ProviderLogo partner={rate.partner} bounding={true} />
      <div className="flex-shrink-0 flex w-full">
        <DealLink
          provider={rate.partner}
          rate={rate.priceTotalStay}
          cheapest={true}
          url={rate.url}
        />
      </div>
      {morePrices && (
        <div className="pt-3">
          <ButtonBasicSmall
            label="More Prices"
            displayDownArrow={true}
            open={morePricesOpened}
            toggle={() => toggleDisplayMorePrices()}
          />
        </div>
      )}
    </div>
  );
};
