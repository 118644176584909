import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { IconChevronRight } from "../Elements/Icon/IconChevronRight";
import { IconMap } from "../Elements/Icon/IconMap";

export const CarouselSlide = ({
  alt = "",
  firstToDisplay = false,
  image = "",
  name = "",
  url = "",
  marketingPlaceholder = "",
  wide = false,
  loaded = (width) => width,
  clicked = () => true,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) loaded(ref.current.offsetWidth);
  }, []);

  const state_names = {
    // Australia
    Victoria: "VIC",
    "New South Wales": "NSW",
    "Western Australia": "WA",
    Queensland: "QLD",
    "Northern Territory": "NT",
    "South Australia": "SA",
    "Australian Capital Territory": "ACT",
    Tasmania: "TAS",
    // America
    Hawaii: "HI",
    Texas: "TX",
    Florida: "FL",
    California: "CA",
    Alaska: "AK",
    Colorado: "CA",
    "New York": "NY",
    Nevada: "NV",
    Arizona: "AZ",
  };

  return (
    <Link to={url} ref={ref}>
      <div
        className={`${
          wide ? "h-64 lg:h-64 w-88 lg:w-88" : "ml-5 h-64 lg:h-80 w-88 lg:w-64"
        } md:ml-0 mr-5 bg-gray-400 rounded-4xl py-6 px-4 ${
          marketingPlaceholder
            ? "bg-left flex flex-col justify-center items-center"
            : "flex bg-cover items-end"
        }`}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0)), url(${image}`,
        }}
        role="img"
        aria-label={alt}
        onClick={() => clicked(true)}
      >
        {!marketingPlaceholder && (
          <div className="flex w-full justify-between">
            <div className="flex place-items-center space-x-2 p-3 bg-transparentBlack rounded-4xl ">
              <IconMap />
              <span className="text-white text-lg font-medium flex space-x-3 items-center">
                {wide ? name : state_names[name]}
              </span>
              <div className="text-white flex justify-end">
                <IconChevronRight
                  classes="text-peakahGreen"
                  height={22}
                  width={22}
                  strokeWidth={2}
                />
              </div>
            </div>
          </div>
        )}
        {marketingPlaceholder && (
          <div className="flex flex-col p-4 gap-6">
            <span className="text-3xl text-center text-wrap text-navyBlue font-medium">
              Free travel rewards for everyone
            </span>
            <div className="flex flex-col mt-">
              <div className="p-3 text-center bg-peakahGreen rounded-4xl">
                <span className="text-lg text-white font-medium">
                  Enter Peaky Island
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};
