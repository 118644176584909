export const ModalContents = ({
  children,
  enabled = false,
  onClickScrim = () => true,
}) => {
  return (
    <div
      className={`${enabled ? "translate-y-16" : "-translate-y-full"}
      absolute mx-auto top-0 transform z-80 h-full w-full`}
    >
      <div
        onClick={onClickScrim}
        className={`${
          enabled ? "bg-opacity-60 xl:mt-10 p-5 xl:pt-5" : "bg-opacity-0"
        }
       mx-auto transform transition-opacity duration-500 delay-500 ease-in-out flex justify-center h-full w-full bg-blue-100 overflow-y-scroll`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`${
            enabled ? "opacity-100" : "opacity-0"
          } transform transition-opacity duration-1000 ease-in-out delay-500 max-w-6xl w-full h-full`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
