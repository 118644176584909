import React from "react";
import { DealLink } from "./DealLink";
import { DealDetail } from "./DealDetail";
import { formatPrice } from "../../utils/formatPrice";
import PartnerRate from "../../models/availability/PartnerRate";
export const CheapestRateCompact = ({
  rate = new PartnerRate(),
  buttonHover = true,
  nights = 1
}) => {
  return (
    
      <div className="flex items-start justify-between space-y-1">
        <DealDetail
          price={formatPrice(Math.round(rate.priceTotalStay))}
          cheapest={true}
          priceNight={formatPrice(Math.round(rate.pricePerNight))}
          nights={nights}
          compact={true}
        />
        <DealLink
          provider={rate.partner}
          rate={rate.priceTotalStay}
          cheapest={true}
          url={rate.url}
          buttonHover={buttonHover}
          label="View"
        />
      </div>
    
  );
};
