import { IconChevronRight } from "../Icon/IconChevronRight";
import { IconMap } from "../Icon/IconMap";

export const CardFeaturedLocation = ({
  location = {},
  clicked = () => true,
}) => (
  <div
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7)), url(${location.image}`,
    }}
    className="bg-cover bg-center bg-lightGray flex items-end justify-between rounded-4xl h-full md:p-4 lg:p-6 shadow-light cursor-pointer h-56"
    onClick={() => clicked()}
  >
    <div className="flex space-x-2 space-y-40 place-items-end">
      <div className="flex place-items-center space-x-2 p-3 bg-transparentBlack rounded-4xl ">
        <IconMap />
        <span className="text-md xl:text-md text-white">{location.name}</span>
        <div className="text-white flex justify-end">
          <IconChevronRight classes="text-peakahGreen" />
        </div>
      </div>
    </div>
  </div>
);
