import { Heading2 } from "../Elements/Heading/Heading2";
import { NewsletterSignup } from "./NewsletterSignup";

export const NewsletterSignupStandalone = ({
  heading = "",
  image = "",
  stacked = false
}) => (
  <div
    style={{
      backgroundImage: `url('${image}')`,
    }}
    className={`${stacked ? "" : "md:justify-between md:flex-row lg:p-10"} p-6  rounded-4xl bg-blue bg-cover bg-center flex flex-col  space-y-5 shadow-light`}
  >
    <div className={`${stacked ? "w-full flex items-center" : "md:w-full lg:w-1/2 md:pr-5 md:flex md:items-center"}`}>
      <Heading2 classes="leading-relaxed text-xl md:text-2xl font-medium text-white">
        {heading}
      </Heading2>
    </div>
    <div className={`${stacked ? "flex flex-col" : "md:w-full lg:w-1/2 md:flex md:flex-col"}`}>
      <NewsletterSignup stacked={stacked} />
    </div>
  </div>
);
