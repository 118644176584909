export const ToggleButton = ({
  enabled = false,
  onClick = () => true,
  icon,
  label = "",
}) => {
  return (
    <button
      className={`${
        enabled ? "shadow-inner" : ""
      } px-2 py-3 w-full flex space-x-2 items-center justify-center text-blue-500`}
      onClick={onClick}
    >
      <div>{icon}</div>
      <div className="text-xs font-semibold flex-shrink-0 hidden sm:block">
        {label}
      </div>
    </button>
  );
};
