import { Heading1 } from "../components/Elements/Heading/Heading1";
import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NewsletterSignupStandalone } from "../components/NewsletterSignup/NewsletterSignupStandalone";
import { HeaderBasic } from "../components/Header/HeaderBasic";
import CMSService from "../services/CMSApi/CMSService";
import Page from "../models/api/cms/Page";
import { useEffect, useState } from "react";
import Head from "../components/Head/Head";

interface IContentPage {
  children?: React.ReactChild;
}

const ContentPage: React.FC<IContentPage> = ({ children }) => {
  const location = useLocation();
  const currentPathWithoutSlashPrefix = location.pathname.substring(1);
  const [content, setContent] = useState<Page>();

  const getContent = async () => {
    const contentResponse = await CMSService.getPage(
      currentPathWithoutSlashPrefix
    );
    setContent(contentResponse.data);
  };

  useEffect(() => {
    getContent();

    return () => {
      setContent(undefined);
    };
  }, [location.pathname]);

  return (
    <div className="h-full flex flex-col justify-between space-y-8 lg:space-y-24">
      <Head
        metaData={{
          title: content?.meta_title as string,
          description: content?.meta_description as string,
        }}
      />
      <div className="max-w-xxl w-full mx-auto px-5 sm:px-10">
        <HeaderBasic darkMode={false} />
      </div>
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-20 lg:space-y-0 px-5 sm:px-10 max-w-xxl w-full mx-auto">
        <div className="hidden lg:block max-w-screen-xs mx-auto pt-10 lg:pt-0 lg:w-88">
          <NewsletterSignupStandalone
            image="https://cdn.peakah.com/images/newsletter-bg-mobile.jpg"
            heading="Become a member to receive exclusive offers and the best travel tips!"
            stacked={true}
          />
        </div>
        <div className="w-full text-blue-500">
          <Heading1 classes="text-center lg:text-left pb-8 lg:pb-14 text-2xl lg:text-5xl font-medium">
            {content?.heading && content?.heading}
          </Heading1>
          {content?.content && (
            <div
              className="flex flex-col space-y-5"
              dangerouslySetInnerHTML={{ __html: content?.content }}
            ></div>
          )}
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ContentPage;
