import React from 'react';
import { StyledContactForm,
	StyledInput,
	StyledLoading,
	StyledLoaderCircle,
	StyledLoaderCircleInner,
	StyledError,
	StyledSubmit } from './Contact.styled';

const API_URL = 'https://6o29xndakd.execute-api.ap-southeast-2.amazonaws.com/development';
const AVAILABILITY_ENDPOINT = '/contact';

export class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			message: '',
			sending: false,
			error: '',
			sent: false
		};
	}

	handleSubmit = () => {
		if (this.validateForm()) {
			this.setState({sending:true, error: ''})
			let params = {
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				email: this.state.email,
				phone: this.state.phone,
				message: this.state.message,
			};
			this.submitForm(params)
				.then(response=>this.setState({sent:true}))
				.catch((err)=>{
					console.warn(err)
					this.setState({sending:false, error:'Something went wrong, please try again'})
				});
		}
	};

	submitForm = async (params) => {
		let url = new URL(API_URL+AVAILABILITY_ENDPOINT);
		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
		const response = await fetch(url, {
			method: 'POST'
		});
		const json = await response.json();
		return json;
	};

	validateForm = () => {
		let {firstname, message} = this.state;
		let error = [];
		if (!firstname) {
			error.push("Please enter your first name.");
		}
		if (!message) {
			error.push("Please enter a message.");
		}
		if (error.length > 0) {
			this.setState({error:error.join(' ')});
			return false;
		}
		return true;
	}

	render() {
		let {firstname, lastname, email, phone, message, sending, error, sent} = this.state;
		return (
			<div>
				<p>Send us a message using the form below and we'll be sure to get in touch with you soon.</p>
				{!sending && !sent &&
					<StyledContactForm>
						{error && <StyledError>{error}</StyledError>}
						<StyledInput type="text" 	value={firstname} 	onChange={(event)=>{this.setState({firstname:event.target.value})}} placeholder="First Name"/>
						<StyledInput type="text" 	value={lastname} 	onChange={(event)=>{this.setState({lastname:event.target.value})}} 	placeholder="Last Name"/>
						<StyledInput type="text" 	value={email} 		onChange={(event)=>{this.setState({email:event.target.value})}} 	placeholder="Email address"/>
						<StyledInput type="text" 	value={phone} 		onChange={(event)=>{this.setState({phone:event.target.value})}} 	placeholder="Phone Number"/>
						<StyledInput as="textarea" 	value={message} 	onChange={(event)=>{this.setState({message:event.target.value})}} 	placeholder="Your message" fullwidth/>
						<StyledSubmit type="submit" onClick={this.handleSubmit} value="Send"/>
					</StyledContactForm>
				}
				{sending && !sent &&
					<StyledContactForm>
						<StyledLoading>
							<h2>Sending your message</h2>
							<StyledLoaderCircle><StyledLoaderCircleInner/></StyledLoaderCircle>
						</StyledLoading>
					</StyledContactForm>
				}
				{sent &&
					<StyledContactForm>
						<h3>Thanks for your message!</h3>
					</StyledContactForm>
				}
			</div>
		)
	}
}