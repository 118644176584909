import { SelectionOption } from "./SelectionOption";
import { Label } from "../../Elements/Label/Label";
import { useContext, useState } from "react";
import { ButtonBasicSmall } from "../../Elements/Button/ButtonBasicSmall";
import PropertyAttribute from "../../../models/availability/PropertyAttribute";
import { FilterContext } from "../../../context/FilterContext/FilterContext";
export const AmenitiesFilter = ({
  amenitiesFilters = [new PropertyAttribute()],
  onChange = () => ({ enabled: false, id: "" }),
}) => {
  const [displayFullList, setDisplayFullList] = useState(false);
  const optionsLimit = displayFullList ? 999 : 6;
  const [filterContext, setFilterContext] = useContext(FilterContext);
  return (
    <div className="w-full text-navyBlue">
      <Label title="Amenities" />
      <ul className="space-y-2 flex flex-col">
        {amenitiesFilters.length > 0 && amenitiesFilters
          .slice(0, optionsLimit)
          .map((amenity, index) => {
            return (
              <SelectionOption
                key={index}
                id={index}
                label={amenity.label}
                value={amenity.name}
                onChange={({ checked: enabled, value: id }) => {
                  onChange({ enabled, id });
                }}
                checked={filterContext.amenities[amenity.name]}
              />
            );
          })}
      </ul>
      <div className="pt-4">
        <ButtonBasicSmall
          smallText={false}
          displayDownArrow={true}
          fullWidth={false}
          toggle={() => setDisplayFullList(!displayFullList)}
          open={displayFullList}
          label={displayFullList ? "Show Less" : "Show More"}
        />
      </div>
    </div>
  );
};
