class ImagesResponse {
  images: any[];
  success: boolean;
  constructor({ status = 200, data = [] }) {
    this.images = data;
    this.success = status === 200;
  }
}

export default ImagesResponse;
