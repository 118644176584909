import React, {useState,useEffect,useContext} from 'react'
import { SearchMessage } from '../Search/SearchMessage'
import { FilterContext } from '../../context/FilterContext/FilterContext';
export const SearchMessages = (props) => {

    const [message,setMessage] = useState('Enter a destination to start your search')
    const { error, loading, resultCount, searchAttempted, destination, activeResults, extendingSearch, maxRadiusExceeded  } = props
    const [filterContext, setFilterContext] = useContext(FilterContext);
    useEffect(() => {
        if (maxRadiusExceeded && !error) setMessage(null);
        if (resultCount > 0 && !error) setMessage(null);
        if (error) setMessage('Sorry but an unexpected error occurred. Please adjust your search and try again.')
        if (destination && resultCount === 0 && !loading && searchAttempted) setMessage("Sorry, we can't find any availability in this location.")
        if (loading) setMessage(`Checking availability from our ${resultCount > 0 ? resultCount : ''} accommodation options.`)
        if (!destination) setMessage('Enter a destination to start your search');
        if (!activeResults && !loading && resultCount > 0 && !extendingSearch) setMessage(`No available accommodation matching your filters. Please adjust your filters to see results.`)
        return () => setMessage('Please enter your search criteria to start searching');
    },[error,loading,resultCount,searchAttempted,destination,activeResults,filterContext])

    return (<SearchMessage text={message} />)
    
}