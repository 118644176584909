import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderInner } from '../components/HeaderInner';
import { Carousel } from '../components/Carousel';
import { SearchSidebar } from '../components/Search/SearchSidebar';
import { StickySearch } from '../components/Search/StickySearch';
import { LocationInner } from '../components/LocationPage/LocationInner';
import { MobileOverlay } from '../components/Modules/MobileOverlay';
import { SearchContext } from '../context/SearchContext/SearchContext';
import { twoWeeks, twoWeeksTomorrow } from '../utils/defaultDates';
import { Footer } from '../components/Footer';
import CMSService from '../services/CMSApi/CMSService';
import LandingPage from '../models/api/cms/LandingPage';
import Head from '../components/Head/Head';
import LandingPagesCarouselSlide from '../models/api/cms/LandingPagesCarouselSlide';
import { UrlContext } from '../context/UrlContext/UrlContext';
import { SiteParams } from '../config/constants/SiteParams';

export const LocationPage: React.FC = () => {
  const [toSearchResults, setToSearchResults] = useState(false);
  const history = useHistory();
  const route = useLocation();

  const [loaded, setLoaded] = useState(false);
  const [locationIndex, setLocationIndex] = useState(0);
  const [mobileSearchDisplay, setMobileSearchDisplay] = useState(false);
  const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 });
  const [location, setLocation] = useState<LandingPage>();
  const [carouselContent, setCarouselContent] = useState<
    LandingPagesCarouselSlide[]
  >();

  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");
  const URINoSlash = route.pathname.slice(1);
  const URI = URINoSlash.split('/').slice(-1)[0];
  const category = URINoSlash.split('/').slice(-2)[0];

  const getContent = async () => {
    const contentResponse = await CMSService.getLandingPage(URI, category);
    setLocation(contentResponse.data);
    setLoaded(true);
  };

  const getCarouselContent = async () => {
    const contentResponse = await CMSService.getLandingPagesCarousel(category);
    setCarouselContent(contentResponse.data);
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    setTimeout(() => {
      setLocationIndex(0);
    }, 200);
    getContent();
    return () => {
      setLocation(undefined);
    };
  }, [route.pathname]);

  useEffect( () => {
    if (urlContext !== "/" ) {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url)
		}
	}, [urlContext])

  useEffect(() => {
    getCarouselContent();
  }, [location])
  
  useEffect(() => {
    if (loaded && locationIndex === 0) saveLocationIndex(0);
  }, [location, locationIndex, loaded]);

  useEffect(() => {
    const search = () => history.push({ pathname: `${url}/search` });
    if (toSearchResults) search();
    return () => setToSearchResults(false);
  }, [toSearchResults, history]);

  const searchLocation = () => {
    if (!location) return;
    const { latitude, longitude, radius, name } = location.featuredLocations[
      locationIndex
    ].Locations_id;
    setSearchContext({
      ...searchContext,
      coordinates: { lat: latitude, lng: longitude },
      radius: radius,
      destination: name,
      startDate: twoWeeks,
      endDate: twoWeeksTomorrow,
    });
    setToSearchResults(true);
  };

  const saveLocationIndex = (index: number) => {
    if (!location) return;
    const latitude = location.featuredLocations[index].Locations_id.latitude;
    const longitude = location.featuredLocations[index].Locations_id.longitude;
    const radius = location.featuredLocations[index].Locations_id.radius
      ? location.featuredLocations[index].Locations_id.radius
      : searchContext.radius;
    setLocationIndex(index);
    setCoordinates({ latitude: latitude, longitude: longitude });
    setSearchContext({
      ...searchContext,
      coordinates: { lat: latitude, lng: longitude },
      radius: radius,
      destination: location.featuredLocations[index].Locations_id.name,
      destinationShortName: location.featuredLocations[index].Locations_id.name,
    });
    console.log(
      `Set location for ${location.featuredLocations[index].Locations_id.name}`
    );
  };

  const locationVisibleName = (index: number) => {
    if (!location) return;
    return location.featuredLocations[index]?.Locations_id.name;
  };

  return (
    <div>
      <Head
        metaData={{
          description: location?.meta_description,
          title: location?.meta_title,
        }}
      />
      {location && (
        <HeaderInner
          heroBg={{
            source: location?.heroImageUrl,
            alt: location?.heroImageAlt,
          }}
          heading={location?.heading}
        />
      )}
      <div className="px-5 sm:px-10 max-w-xxl mx-auto flex items-start">
        <div className="w-full lg:w-6/12">
          <LocationInner
            subHeading={location?.subHeading}
            introduction={location?.introduction}
            loaded={loaded}
            data={location?.featuredLocations}
            locationChange={saveLocationIndex}
          />
        </div>
        <div className="hidden w-full lg:w-5/12 lg:block sticky top-0 ml-auto z-40 lg:px-6 my-10 sm:my-20 sm:pt-5">
          <SearchSidebar
            coordinates={coordinates}
            name={loaded && locationVisibleName(locationIndex)}
            search={searchContext}
          />
        </div>
      </div>
      <div className="pl-5 sm:pl-10 max-w-xxl mx-auto mb-10 xl:pr-10">
        <div>
          {carouselContent && (
            <Carousel
              heading="Get to know your state with a Staycation"
              images={carouselContent}
              exclude={URI}
            />
          )}
        </div>
      </div>
      {mobileSearchDisplay && (
        <MobileOverlay
          darkMode={false}
          label="Close"
          clicked={() => setMobileSearchDisplay(false)}
        >
          <SearchSidebar
            coordinates={coordinates}
            name={loaded && locationVisibleName(locationIndex)}
            search={searchContext}
          />
        </MobileOverlay>
      )}
      <StickySearch
        display={true}
        name={locationVisibleName(locationIndex)}
        click={searchLocation}
      />
      <Footer />
    </div>
  );
};
