import { Icon } from "./Icon";
export const IconCar = (props) => (
  <Icon {...props}>
    <path
      d="M25.3,4.1l-0.4,0c-0.2,0-0.4,0.1-0.6,0.1c-0.3-0.7-0.7-1.5-1.1-2.2c-0.6-0.9-1.6-1.4-2.6-1.5
		c-1.1-0.2-2.2-0.2-3.2-0.2c-1.1,0-2.2,0-3.2,0.2c-1.1,0.1-2,0.7-2.7,1.6c-0.4,0.7-0.8,1.4-1.1,2.1c-0.2-0.1-0.4-0.1-0.7-0.1H9.3
		c-0.9,0-1.6,0.7-1.6,1.6v0.7c0,0.7,0.4,1.2,1,1.5C8.4,8.5,8.3,9.1,8.3,9.7v4.4c0,0.9,0.8,1.7,1.7,1.7h1.2c0.9,0,1.6-0.7,1.7-1.6
		h8.9c0,0.9,0.8,1.6,1.7,1.6h1.2c0.9,0,1.7-0.8,1.7-1.7V9.7c0-0.6-0.1-1.3-0.3-1.9c0.6-0.3,0.9-0.8,1-1.5V5.7
		C26.9,4.8,26.2,4.1,25.3,4.1z M25.4,6.3c0,0.1-0.1,0.2-0.2,0.2l-0.4,0c-0.3,0-0.6,0.2-0.7,0.4C24,7.2,24,7.5,24.2,7.7l0.1,0.2
		c0.4,0.5,0.5,1.2,0.5,1.8v4.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-0.7c0-0.4-0.3-0.8-0.8-0.8H12.1
		c-0.4,0-0.8,0.3-0.8,0.8v0.7c0,0.1-0.1,0.2-0.2,0.2H10c-0.1,0-0.2-0.1-0.2-0.2l0-4.4c0-0.6,0.2-1.3,0.5-1.8c0,0,0.1-0.1,0.1-0.1
		c0.2-0.2,0.2-0.5,0.1-0.8c-0.1-0.3-0.4-0.4-0.7-0.4H9.4c-0.1,0-0.2-0.1-0.2-0.2V5.7c0-0.1,0.1-0.2,0.1-0.2l0.3,0
		c0.2,0,0.4,0.1,0.5,0.2C10.4,6,10.6,6.1,10.9,6c0.2-0.1,0.5-0.2,0.5-0.5c0.4-0.9,0.8-1.9,1.2-2.7c0.4-0.5,1-0.9,1.6-0.9
		c1-0.2,2-0.2,3-0.2c1,0,2,0,3,0.2C21,2,21.6,2.4,21.9,2.8c0.5,0.9,0.9,1.8,1.3,2.7c0.1,0.2,0.3,0.4,0.5,0.5c0.3,0.1,0.5,0,0.7-0.2
		c0.1-0.1,0.3-0.2,0.5-0.2h0.3c0.1,0,0.2,0.1,0.2,0.1V6.3z"
    />
    <path
      d="M14.6,9.5c0-0.3-0.2-0.6-0.5-0.7l-1.7-0.4c-0.4-0.1-0.8,0.1-0.9,0.5c0,0.1,0,0.1,0,0.2v0.4
		c0,0.4,0.3,0.7,0.7,0.7h1.7C14.2,10.2,14.6,9.9,14.6,9.5L14.6,9.5L14.6,9.5z"
    />
    <path
      d="M23.2,9c0-0.4-0.3-0.7-0.7-0.7c-0.1,0-0.1,0-0.2,0l-1.7,0.4c-0.4,0.1-0.6,0.5-0.5,0.9
		c0.1,0.3,0.4,0.5,0.7,0.5h1.7c0.4,0,0.7-0.3,0.7-0.7l0,0l0,0L23.2,9z"
    />
  </Icon>
);
