
import Hotel from '../models/availability/Hotel';

const lowestRate = (rates) => Math.min(...rates.map(item => item.pricePerNight));

const highestRate = (rates) => Math.max(...rates.map(item => item.pricePerNight));

export const maxRates = (hotels=[]) => {
	return hotels.map(hotel => {
		return Math.max(...hotel.rates.map(item => item.pricePerNight))
	})
}

export const sortResults = ( listings = [], sortBy = 'distance-nearest' ) => {
	if (sortBy === 'price-desc')
		return listings.sort((x, y) =>
			lowestRate(x.rates) === lowestRate(y.rates) ? 0 : lowestRate(x.rates) < lowestRate(y.rates) ? 1 : -1);
	if (sortBy === 'price-asc')
		return listings.sort((x, y) =>
			lowestRate(x.rates) === lowestRate(y.rates) ? 0 : lowestRate(x.rates) > lowestRate(y.rates) ? 1 : -1 );
	if (sortBy === 'distance-nearest')
		return listings.sort((x, y) =>
			x.distanceMetres > y.distanceMetres ? -1 : 1 )
	if (sortBy === 'distance-furthest')
		return listings.sort((x, y) =>
			x.distanceMetres < y.distanceMetres ? 1 : -1 )
	return listings.sort((x, y) => x.distanceMetres > y.distanceMetres ? 1 : -1 )
};

const filterByPropertyAttributes = (
	hotel = new Hotel(),
	attributesToFilter = []
) => {
	if (attributesToFilter.length === 0) return true
	for (let attribute of attributesToFilter){
		if (!hotel.propertyAttributes.find(
			hotelPropertyAttributes => hotelPropertyAttributes.name === attribute
		)) return false
	}
	return true
}

const filterByPrice = (hotel = new Hotel(), maxPrice=1000) => {
	let rate = highestRate(hotel.rates);
	return rate < maxPrice;
}

const filterByType = (hotel = new Hotel(), types = []) => {
	if (types.filter(type => type.enabled).length === 0) return true
	return types.find(type => type.id === hotel.listingType.id && type.enabled)
} 

const filterByStars = (hotel = new Hotel(), stars=[1,2,3,4,5]) => {
	if(stars.length === 0) return true;
	const minStar = Math.min(...stars);
	const maxStar = Math.max(...stars);
	return hotel.stars >= minStar && hotel.stars <= maxStar;
}

const filterByRating = (hotel = new Hotel(), rating = 0.0) => hotel.reviewScore >= rating;

// Counting the number of active results
export const countActiveResults = (results) => results.reduce( ( total , result ) => total + result.enabled, 0);

export const filterHotel = (hotel={}, filter={}, attributesToFilter = []) => {
	if (!filterByPropertyAttributes(hotel, attributesToFilter)) return false;
	if (!filterByPrice(hotel, filter.price)) return false;
	if (!filterByType(hotel, filter.type)) return false;
	if (!filterByStars(hotel, filter.star)) return false;
	if (!filterByRating(hotel, filter.rating)) return false;
	else return true;

}