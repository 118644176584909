import React from "react";
import { Checkbox } from "../Checkbox";
import { RatingSmall } from "../../../Listing/RatingSmall";
import { BadgeRating } from "../../Badge/BadgeRating";
export const SelectionOption = ({
  checked = false,
  displayValues = true,
  name = "",
  stars = 0,
  value = 0,
  handleCheckboxChange = ({ checked = true, value = 0 }) => ({
    checked,
    value,
  }),
}) => {
  return (
    <li className="text-sm flex items-center cursor-pointer">
      <label className="flex w-full justify-between items-center cursor-pointer">
        <div>
          <Checkbox
            value={value}
            onChange={({ checked, value }) =>
              handleCheckboxChange({ checked, value })
            }
            checked={checked}
            type={1}
          />
        </div>
        <div className="flex items-center w-full">
          <span>{name}</span>
          {displayValues && value !== 0 && (
            <span className="ml-2">
              <BadgeRating
                reviewScore={parseFloat(value).toFixed(1)}
                suffix={"+"}
                small={true}
              />
            </span>
          )}
        </div>
        {!displayValues && (
          <div className="flex items-center justify-end">
            <RatingSmall stars={stars} />
          </div>
        )}
      </label>
    </li>
  );
};
