import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import CMSService from "../services/CMSApi/CMSService";
import { useEffect, useState } from "react";
import Head from "../components/Head/Head";
import { HeaderInner } from "../components/HeaderInner";
import ArticlePage from "../models/api/cms/ArticlePage";
import ArticleGalleryCard from "../models/api/cms/ArticleGalleryCard";
import { ArticleCards } from "../components/Article/ArticleCards";
import {
  isMobile,
  isTablet,
} from "react-device-detect";

interface IContentPage {
  children?: React.ReactChild;
}

export const ArticleContentPage: React.FC<IContentPage> = ({ children }) => {
  const route = useLocation();
  const URINoSlash = route.pathname.slice(1);
  const URI = URINoSlash.split("/").slice(-1)[0];
  const [content, setContent] = useState<ArticlePage>();
  const [galleryContent, setGalleryContent] = useState<ArticleGalleryCard[]>();

  const getContent = async () => {
    const contentResponse = await CMSService.getArticle(URI, "aus");
    setContent(contentResponse.data);
  };

  const getGalleryContent = async () => {
    const contentResponse = await CMSService.getArticleGallery("aus");
    setGalleryContent(contentResponse.data);
  };

  useEffect(() => {
    getContent();

    return () => {
      setContent(undefined);
    };
  }, [route.pathname]);

  useEffect(() => {
    getGalleryContent();
  }, []);

  const shuffle = (arr: any) => [...arr].sort(() => Math.random() - 0.5);

  return (
    <div className="h-full flex flex-col justify-between">
      <Head
        metaData={{
          title: content?.meta_title as string,
          description: content?.meta_description as string,
        }}
      />
      {content && (
        <HeaderInner
          heroBg={{
            source: content?.heroImageUrl,
            alt: content?.heroImageAlt,
          }}
          heading={content?.heading}
        />
      )}
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-20 mt-8 lg:mt-24 px-5 sm:px-10 max-w-xxl w-full mx-auto">
        <div className="w-full text-blue-500">
          {content?.content && (
            <div
              className="flex flex-col space-y-5"
              dangerouslySetInnerHTML={{ __html: content?.content }}
            ></div>
          )}
          {children}
        </div>
      </div>
      {galleryContent && (
        <div className="lg:space-x-0 lg:space-y-0 max-w-xxl w-full mx-auto mb-8 lg:mb-24">
          <div
            className={`${
              isMobile || isTablet ? "my-7" : "my-13"
            } px-5 sm:px-10 `}
          >
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
          </div>
          <ArticleCards
            heading="Related"
            images={shuffle(galleryContent)}
            cardLimit={4}
          />
        </div>
      )}

      <Footer />
    </div>
  );
};
