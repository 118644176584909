import { isMobile } from "react-device-detect";
import Callout from "../Elements/Callout/Callout";
import { growthJs } from "../../config/nft";
interface IGrowth {
  imageUrl: string;
  imageAlt: string;
}

const Growth: React.FC<IGrowth> = ({ imageUrl, imageAlt }) => {
  return (
    <div className="px-5 sm:px-10 xl:px-0 max-w-xxl mx-auto">
      <div className="flex flex-col sm:px-20 md:justify-between lg:flex-row items-center">
        <div className="flex flex-col lg:w-1/2">
          <p className="text-5xl">
            <span className="text-black font-medium">{"Growth "}</span>
            <span className="text-peakahBlue font-medium">{"& "}</span>
            <span className="text-peakahGreen font-medium">
              {"Gamification"}
            </span>
          </p>
          <img
            src={imageUrl}
            alt={imageAlt}
            height={isMobile ? "300" : "1000"}
          />
        </div>
        <div className="flex flex-col lg:space-y-4 lg:w-1/2">
          {growthJs.map((callout) => (
            <Callout
              heading={callout.heading}
              description={callout.description}
              leftIcon={callout.leftIcon}
              colour="bg-peakahBlue"
              key={callout.heading}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Growth;
