class Coordinates {
  public latitude: number;

  public longitude: number;

  constructor(coordinates: number[] = [0.0, 0.0]) {
    this.latitude = coordinates[1];
    this.longitude = coordinates[0];
  }
}

export default Coordinates;
