import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";
import { useContext } from "react";
import { UrlContext } from "../../context/UrlContext/UrlContext";

export const TopBarSlideIn = ({
  enabled = false,
  lastPosition,
  middlePosition,
  middlePositionIcon,
}) => {
  const [urlContext, setUrlContext] = useContext(UrlContext);
  return (
    <div
      className={`${
        enabled
          ? "translate-y-0 shadow-straight"
          : "-translate-y-full shadow-none"
      }
    w-full z-90 transform transition-transform duration-300 ease-in-out absolute bg-white flex justify-between items-center px-4 xl:px-10 xl:py-5 py-2 space-x-4`}
    >
      <div className="flex justify-center xl:justify-start">
        <Link to={`${urlContext}`}>
          <Logo darkMode={false} />
        </Link>
      </div>
      <div className="hidden sm:flex items-center space-x-3 text-blue-600 text-xl font-medium">
        {middlePositionIcon}
        <div>{middlePosition}</div>
      </div>
      <div>{lastPosition}</div>
    </div>
  );
};
