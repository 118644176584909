import React, { useEffect, useState, useContext } from "react";
import { HeaderInner } from "../components/HeaderInner";
import { Footer } from "../components/Footer";
import CMSService from "../services/CMSApi/CMSService";
import Head from "../components/Head/Head";
import { ArticleCards } from "../components/Article/ArticleCards";
import ArticleGalleryCard from "../models/api/cms/ArticleGalleryCard";

export const ArticleGallery: React.FC = () => {
  const [galleryContent, setGalleryContent] = useState<ArticleGalleryCard[]>();

  const getGalleryContent = async () => {
    const contentResponse = await CMSService.getArticleGallery("aus");
    setGalleryContent(
      contentResponse.data?.sort(
        (a, b) =>
          new Date(b.date_created).getTime() -
          new Date(a.date_created).getTime()
      )
    );
  };

  useEffect(() => {
    getGalleryContent();
  }, []);

  return (
    <div>
      <Head
        metaData={{
          title: "The Latest News | Articles | Peakah",
          description: "The latest news and articles from Holiday Homes to Hotels",
        }}
      />
      <HeaderInner
        heroBg={{
          source:
            "https://cdn.peakah.com/images/62f3ae72-808c-4cc3-a349-55d188de4bd7.jpg",
          alt: "The latest news and articles from Holiday Homes to Hotels",
        }}
        heading="Articles"
      />
      <div className="pt-5 md:pt-24 pb-24 max-w-xxl mx-auto">
        {galleryContent && (
          <ArticleCards
            heading="The Latest News"
            images={galleryContent}
            showMore={true}
            cardLimit={6}
          />
        )}
      </div>

      <Footer />
    </div>
  );
};
