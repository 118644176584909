import React, { useRef, createRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";
import { MainNavigation } from "../Navigation/MainNavigation";
import Links from "../../config/links.json";
import { SearchBar } from "../SearchBar";
import { SearchBarMobile } from "../SearchBar/SearchBarMobile";
import { UrlContext } from "../../context/UrlContext/UrlContext";
import { ButtonToggle } from "../Elements/Button/ButtonToggle";
import { useDarkMode } from "../../context/DarkModeContext/DarkModeContext";
import HeaderBanner from "./HeaderBanner";

export const Header = ({
  classes = "",
  collapseSearchFieldsInitially = false,
  freeCancellationFilterEnabled = false,
  displayFreeCancellation = false,
  searchWithoutButton = true,
  heroBg = [],
  header = [],
  displayPartnerLogos = true,
  onSearch,
  toggleFreeCancellation,
}) => {
  const searchBarRef = useRef();
  const ref = createRef();
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const { theme, toggleDarkMode } = useDarkMode();

  return (
    <div className="flex flex-col">
      <div className="flow-root py-6">
        <div className="float-left">
          <Link to={`${urlContext}`}>
            <Logo darkMode={theme} small={true} />
          </Link>
        </div>
        {/* Dark Mode was removed since other pages have not integrated this feature */}
        {/* <div className="float-right">
          <ButtonToggle checked={theme} onChange={toggleDarkMode} />
        </div> */}
      </div>
      <div className={`items-center`}>
        <div
          className={`flex flex-col shadow-xl bg-cover h-[18rem] relative ${
            collapseSearchFieldsInitially ? "bg-top-30" : "bg-top-10"
          } bg-no-repeat ${classes}`}
          style={{ borderRadius: 40, overflow: "hidden" }}
          ref={ref}
        >
          <HeaderBanner
            interval={1500}
            transition={3000}
            images={heroBg}
            texts={header}
          />
          <div className="w-full mx-auto h-full flex items-center absolute">
            <div className="flex justify-center xl:justify-start">
              <div className="hidden xl:flex">
                <MainNavigation links={Links} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          collapseSearchFieldsInitially ? "sm:max-w-2xl" : "max-w-xxl"
        } pt-5 sm:-mt-14 -mt-12 mx-5 md:mx-20 lg:mx-24 z-10`}
      >
        <div ref={searchBarRef}>
          <div
            className={`hidden xl:flex flex-col ${
              theme ? "bg-darkModeDarkGray" : "bg-white"
            } rounded-4xl p-6 shadow-xl`}
          >
            <SearchBar
              searchFocused={true}
              displayPartnerLogos={displayPartnerLogos}
              onSearch={onSearch}
              darkMode={theme}
            />
          </div>
          <SearchBarMobile
            freeCancellationFilterEnabled={freeCancellationFilterEnabled}
            displayAllInputsOnLoad={!collapseSearchFieldsInitially}
            displayFreeCancellation={displayFreeCancellation}
            searchWithoutButton={searchWithoutButton}
            searchFocused={true}
            onSearch={onSearch}
            displayPartnerLogos={displayPartnerLogos}
            toggleFreeCancellation={toggleFreeCancellation}
            darkMode={theme}
          />
        </div>
      </div>
    </div>
  );
};
