import React from 'react';
import { IconClose } from '../Icon/IconClose';

export const ButtonClose = (props) => {
    return (
        <button
            className="h-12 w-20 items-center justify-center"
            onClick={props.clicked}
        >
            <span className={`mx-auto text-lg flex items-center ${props.darkMode ? 'text-white' : 'text-peakahBlue' }` }>
                <IconClose darkMode={props.darkMode} />
                {props.label}
            </span>
        </button>
    )
}