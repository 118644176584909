export default class PartnerRate {
  constructor({
    nights = 1,
    partner = "",
    pricePerNight = 0.0,
    priceTotalStay = 0.0,
    url = "",
  }) {
    this.nights = nights;
    this.partner = partner;
    this.pricePerNight = pricePerNight;
    this.priceTotalStay = priceTotalStay;
    this.url = url;
  }
}
