import React from 'react';

export class RatingSmall extends React.Component {

	render() {

		let elements = []
		for (let index = 0; index < 5; index++) {
			elements.push(<svg key={index} className="w-4 mr-1 fill-current text-peakahBlue float-left" viewBox="0 0 22 21.02"><path d="M12,2l3.09,6.26L22,9.27l-5,4.87,1.18,6.88L12,17.77,5.82,21.02,7,14.14,2,9.27,8.91,8.26Z" transform="translate(-1 -1)" fill="none" stroke="#61b516" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" /></svg>)
		}

		return(
			<div className="mr-2 h-4" style={{ width: this.calculatedWidth(this.props.stars) }}>
				<div className="overflow-hidden absolute" style={{ width: this.calculatedWidth(this.props.stars) }}>
					<div className="h-4 w-40">
						{elements}
					</div>
				</div>
			</div>
		)
	}
	calculatedWidth(stars) {
		let width = (stars * 19.5) + 'px'
		return width;
	}

}