import Callout from "../Elements/Callout/Callout";
import { TagLine } from "../Header/TagLine";
import { travelJs } from "../../config/nft";

const TravelToEarn: React.FC = () => {
  return (
    <div className="px-5 sm:px-10 xl:px-0 max-w-xxl mx-auto mb-20 pt-20 lg:pt-0 ">
      <div className="sm:px-20 mb-sm">
        <p className="text-5xl">
          <span className="text-black font-medium">{"Travel "}</span>
          <span className="text-peakahBlue font-medium">{"To "}</span>
          <span className="text-peakahGreen font-medium">{"Earn "}</span>
        </p>
      </div>
      <TagLine classes="sm:px-20 mb-sm text-peakahBlue font-normal">
        <div className="block sm:text-xl md:text-xl lg:text-2xl xl:text-3xl">
          <span>
            We're building a cyclical ecosystem, the first of its kind in the
            World of Travel.
          </span>
        </div>
      </TagLine>

      {/* Grid Info */}
      <div className="sm:px-20">
        <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-5">
          {travelJs.map((callout) => (
            <Callout
              heading={callout.heading}
              description={callout.description}
              extra={callout.extra}
              imageUrl={callout.imageUrl}
              colour="bg-peakahBlue"
              key={callout.heading}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TravelToEarn;
