import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderInner } from '../components/HeaderInner';
import { Carousel } from '../components/Carousel';
import { SearchSidebar } from '../components/Search/SearchSidebar';
import { StickySearch } from '../components/Search/StickySearch';
import { LocationInner } from '../components/LocationPage/LocationInner';
import { MobileOverlay } from '../components/Modules/MobileOverlay';
import { SearchContext } from '../context/SearchContext/SearchContext';
import { twoWeeks, twoWeeksTomorrow } from '../utils/defaultDates';
import { Footer } from '../components/Footer';
import CMSService from '../services/CMSApi/CMSService';
import LandingPage from '../models/api/cms/LandingPage';
import Head from '../components/Head/Head';
import CardStackedContent from '../components/Elements/Card/CardStackedContent';
import LandingPagesCarouselSlide from '../models/api/cms/LandingPagesCarouselSlide';
import { UrlContext } from '../context/UrlContext/UrlContext';
import { SiteParams } from '../config/constants/SiteParams';

const CityPage: React.FC = () => {
  const history = useHistory();
  const route = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [mobileSearchDisplay, setMobileSearchDisplay] = useState(false);
  const [city, setCity] = useState<LandingPage>();
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [carouselContent, setCarouselContent] = useState<LandingPagesCarouselSlide[]>();
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");
  const URINoSlash = route.pathname.slice(1);
  const URI = URINoSlash.split('/')[1];
  const category = URINoSlash.split('/')[0];

  const getContent = async () => {
    const contentResponse = await CMSService.getLandingPage(URI, category);
    setCity(contentResponse.data);
    setLoaded(true);
  };

  const getCarouselContent = async () => {
    const contentResponse = await CMSService.getLandingPagesCarousel(category);
    setCarouselContent(contentResponse.data);
    setLoaded(true);
  };

  useEffect(() => {
    if (!city?.destination || !city?.destinationSearchRadius) return undefined;
    const { latitude, longitude } = city.destination;
    setSearchContext({
      ...searchContext,
      coordinates: { lat: latitude, lng: longitude },
      radius: city.destinationSearchRadius ?? searchContext.radius,
      destination: city.name,
      destinationShortName: city.name,
      startDate: twoWeeks,
      endDate: twoWeeksTomorrow,
    });
    getCarouselContent();
  }, [city]);

  useEffect(() => {
    setLoaded(false);
    getContent();
  }, [route.pathname]);

  useEffect( () => {
    if (urlContext !== "/" ) {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url)
		}
	}, [urlContext])

  const searchLocation = () => history.push({ pathname: `${url}/search` });

  return (
    <div>
      <Head
        metaData={{
          description: city?.meta_description,
          title: city?.meta_title,
        }}
      />
      {city && (
        <HeaderInner
          heroBg={{
            source: city?.heroImageUrl,
            alt: city?.heroImageAlt,
          }}
          heading={city?.heading}
        />
      )}
      <div className="px-5 sm:px-10 max-w-xxl mx-auto flex items-start">
        <div className="w-full lg:w-6/12 text-sm text-navyBlue leading-neat">
          <LocationInner
            subHeading={city?.subHeading}
            introduction={city?.introduction}
            loaded={loaded}
            locationChange={() => null}
          />
          {city?.content_blocks?.map((contentBlock, blockIndex) => (
            <CardStackedContent
              heading={contentBlock.Content_Blocks_id.heading}
              description={contentBlock.Content_Blocks_id.description}
              imageUrl={contentBlock.imageUrl}
              imageAlt={contentBlock.Content_Blocks_id.imageAlt}
              onClick={() => searchLocation()}
              destination={city.name}
              key={+blockIndex}
            />
          ))}
        </div>
        <div className="hidden w-full lg:w-5/12 lg:block sticky top-0 ml-auto z-40 lg:px-6 my-10 sm:my-20 sm:pt-5">
          <SearchSidebar
            coordinates={{ latitude: 0, longitude: 0 }}
            name={city?.name}
            search={searchContext}
          />
        </div>
      </div>
      <div className="pl-5 sm:pl-10 max-w-xxl mx-auto mb-10 xl:pr-10">
        <div>
          {carouselContent && (
            <Carousel
              heading="Travel &amp; Explore an Australian City"
              images={carouselContent}
              exclude={URI}
            />
          )}
        </div>
      </div>
      {mobileSearchDisplay && (
        <MobileOverlay
          darkMode={false}
          label="Close"
          clicked={() => setMobileSearchDisplay(false)}
        >
          <SearchSidebar
            coordinates={{
              latitude: city?.destination.latitude,
              longitude: city?.destination.longitude,
            }}
            name={city?.name}
            search={searchContext}
          />
        </MobileOverlay>
      )}
      <StickySearch display={true} name={city?.name} click={searchLocation} />
      <Footer />
    </div>
  );
};

export default CityPage;
