export default class ApiService {
  static getRequest = async (
    apiHost: string,
    endpoint: string,
    params?: { [key: string]: any }
  ) => {
    const query = new URLSearchParams(params);
    const url = `${apiHost}/${endpoint}${params ? `?${query}` : ''}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    try {
      return response.json();
    } catch (error) {
      throw new Error(`${error}`);
    }
  };

  static postRequest = async (
    apiHost: string,
    endpoint: string,
    postBody: { [key: string]: any }
  ) => {
    const url = `${apiHost}/${endpoint}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(postBody),
    });
    try {
      return response.json();
    } catch (error) {
      throw new Error(`${error}`);
    }
  };
}
