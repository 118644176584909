import React from "react";
import partners from "../../config/partners.json";

export const ProviderLogos = () => (
  <div className="w-full max-w-xxl mx-auto">
    <div className="grid content-center grid-cols-3 lg:flex items-center justify-center">
      {partners
        .filter((partner) => partner.featured)
        .sort((a, b) => a.order - b.order)
        .map((partner, index) => (
          <img
            src={partner.logo}
            alt={`${partner.reference.toUpperCase()} Logo`}
            key={index}
            className="mx-auto lg:mx-0 w-32"
          />
        ))}
      <span className="text-xs md:text-sm text-peakahBlue md:text-navyBlue flex items-center justify-center">
        &amp; many more!
      </span>
    </div>
  </div>
);
