import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";

const Hero: React.FC = () => {
  const image =
    "https://cdn.peakah.com/images/66d3b3c6-8747-4afa-979d-4eac8d50173c.png";
  const gradientImage = `linear-gradient(rgba(0, 0, 0, -0.1), rgba(0, 0, 0, 0) 90%), url(${image}`;

  return (
    <div
      className="relative bg-cover bg-no-repeat bg-center w-full h-[22rem] sm:h-[32rem] md:h-100 2xl:h-screen px-3 sm:px-10 pt-8 lg:px-10 scroll-smooth"
      style={{
        backgroundImage: gradientImage,
      }}
    >
      {/* Logo  */}
      <div className="max-w-xxl mx-auto">
        <div className="flex flex-col justify-center">
          <div className="flex -translate-y-5 lg:-translate-y-0 justify-center xl:justify-start">
            <div>
              <Link to="/">
                <Logo darkMode={false}/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="flex justify-center">
        <div className="absolute bottom-10 md:bottom-20 lg:bottom-28 p-3 md:p-5 lg:p-7 px-7 bg-brightBlue rounded-3xl text- md:text-2xl text-center text-white font-medium transition ease-in-out hover:-translate-y-5 hover:duration-700 duration-500">
          Hatching soon
        </div>
        <a href="#nextSection" className="absolute bottom-0">
          <svg
            fill="white"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="black"
            className="text-white animate-bounce w-8 md:w-14 duration-200"
          >
            <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Hero;
