import React, { useState, useEffect, createRef } from "react";
import { CardImage } from "../Elements/Card/CardImage";
import { CheapestRate } from "./CheapestRate";
import { ListingMobileLinks } from "./ListingMobileLinks";
import { MorePricesDrawer } from "./MorePrices/MorePricesDrawer";
import { MoreInfoDrawer } from "./MoreInfo/MoreInfoDrawer";
import { ListingName } from "./ListingName";
import { ListingInfoBlocks } from "./ListingInfoBlocks/ListingInfoBlocks";
import { CheapestRateLarge } from "./CheapestRateLarge";
import { isDesktop } from "react-device-detect";
import Hotel from "../../models/availability/Hotel";

export const Listing = ({
  clickImage,
  clickClose,
  destination = "",
  drawerOpened = () => null,
  enteredView = () => true,
  hoveringOver = () => null,
  loading = true,
  openDrawer = false,
  openMapCard = () => null,
  showClose = false,
  compact = false,
  nights = 1,
  hotel = new Hotel(),
  id,
}) => {
  const [displayMorePrices, setDisplayMorePrices] = useState(false);
  const [displayMoreInfo, setDisplayMoreInfo] = useState(false);
  const [raiseCard, setRaiseCard] = useState(false);
  const [raiseMorePrices, setRaiseMorePrices] = useState(false);
  const [raiseMoreInfo, setRaiseMoreInfo] = useState(false);
  const [mounted, setIsMounted] = useState(false);
  const [loaded, setIsLoaded] = useState(false);
  const isDisplayingOnMap = compact;

  useEffect(() => {
    setIsMounted(() => true);
  }, []);

  useEffect(() => {
    !loading && setIsLoaded(() => true);
  }, [loading]);

  useEffect(() => {
    // More prices drawer
    if (displayMorePrices) {
      setRaiseMorePrices(displayMorePrices);
      setRaiseCard(displayMorePrices);
    } else {
      setTimeout(() => {
        setRaiseCard(displayMorePrices);
        setRaiseMorePrices(displayMorePrices);
      }, 300);
    }
  }, [displayMorePrices]);

  useEffect(() => {
    // More info drawer
    if (displayMoreInfo) {
      setRaiseMoreInfo(true);
      setRaiseCard(true);
    } else {
      setTimeout(() => {
        setRaiseCard(displayMoreInfo);
        setRaiseMoreInfo(displayMoreInfo);
      }, 300);
    }
  }, [displayMoreInfo]);

  useEffect(() => {
    if (displayMoreInfo || displayMorePrices) {
      drawerOpened(id);
    }
    // Avoiding conflicts between more Info & More Prices drawers
    if (displayMoreInfo && displayMorePrices) {
      // Stop displaying both at first
      setDisplayMorePrices(false);
      setDisplayMoreInfo(false);
      // Display the drawer that isn't already raised (raise is on timeout of 300ms)
      setTimeout(() => {
        raiseMorePrices ? setDisplayMoreInfo(true) : setDisplayMorePrices(true);
      }, 400);
    }
  }, [displayMorePrices, displayMoreInfo, raiseMorePrices, raiseMoreInfo]);

  useEffect(() => {
    // Listening for change in listing ID with an open drawer. If not matching this listing, close the drawers.
    if (openDrawer !== id) {
      setDisplayMorePrices(false);
      setDisplayMoreInfo(false);
    }
  }, [openDrawer, id]);

  const ref = createRef();

  useEffect(() => {
    const cachedRef = ref.current;
    const observerOptions = {
      rootMargin: "-100px 0px 0px 0px",
      threshold: 0.1,
    };
    const observer = new IntersectionObserver((e) => {
      if (!isDisplayingOnMap && loaded && e[0].isIntersecting) enteredView(e);
    }, observerOptions);

    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [ref]);

  return (
    <div
      ref={ref}
      onMouseOver={() => isDesktop && hoveringOver(hotel.id)}
      className={`mb-12 sm:mb-8 xl:mb-0 relative ${!raiseCard &&
        "transition-all duration-700 delay-200 ease-in-out"} ${mounted &&
        !raiseCard &&
        "opacity-100 transform translate-y-0"} ${!mounted &&
        !raiseCard &&
        "opacity-0 transform translate-y-8"}`}
      onMouseLeave={() => {
        setDisplayMorePrices(false);
        isDesktop && hoveringOver(null);
      }}
    >
      <div
        className={`${
          raiseCard ? "z-40" : "z-10"
        } relative w-full shadow-straight lg:transition-shadow lg:ease-in-out lg:duration-200 lg:hover:shadow-straight-lg p-5 xl:p-0 rounded-4xl bg-white text-navyBlue flex flex-col xl:flex-row justify-between sm:h-full group`}
      >
        <div className="xl:w-72 xl:h-60 xl:flex-shrink-0 -mt-12 xl:-mt-0">
          <CardImage
            classification={hotel.listingType.classification}
            typeName={hotel.listingType.label}
            loaded={loaded}
            image={hotel.photo}
            partner={hotel.imageGalleryPartnerDetails.partner}
            partnerId={hotel.imageGalleryPartnerDetails.partnerId}
            clickImage={clickImage}
            showClose={showClose}
            clickClose={clickClose}
            stars={hotel.stars}
            roomCount={hotel.roomCount}
            maxGuests={hotel.maxGuests}
            reviewScore={hotel.reviewScore}
            compact={compact}
            id={id}
            name={hotel.name}
          />
        </div>
        <div className="w-full xl:p-5 xl:flex xl:flex-col">
          <div className="relative xl:flex xl:h-full justify-between">
            <div className="w-60 justify-between flex flex-col">
              <ListingName name={hotel.name} />
              <ListingInfoBlocks
                propertyAttributes={hotel.propertyAttributes}
                destination={destination}
                distance={hotel.distanceText}
                maxGuests={hotel.maxGuests}
                reviewWord={hotel.reviewWord}
                reviewScore={hotel.reviewScore}
                roomCount={hotel.roomCount}
              />
            </div>
            <div>
              <CheapestRateLarge
                morePrices={hotel.rates.length > 1}
                morePricesOpened={displayMorePrices}
                rate={hotel.rates[0]}
                nights={nights}
                toggleDisplayMorePrices={() =>
                  setDisplayMorePrices(!displayMorePrices)
                }
              />
            </div>
          </div>
          <div className="h-full flex-col justify-end xl:hidden">
            <div className="py-5 xl:hidden">
              {!compact && (
                <ListingMobileLinks
                  toggleDisplayMorePrices={() =>
                    setDisplayMorePrices(!displayMorePrices)
                  }
                  toggleDisplayMoreInfo={() =>
                    setDisplayMoreInfo(!displayMoreInfo)
                  }
                  morePricesOpened={displayMorePrices}
                  moreInfoOpened={displayMoreInfo}
                  openMapCard={() => openMapCard(hotel.id)}
                  morePrices={hotel.rates.length > 1}
                />
              )}
            </div>
            <CheapestRate rate={hotel.rates[0]} nights={nights} />
          </div>
        </div>
      </div>
      <MorePricesDrawer
        display={displayMorePrices}
        raise={raiseMorePrices}
        compact={compact}
        rates={hotel.rates}
        nights={nights}
      />
      <MoreInfoDrawer
        destination={destination}
        display={displayMoreInfo}
        raise={raiseMoreInfo}
        compact={compact}
        reviewScore={hotel.reviewScore}
        reviewWord={hotel.reviewWord}
        reviewCount={hotel.reviewCount}
        openMapCard={openMapCard}
        distance={hotel.distanceText}
      />
    </div>
  );
};
