import React from 'react';
import { IconPencil } from '../Elements/Icon/IconPencil';
import { ListingToolTip } from './ListingToolTip';

export const ReviewDetails = (props) => {

    const { compact=false, reviewWord='',reviewCount=0,displayToolTip=true,reviewScore=0 } = props
    return (
    <div className={`items-center space-x-3 ${compact ? 'flex sm:py-2 pt-0':' hidden pt-3 '}`}>
        <IconPencil width={20}/>
        <div className="flex flex-shrink-0 items-center text-peakahBlue">
            <span className="sm:font-semibold text-xs sm:text-sm ">
                {`${reviewScore > 0 ? reviewScore + ' ' + reviewWord : 'No Reviews Yet' }`} 
            </span>
            {reviewScore > 0 &&
                <span className="text-xs ml-1">({reviewCount} Reviews)</span>
            }
            {displayToolTip &&
                <div className="ml-2">
                    <ListingToolTip message="Based on an average of all of our integrated providers" />
                </div>
            }
        </div>
    </div>
    )
}