export const trackSearchCTA = (provider,rate) => {
    window.dataLayer.push({
        event: 'OTA Referrals',
        eventProps: {
            category: 'OTA Referrals',
            action: 'Click From Search Results',
            label: provider,
            value: rate * 0.3
        }
    });
}