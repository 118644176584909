import config from "../../config/general.json";

export const FilterState = () => {
  return {
    type: [],
    price: 200,
    rating: 0,
    star: [],
    sortBy: "distance-nearest",
    amenities: Object.assign(
      {},
      ...config.amenitiesFilterOptions.map(({ id, defaultValue }) => ({
        [id]: defaultValue,
      }))
    ),
  };
};
