import React from "react";
import { Icon } from "./Icon";
export const IconSorting = (props) => (
  <Icon {...props}>
    <g transform="translate(-1081.75 -12627.663)">
      <line
        y1="7"
        transform="translate(1082.565 12645.588) rotate(-90)"
        fill="none"
        stroke="#61b516"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="14.935"
        transform="translate(1082.5 12639.5)"
        fill="none"
        stroke="#61b516"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        y1="9"
        transform="translate(1082.565 12633.413) rotate(-90)"
        fill="none"
        stroke="#61b516"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </Icon>
);
