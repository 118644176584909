import React from 'react';
import { Heading1 } from '../Elements/Heading/Heading1'
export class Headline extends React.Component {
    render() {
		return (
            <Heading1 classes={this.props.classes}>
              {this.props.children}
            </Heading1>
        )
	}
}