import { featuredLocations } from "../config/content/homeContent";
import FeaturedLocation from "../models/view/FeaturedLocation";
export default class FeaturedLocationsService {
  static getFeaturedLocations = (site: string, placeholder: boolean) => {
    const locations = featuredLocations
      .filter((data) => data.site === site || (placeholder ? data.site === "placeholder" : ""))
      .map((location) => new FeaturedLocation(location));
    return locations;
  };
}
