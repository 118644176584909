import React from "react";
import { Icon } from "./Icon";

export const IconDownArrowRotating = ({
  classes = "",
  rotate = true,
  width = 12,
  height = 12,
  strokeWidth = 4,
}) => (
  <Icon
    classes={`${
      rotate ? "transform rotate-180" : ""
    } transition-transform duration-500 ease-in-out ${classes}`}
    width={width}
    height={height}
    strokeWidth={strokeWidth}
  >
    <path d="M6 9l6 6 6-6" />
  </Icon>
);
