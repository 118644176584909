import { CarouselSlide } from "./CarouselSlide";

export const CarouselNoControls = ({ slides = [], wide=false, clicked = () => true }) => (
  <div className="flex overflow-x-scroll scrolling-touch carousel">
    {slides.map((item, index) => (
      <CarouselSlide
        url={item.url}
        image={item.image}
        name={item.name}
        marketingPlaceholder={item.site === 'placeholder'}
        key={index}
        firstToDisplay={index === 0}
        clicked={() => clicked(item)}
        wide={wide}
      />
    ))}
  </div>
);
