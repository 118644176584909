export const trackDestinationSearchParams = ({
  destination = "",
  results = 0,
  resultsWithRates = 0,
  adults = 0,
  children = 0,
  rooms = 0,
  nights = 0,
  radius = 100
}) => {
  window.dataLayer.push({
    event: "destinationSearch",
    destinationSearchValues: {
      destination: destination,
      results: results,
      resultsWithRates: resultsWithRates,
      adults: adults,
      children: children,
      rooms: rooms,
      nights: nights,
      radius: radius
    },
  });
};
