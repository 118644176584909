import React, { useState, useEffect, useContext, useRef } from "react";
import { ImageGallery } from "../components/ImageGallery/ImageGallery";
import "swiper/swiper.min.css";
import {
  maxRates,
  sortResults,
  filterHotel,
  countActiveResults,
} from "../utils/filters.js";
import { countNights } from "../utils/countNights";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { CovidCallOut } from "../components/CallOut/CovidCallOut";
import { useQueryParam } from "../utils/useQueryStringParams";
import { SearchMessages } from "../components/Search/SearchMessages";
import { ExtendingSearchCallOut } from "../components/Search/ExtendingSearchCallOut";
// Context
import { SearchContext } from "../context/SearchContext/SearchContext";
import { FilterContext } from "../context/FilterContext/FilterContext";
import { ListingGrid } from "../components/Listing/ListingGrid";
import { ImageGalleryMobile } from "../components/ImageGallery/ImageGalleryMobile";
import { trackDestinationSearchParams } from "../services/tracking/trackDestinationSearchParams";
import { TopBar } from "../components/Search/TopBar";
import { ToolBar } from "../components/Search/ToolBar";
import { FilterBarSide } from "../components/FilterBar/FilterBarSide";
import GoogleMapReact from "google-map-react";
import { MapMarker } from "../components/Map/MapMarker";
import config from "../config/general.json";
import { ListingCompact } from "../components/Listing/ListingCompact";
import { FilterState } from "../context/FilterContext/FilterState";
import { FiltersFeedback } from "../components/Search/FiltersFeedback";
import { SearchBarMobileSticky } from "../components/SearchBar/SearchBarMobileSticky";
import { formatDateShort } from "../utils/formatDate";
import { MobileToggleButtons } from "../components/Search/MobileToggleButtons/MobileToggleButtons";
import { HeaderMobile } from "../components/Header/HeaderMobile";
import { BottomDrawer } from "../components/Modules/BottomDrawer";
import { SortByFilter } from "../components/SortByFilter";
import { IconSorting } from "../components/Elements/Icon/IconSorting";
import { FilterBar } from "../components/FilterBar/FilterBar";
import Hotel from "../models/availability/Hotel";
import { IconCamera } from "../components/Elements/Icon/IconCamera";
import { CheapestRate } from "../components/Listing/CheapestRate";
import { FullScreenModal } from "../components/Modules/FullScreenModal/FullScreenModal";
import { CheapestRateSingleLine } from "../components/Listing/CheapestRateSingleLine";
import reduceToUniqueArray from "../utils/reduceToUniqueArray";
import SearchAgainButton from "../components/Search/SearchAgainButton";
import { MapNotifications } from "../components/Map/MapNotifications";
import SearchUtility from "../components/Search/SearchUtility";
import SearchAvailabilityService from "../services/SearchApi/SearchAvailabilityService";
import { UrlContext } from "../context/UrlContext/UrlContext";
import { SiteParams } from "../config/constants/SiteParams";
import { IconCurrency } from "../components/Elements/Icon/IconCurrency";
import { CurrencySelector } from "../components/Currency/CurrencySelector";

export const Search = (props) => {
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24);
  // Data
  const [
    propertyAttributesFromAvailability,
    setPropertyAttributesFromAvailability,
  ] = useState([]);
  const [listingTypesFromAvailability, setListingTypesFromAvailability] =
    useState([]);
  const [response, setResponse] = useState([]);
  const [results, setResults] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [activeResults, setActiveResults] = useState(false);
  // Interactions
  const [userChangedPrice, setUserChangedPrice] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [sortByOpen, setSortByOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);
  const [displayGallery, setDisplayGallery] = useState(false);

  const [searchAttempted, setSearchAttempted] = useState(false);
  const [galleryHotel, setGalleryHotel] = useState(null);
  const [resultsScrollDirection, setResultsScrollDirection] = useState("up");
  // Search Parameters
  const [priceMax, setPriceMax] = useState(200);
  const [priceMin, setPriceMin] = useState(0);
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [filterContext, setFilterContext] = useContext(FilterContext);
  const [queryStringLat, setQueryStringLat] = useQueryParam("lat", null);
  const [queryStringLng, setQueryStringLng] = useQueryParam("lng", null);
  const [queryStringName, setQueryStringName] = useQueryParam("name", "");
  const [queryStringRadius, setQueryStringRadius] = useQueryParam(
    "radius",
    searchContext.radius
  );
  const [displayRadiusRing, setDisplayRadiusRing] = useQueryParam(
    "radiusRing",
    false
  );
  const [expandSearchRadius, setExpandRadius] = useState(false);
  const [extendingSearch, setExtendingSearch] = useState(false);
  const [maxRadiusExceeded, setMaxRadiusExceeded] = useState(false);
  const [nextSearchCoordinates, setNextSearchCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [urlContext, setUrlContext] = useContext(UrlContext)
  const [currency, setCurrency] = useState(SiteParams.find(obj => obj.url === urlContext).value);
  // Display
  const [covidWarningEnabled, setCovidWarningEnabled] = useState(true);
  const [displayResults, setDisplayResults] = useState(24);
  const resultsContainer = useRef(null);
  const [resultsVisible, setResultsVisible] = useState(0);
  const [resultsScrollDistance, setResultsScrollDistance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Maps
  const [mapDraggable, setMapDraggable] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const mapContainerRef = useRef(null);
  const [mapZoom, setMapZoom] = useState(14);
  const [mapNotificationMessage, setMapNotificationMessage] = useState("");
  const [searchFromMap, setSearchFromMap] = useState(false);
  const [openedDeal, setOpenedDeal] = useState(null);
  const [activeHotel, setActiveHotel] = useState(null);
  const [mapRadiusRing, setMapRadiusRing] = useState({ radius: 0 });
  const [mapDisplayChanged, setMapDisplayChanged] = useState(false);

  const [googleApiAvailable, setGoogleApiAvailable] = useState(false);
  const googleMapObject = useRef(null);
  const googleMapsService = useRef(null);
  const {
    startDate,
    endDate,
    adults,
    children,
    rooms,
    coordinates,
    destination,
    destinationShortName,
    mapMoved,
    radius,
    queryStringUsed,
  } = searchContext;
  const { sortBy } = filterContext;
  const nights = countNights(startDate, endDate);
  const resetState = () => {
    setLoading(true);
    setFilterContext({ ...filterContext, type: [] });
    setResults([]); // Reset state when searching again
    setResponse([]); // Reset state when searching again
    setHotels([]); // Reset hotels state when searching again
    setDisplayResults(24);
    setResultsVisible(0);
    setActiveHotel(null);
    setOpenedDeal(null);
    setNextSearchCoordinates(coordinates);
    setMapDisplayChanged(false);
    resultsContainer.current.scrollTo(0, 0);
  };

  const searchHotels = async (
    radiusToSearch = 0,
    allowExtendingSearch = true
  ) => {
    console.log(
      `Search ${radiusToSearch} km in ${coordinates.lat} and allow extending: ${allowExtendingSearch}`
    );
    setSearchFromMap(false);
    setMapZoom(14);
    resetState();
    if (!coordinates.lat || !coordinates.lng) {
      setError(true);
      setLoading(false);
      return;
    }
    if (startDate === null || endDate === null)
      setSearchContext({
        ...searchContext,
        startDate: today,
        endDate: tomorrow,
      });
    setMaxRadiusExceeded(false);
    setExpandRadius(false);
    setError(false);
    setFiltersOpen(false);
    mapRadiusRingUpdate(radiusToSearch, coordinates);
    const availability = await SearchAvailabilityService.getAvailability({
      lat: coordinates.lat,
      lng: coordinates.lng,
      radius: 0,
      radiusTo: radiusToSearch,
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
      rooms: rooms,
      nights: nights,
      currency: currency,
    });

    if (availability.count === 0 && !allowExtendingSearch) {
      setMapNotificationMessage("No accommodation found");
      setSearchAttempted(true);
      console.log(`No hotels found within ${radiusToSearch}m`);
      setLoading(false);
      return;
    }

    if (availability.count === 0 && allowExtendingSearch) {
      console.log(`No hotels found within ${radiusToSearch}m`);
      console.log("keep searching");
      setSearchAttempted(true);
      setExtendingSearch(false);
      setExpandRadius(true);
      return;
    }

    setResponse(availability.data);
    setPropertyAttributesFromAvailability(
      reduceToUniqueArray(
        [
          ...propertyAttributesFromAvailability,
          ...availability.propertyAttributes,
        ],
        "name"
      )
    );
    setListingTypesFromAvailability(
      reduceToUniqueArray(
        [...listingTypesFromAvailability, ...availability.listingTypes],
        "id"
      )
    );

    // Only want to use query string params once, set flag to ignore after this
    queryStringLat &&
      queryStringLng &&
      !queryStringUsed &&
      setSearchContext({
        ...searchContext,
        startDate: today,
        endDate: tomorrow,
        queryStringUsed: true,
      });
    trackDestinationSearchParams({
      destination: destination,
      results: availability.count,
      adults: adults,
      children: children,
      rooms: rooms,
      nights: nights,
      radius: radius,
    });
    return availability.data;
  };

  const searchWithinRadius = async (
    radiusToSearch = 0,
    searchCoordinates = { lat: 0, lng: 0 }
  ) => {
    console.log(`Search ${radiusToSearch} km in ${searchCoordinates.lat}`);
    resetState();
    setMapDisplayChanged(false);
    mapRadiusRingUpdate(radiusToSearch, searchCoordinates);
    const availability = await SearchAvailabilityService.getAvailability({
      lat: searchCoordinates.lat,
      lng: searchCoordinates.lng,
      radius: 0,
      radiusTo: radiusToSearch,
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
      rooms: rooms,
      nights: nights,
      currency: currency,
    });

    if (availability.count === 0) {
      setMapNotificationMessage("No accommodation found");
      console.log(`No hotels found within ${radiusToSearch}m`);
      setSearchAttempted(true);
      setLoading(false);
      return;
    }

    setResponse(availability.data);
    setPropertyAttributesFromAvailability(
      reduceToUniqueArray(
        [
          ...propertyAttributesFromAvailability,
          ...availability.propertyAttributes,
        ],
        "name"
      )
    );
    setListingTypesFromAvailability(
      reduceToUniqueArray(
        [...listingTypesFromAvailability, ...availability.listingTypes],
        "id"
      )
    );
    trackDestinationSearchParams({
      destination: destination,
      results: availability.count,
      adults: adults,
      children: children,
      rooms: rooms,
      nights: nights,
      radius: radius,
    });
    return availability.data;
  };

  const saveMaxPrice = (hotels = []) => {
    const maxPrices = maxRates(hotels);
    const highestRate = parseInt(Math.max(...maxPrices) + 1);
    const lowestRate = parseInt(Math.min(...maxPrices) - 1);
    setPriceMax(highestRate);
    setPriceMin(lowestRate);
  };

  useEffect(() => {
    !userChangedPrice &&
      setFilterContext({ ...filterContext, price: priceMax });
  }, [priceMax, priceMin, userChangedPrice]);

  useEffect(() => {
    // Saving max price for filter + saving results state object
    if (response.length === 0) return;
    saveMaxPrice(response);
    setResults(response);
  }, [response]);

  const amenitiesToFilter = Object.keys(filterContext.amenities).filter(
    (key) => filterContext.amenities[key] === true
  );

  useEffect(() => {
    const filteredHotels = results.map((hotel) => {
      const shouldDisplay = filterHotel(
        hotel,
        filterContext,
        amenitiesToFilter
      );
      hotel.enabled = shouldDisplay;
      return hotel;
    });
    const sortedresults = sortResults(filteredHotels, sortBy);
    setHotels(sortedresults);
  }, [results, filterContext]);

  useEffect(() => {
    setHotels((previousHotels) => sortResults(previousHotels, sortBy));
    resultsContainer.current.scrollTo(0, 0);
  }, [sortBy]);

  useEffect(() => {
    // Counting number of active hotels and saving outcome to state
    setResultsVisible(countActiveResults(hotels) || 0);
    setMapNotificationMessage("");
    if (googleApiAvailable && !searchFromMap)
      handleBoundsChange(
        googleMapObject.current,
        googleMapsService.current,
        hotels
      );
  }, [hotels]);

  useEffect(() => {
    activeResults > 0 && setLoading(false);
  }, [activeResults]);

  const openGallery = async (hotel = new Hotel()) => {
    setDisplayGallery(true);
    setGalleryHotel(hotel);
  };

  const toggleMobileFilters = (shouldDisplay = true) => {
    if (isMobile) {
      setFiltersOpen(shouldDisplay);
      setMobileFiltersOpen(shouldDisplay);
    }
  };

  const previousResultsScroll = useRef();

  const handleResultsScroll = (scrollTop = 0) => {
    setResultsScrollDistance(scrollTop);
    const changedScrollDistance = previousResultsScroll.current - scrollTop;
    if (changedScrollDistance >= 0) setResultsScrollDirection("up");
    else setResultsScrollDirection("down");
    previousResultsScroll.current = scrollTop;
  };

  const mapRadiusRingUpdate = (
    radiusToDisplay = 0,
    coordinatesToDisplay = { lat: 0, lng: 0 }
  ) => {
    const mapRing = mapRadiusRing;
    if (mapRing.radius === 0) return;
    setMapRadiusRing({ radius: 0 });
    mapRing.setRadius(radiusToDisplay);
    mapRing.setCenter({
      lat: coordinatesToDisplay.lat,
      lng: coordinatesToDisplay.lng,
    });
    setMapRadiusRing(mapRing);
  };

  useEffect(() => {
    // Parsing search params from query string e.g. lat=xxx&lng=yyy&name=zzz
    queryStringLat &&
      queryStringLng &&
      setSearchContext({
        ...searchContext,
        radius: parseInt(queryStringRadius),
        destination: queryStringName,
        destinationShortName: queryStringName,
        coordinates: {
          lat: parseFloat(queryStringLat),
          lng: parseFloat(queryStringLng),
        },
        queryStringUsed: true,
      });

    window.localStorage.getItem("displayCovid19", false) &&
      setCovidWarningEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const activeQueryStrings = queryStringLat && !queryStringUsed;
    if (activeQueryStrings) return;
    !loading && searchHotels(radius, true);
  }, [startDate, endDate, adults, children, rooms, queryStringUsed, coordinates, currency]);

  useEffect(() => {
    setMapNotificationMessage("");
  }, [nextSearchCoordinates]);

  const getMoreResults = async () => {
    if (radius >= 65000) {
      setMaxRadiusExceeded(true);
      setLoading(false);
      setExtendingSearch(false);
      return false;
    }
    if (extendingSearch || mapOpen) return null; // Don't search if already searching
    setMapDisplayChanged(false);
    setExtendingSearch(true);
    setExpandRadius(false);
    setSearchContext({ ...searchContext, radius: radius + 20000 });
    setError(false);
    const radiusTo = radius + 20000;

    const availability = await SearchAvailabilityService.getAvailability({
      lat: coordinates.lat,
      lng: coordinates.lng,
      radius: radius,
      radiusTo: radiusTo,
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
      rooms: rooms,
      nights: nights,
      currency: currency,
    });

    if (availability.error) {
      setError(true);
      setLoading(false);
      setExpandRadius(false);
      setExtendingSearch(false);
      console.error(availability.error);
      return true;
    }

    try {
      if (availability.count === 0)
        throw new Error(
          `No hotels found within ${radiusTo}km - keep searching`
        );
      if (availability.count === 0)
        throw new Error(
          `No hotels with rates found within ${radiusTo}km  - keep searching`
        );
      mapRadiusRingUpdate(radiusTo, coordinates);
      setResponse(() => [...hotels, ...availability.data]);
      setPropertyAttributesFromAvailability(
        reduceToUniqueArray(
          [
            ...propertyAttributesFromAvailability,
            ...availability.propertyAttributes,
          ],
          "name"
        )
      );
      setListingTypesFromAvailability(
        reduceToUniqueArray(
          [...listingTypesFromAvailability, ...availability.listingTypes],
          "id"
        )
      );
      setExtendingSearch(false);
      setDisplayResults(displayResults + 12);
      setLoading(false);
      if (availability.count < 12) {
        setExpandRadius(true);
        setExtendingSearch(false);
      }
      trackDestinationSearchParams({
        destination: destination,
        results: availability.count,
        adults: adults,
        children: children,
        rooms: rooms,
        nights: nights,
        radius: radiusTo,
      });
      return true;
    } catch (error) {
      console.log(error);
      setExtendingSearch(false);
      setExpandRadius(true);
    }
  };

  useEffect(() => {
    if (
      expandSearchRadius &&
      !extendingSearch &&
      !maxRadiusExceeded &&
      !mapOpen
    ) {
      getMoreResults();
    }
  }, [expandSearchRadius]);

  useEffect(() => {
    resultsVisible > 0 ? setActiveResults(true) : setActiveResults(false);
  }, [resultsVisible]);

  const updateFilterStars = (event) => {
    let stars = filterContext.star;
    let val = parseInt(event.value);
    if (event.checked) {
      stars.push(val);
    } else {
      stars.splice(stars.indexOf(val), 1);
    }
    setFilterContext({ ...filterContext, star: stars });
  };

  const updateFilterTypes = (listingType) => {
    let updatedContextType = filterContext.type;
    const existingTypeIndex = updatedContextType.findIndex(
      (contextType) => contextType.id === listingType.id
    );
    if (existingTypeIndex > -1) {
      updatedContextType[existingTypeIndex] = listingType;
    } else {
      updatedContextType = [...updatedContextType, listingType];
    }
    setFilterContext({ ...filterContext, type: updatedContextType });
  };

  const updateFilterAmenities = (enabled = false, id = "") => {
    return setFilterContext({
      ...filterContext,
      amenities: {
        ...filterContext.amenities,
        [id]: enabled,
      },
    });
  };

  const searchNewLocation = () => {
    setSearchFromMap(true);
    const bounds = googleMapObject?.current?.getBounds();
    const radiusVisibleOnMap = SearchUtility.calculateMapRadius(bounds);
    searchWithinRadius(radiusVisibleOnMap, nextSearchCoordinates);
    setSearchContext({
      ...searchContext,
      destination: "Custom Search",
      destinationShortName: "Map Centre",
    });
  };

  const handleScrolledToListEnd = (shouldGetMoreResults = false) => {
    setExpandRadius(shouldGetMoreResults);
  };

  const handleBoundsChange = (map, maps, hotels) => {
    const bounds = new maps.LatLngBounds();
    const setBounds = hotels.map((hotel) => {
      const position = new maps.LatLng(
        hotel.coordinates.latitude,
        hotel.coordinates.longitude
      );
      return bounds.extend(position);
    });
    map.fitBounds(bounds);
    return setBounds;
  };

  const previousGoogleApiState = useRef(googleApiAvailable);
  useEffect(() => {
    if (
      previousGoogleApiState.current === false &&
      googleApiAvailable &&
      googleMapObject.current
    ) {
      const mapCircle = new googleMapsService.current.Circle({
        strokeColor: "#1aa086",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#1aa086",
        fillOpacity: 0.3,
        map: googleMapObject.current,
        center: { lat: coordinates.lat, lng: coordinates.lng },
        radius: radius,
        visible: displayRadiusRing,
      });
      setMapRadiusRing(mapCircle);
    }
  }, [googleApiAvailable, googleMapObject.current]);

  useEffect(() => {
    mapRadiusRingUpdate(radius, coordinates);
  }, [mapRadiusRing]);

  useEffect(() => {
    const mobileHeaderShouldDisplay =
      (isMobile &&
        resultsScrollDistance > 200 &&
        resultsScrollDirection === "up") ||
      mapOpen;
    setMobileHeaderOpen(mobileHeaderShouldDisplay);
    setSearchFromMap(false);
    return () => setMobileHeaderOpen(mobileHeaderShouldDisplay);
  }, [resultsScrollDirection, mapOpen, resultsScrollDistance]);

  const isOutsideMap = (childRect = DOMRect, parentRect = DOMRect) => {
    if (isMobileOnly) return false;
    if (childRect.left < parentRect.left) return true;
    if (childRect.top < parentRect.top + 165) return true;
    if (childRect.right > parentRect.right) return true;
    return false;
  };

  const handleCardOnMap = (id) => {
    const hotelFromState = hotels.find((hotel) => hotel.id === id);
    setOpenedDeal(hotelFromState);
    setMapCenter([
      hotelFromState.coordinates.latitude,
      hotelFromState.coordinates.longitude,
    ]);
    setMapOpen(true);
    return hotelFromState;
  };

  const previousCoordinates = useRef(coordinates);
  const previousZoom = useRef(mapZoom);
  useEffect(() => {
    // Only set state if zoom changes without coords changing
    if (
      previousCoordinates.current?.lat === coordinates.lat &&
      mapZoom !== previousZoom.current
    ) {
      setMapNotificationMessage("");
      setMapDisplayChanged(true);
    }
    previousZoom.current = mapZoom;
  }, [mapZoom]);

  const handleMapDisplayChange = (newCoordinates = { lat: 0, lng: 0 }) => {
    setNextSearchCoordinates(newCoordinates);
    setMapNotificationMessage("");
    setMapDisplayChanged(true);
  };

  return (
    <>
      <div className="relative flex flex-col justify-between overflow-hidden">
        <div className="fixed top-0 w-full flex flex-col shadow-straight z-70">
          <CovidCallOut
            enabled={covidWarningEnabled}
            onClick={() => {
              setCovidWarningEnabled(false);
              window.localStorage.setItem("displayCovid19", false);
            }}
          />
          <TopBar onSearch={searchHotels} />
          <SearchBarMobileSticky
            leftText={destinationShortName}
            rightText={`${formatDateShort(startDate)} - ${formatDateShort(
              endDate
            )}`}
            onSearch={searchHotels}
            display={mobileHeaderOpen}
          />
        </div>
        {/* Currency Drawer */}
        <BottomDrawer
          enabled={currencyOpen}
          fullScreen={false}
          leftLabel="Currency"
          iconLeft={<IconCurrency />}
          onClickRight={() => setCurrencyOpen(false)}
          onClickScrim={() => setCurrencyOpen(false)}
        >
          <CurrencySelector
            selectionChanged={(selected) => {
              setCurrency(selected);
              setCurrencyOpen(false);
            }}
            selected={currency}
            displayAsChild={false}
          />
        </BottomDrawer>
        {/* Sort Drawer */}
        <BottomDrawer
          enabled={sortByOpen}
          fullScreen={false}
          leftLabel="Sort By"
          iconLeft={<IconSorting />}
          onClickRight={() => setSortByOpen(false)}
          onClickScrim={() => setSortByOpen(false)}
        >
          <SortByFilter
            selectionChanged={(sortSelected) => {
              setFilterContext({ ...filterContext, sortBy: sortSelected });
              setSortByOpen(false);
            }}
            selected={sortBy}
            displayAsChild={false}
          />
        </BottomDrawer>
        {/* Filters Drawer */}
        <BottomDrawer
          enabled={mobileFiltersOpen}
          fullScreen={true}
          leftLabel="Filters"
          iconLeft={<IconSorting />}
          onClickRight={() => setMobileFiltersOpen(false)}
          onClickScrim={() => setMobileFiltersOpen(false)}
        >
          <FilterBar
            amenitiesChange={({ enabled, id }) => {
              updateFilterAmenities(enabled, id);
            }}
            amenitiesFilters={propertyAttributesFromAvailability}
            listingTypes={listingTypesFromAvailability}
            priceChange={(price) =>
              setFilterContext({ ...filterContext, price: price })
            }
            priceMax={priceMax}
            priceMin={priceMin}
            starChange={(star) => updateFilterStars(star)}
            ratingChange={(rating) =>
              setFilterContext({ ...filterContext, rating: +rating.value })
            }
            typeChange={(types) => updateFilterTypes(types)}
          />
        </BottomDrawer>
        {/* Mobile Gallery */}
        <BottomDrawer
          enabled={displayGallery && isMobile}
          extraDrawer={
            galleryHotel && (
              <CheapestRate
                compact={false}
                rate={galleryHotel?.rates[0]}
                nights={nights}
              />
            )
          }
          fullScreen={true}
          fullWidth={true}
          leftLabel={galleryHotel?.name}
          iconLeft={<IconCamera />}
          onClickRight={() => {
            setDisplayGallery(false);
            setGalleryHotel();
          }}
          onClickScrim={() => {
            setDisplayGallery(false);
            setGalleryHotel();
          }}
        >
          <ImageGalleryMobile
            partner={galleryHotel?.imageGalleryPartnerDetails.partner}
            partnerId={galleryHotel?.imageGalleryPartnerDetails.partnerId}
          />
        </BottomDrawer>
        <div className="flex justify-between bg-blue-100 relative h-full">
          <FilterBarSide
            amenitiesChange={({ enabled, id }) => {
              updateFilterAmenities(enabled, id);
            }}
            listingTypes={listingTypesFromAvailability}
            amenitiesFilters={propertyAttributesFromAvailability}
            enabled={filtersOpen}
            enableTopOffset={covidWarningEnabled}
            closeFilters={() => setFiltersOpen(false)}
            ratingChange={(rating) =>
              setFilterContext({ ...filterContext, rating: +rating })
            }
            resetFilters={() => setFilterContext(FilterState)}
            priceChange={(price) => {
              setUserChangedPrice(true);
              setFilterContext({ ...filterContext, price: price });
            }}
            priceMax={priceMax}
            priceMin={priceMin}
            typeChange={(types) => updateFilterTypes(types)}
            starChange={(star) => updateFilterStars(star)}
          />
          <div
            onScroll={(event) => handleResultsScroll(event.target.scrollTop)}
            ref={resultsContainer}
            className={`${
              mapOpen ? "hidden" : "absolute xl:relative top-0 left-0 z-10"
            } xl:flex xl:left-auto flex-col overflow-y-scroll xl:min-w-3xl xl:max-w-3xl w-full h-screen ${
              covidWarningEnabled ? "pt-28 sm:pt-34 xl:pt-40" : "xl:pt-20"
            } bg-blue-100`}
          >
            <div className="xl:hidden">
              <HeaderMobile
                collapseSearchFieldsInitially={true}
                displayFreeCancellation
                displayPartnerLogos={false}
                freeCancellationFilterEnabled={
                  filterContext.amenities.freeCancellation
                }
                heroBg={"https://cdn.peakah.com/images/home-bg-2021.png"}
                onSearch={searchHotels}
                toggleFreeCancellation={(enabled, id) =>
                  updateFilterAmenities(enabled, id)
                }
              />
              <MobileToggleButtons
                enabled={true}
                filtersOpen={mobileFiltersOpen}
                mapOpen={mapOpen}
                onFiltersClick={() => setMobileFiltersOpen(!mobileFiltersOpen)}
                onSortByClick={() => setSortByOpen(!sortByOpen)}
                onMapClick={() => setMapOpen(!mapOpen)}
                onCurrencyClick={() => setCurrencyOpen(!currencyOpen)}
                sticky={false}
              />
            </div>
            <ToolBar
              freeCancellationFilterEnabled={
                filterContext.amenities.freeCancellation
              }
              toggleFreeCancellation={(enabled, id) =>
                updateFilterAmenities(enabled, id)
              }
              toggleMoreFilters={() => setFiltersOpen(!filtersOpen)}
              sortChange={(sortSelected) =>
                setFilterContext({ ...filterContext, sortBy: sortSelected })
              }
              sortBy={sortBy}
              display={resultsScrollDirection === "up"}
              currency={currency}
              currencyChange={(currencySelected) =>
                setCurrency(currencySelected)
              }
            />
            <FiltersFeedback
              amenities={filterContext.amenities}
              amenitiesChange={(id) => updateFilterAmenities(false, id)}
              listingTypes={filterContext.type}
              listingTypeChange={(type) => updateFilterTypes(type)}
              rating={filterContext.rating}
              ratingChange={(rating) =>
                setFilterContext({ ...filterContext, rating: rating })
              }
              stars={filterContext.star}
              starChange={(star) =>
                updateFilterStars({ checked: false, value: star })
              }
            />
            <ListingGrid
              mapOpen={mapOpen}
              loading={loading}
              hotels={hotels.filter((hotel) => hotel.enabled)}
              search={searchContext}
              cardOnMap={handleCardOnMap}
              coordinates={coordinates}
              openGallery={(listing) => openGallery(listing)}
              destination={destinationShortName}
              loadMore={() => handleScrolledToListEnd(searchFromMap === false)}
              nights={nights}
              activateCard={(id) => {
                setActiveHotel(id);
                isMobile &&
                  setOpenedDeal(hotels.find((hotel) => hotel.id === id));
              }}
            />
            <div className="w-full flex flex-col items-center text-peakahBlue justify-center text-center px-6 xl:px-20 mb-40">
              <SearchMessages
                error={error}
                loading={loading}
                resultCount={hotels.filter((hotel) => hotel.enabled).length}
                searchAttempted={searchAttempted}
                destination={coordinates.lat && coordinates.lng ? true : false}
                activeResults={activeResults}
                extendingSearch={extendingSearch}
                maxRadiusExceeded={maxRadiusExceeded}
              />
              <ExtendingSearchCallOut
                destination={destination}
                loadingMore={extendingSearch && !maxRadiusExceeded}
                maxRadiusExceeded={maxRadiusExceeded}
                resultsExist={hotels.length > 0}
                clicked={() => toggleMobileFilters(true)}
              />
            </div>
          </div>
          <div
            ref={mapContainerRef}
            className={`${
              mapOpen ? "z-10" : "z-0"
            } h-screen xl:h-screen w-screen xl:w-full bg-gray-300 xl:pt-20 relative`}
          >
            <SearchAgainButton
              onClick={searchNewLocation}
              enabled={mapDisplayChanged}
            />
            <MapNotifications message={mapNotificationMessage} />
            <GoogleMapReact
              onDragEnd={(event) =>
                handleMapDisplayChange({
                  lat: event.center.lat(),
                  lng: event.center.lng(),
                })
              }
              onChange={(event) => {
                setMapZoom(event.zoom);
              }}
              shouldUnregisterMapOnUnmount={true}
              onZoomAnimationStart={(zoomValue) => setMapZoom(zoomValue)}
              draggable={mapDraggable}
              options={{
                clickableIcons: false,
                panControl: false,
                mapTypeControl: false,
                scrollwheel: true,
                fullscreenControl: false,
                styles: config.mapStyles,
                zoomControl: isDesktop,
                gestureHandling: "greedy",
                margin: ["20px"],
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => {
                googleMapObject.current = map;
                googleMapsService.current = maps;
                setGoogleApiAvailable(true);
              }}
              defaultCenter={coordinates}
              center={mapCenter}
              defaultZoom={mapZoom}
              resetBoundsOnResize={true}
            >
              {openedDeal && !isMobileOnly && (
                <ListingCompact
                  hotel={openedDeal}
                  clickImage={() => openGallery(openedDeal)}
                  lat={openedDeal.coordinates.latitude}
                  lng={openedDeal.coordinates.longitude}
                  nights={nights}
                  showClose={true}
                  clickClose={() => {
                    setOpenedDeal(null);
                    setActiveHotel(null);
                  }}
                  onBoundingClientRectChange={(childRect) => {
                    if (
                      isOutsideMap(
                        childRect,
                        mapContainerRef.current.getBoundingClientRect()
                      )
                    )
                      setMapCenter([
                        openedDeal.coordinates.latitude,
                        openedDeal.coordinates.longitude,
                      ]);
                  }}
                  onTouchStartCapture={() => setMapDraggable(false)}
                  onTouchEndCapture={() => setMapDraggable(true)}
                />
              )}
              {hotels
                .filter((hotel) => hotel.enabled)
                .map((hotel, index) => (
                  <MapMarker
                    key={index}
                    lat={hotel.coordinates.latitude}
                    lng={hotel.coordinates.longitude}
                    rate={hotel.rates[0].pricePerNight}
                    onClick={() => {
                      setOpenedDeal(hotel);
                      setActiveHotel(hotel.id);
                    }}
                    active={activeHotel === hotel.id}
                  />
                ))}
            </GoogleMapReact>
          </div>
        </div>
        {!isMobile && (
          <FullScreenModal
            bottomStickyPosition={
              <CheapestRateSingleLine
                buttonHover={false}
                rate={galleryHotel?.rates[0]}
              />
            }
            contents={
              <ImageGallery
                partner={galleryHotel?.imageGalleryPartnerDetails.partner}
                partnerId={galleryHotel?.imageGalleryPartnerDetails.partnerId}
              />
            }
            enabled={displayGallery && !isMobile}
            middlePosition={galleryHotel?.name}
            middlePositionIcon={<IconCamera width={24} height={24} />}
            onClickScrim={() => setDisplayGallery(false)}
            onClickLastPosition={() => setDisplayGallery(false)}
          />
        )}
      </div>
      <MobileToggleButtons
        enabled={resultsScrollDirection === "down" || mapOpen}
        filtersOpen={filtersOpen}
        mapOpen={mapOpen}
        onFiltersClick={() => setMobileFiltersOpen(!mobileFiltersOpen)}
        onSortByClick={() => setSortByOpen(!sortByOpen)}
        onMapClick={() => setMapOpen(!mapOpen)}
        onCurrencyClick={() => setCurrencyOpen(!currencyOpen)}
        sticky={true}
      />
      {openedDeal && isMobileOnly && (
        <ListingCompact
          display={openedDeal && isMobileOnly}
          hotel={openedDeal}
          nights={nights}
          clickImage={() => openGallery(openedDeal)}
          showClose={true}
          fixedToBottom={true}
          clickClose={() => {
            setOpenedDeal(null);
            setActiveHotel(null);
          }}
        />
      )}
    </>
  );
};
