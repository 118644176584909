import { MultiCheckboxFilter } from "../Elements/Fields/MultiCheckboxFilter";
import config from "../../config/general.json";
import FilterOption from "../../models/view/Filter/FilterOption";
export const RatingsFilter = ({
  rating,
  onRatingChange,
  onStarChange,
  star,
}) => {
  const ratingsFilterOptions = config.ratingsFilterOptions.map((option) => {
    return new FilterOption(
      option.label,
      option.value,
      option.stars,
      option.color,
      rating === option.value
    );
  });
  
  const starsFilters = config.starsFilterOptions.map((option) => {
    return new FilterOption(
      option.label,
      option.value,
      option.stars,
      option.color,
      star.includes(option.value)
    );
  });
  

  return (
    <div className="w-full text-navyBlue">
      <MultiCheckboxFilter
        label={"Guest Rating"}
        options={ratingsFilterOptions}
        selectionChanged={(rating) => onRatingChange(rating)}
        displayValues={true}
      />
      <MultiCheckboxFilter
        label={"Hotel Rating"}
        options={starsFilters}
        selectionChanged={onStarChange}
        displayValues={false}
      />
    </div>
  );
};
