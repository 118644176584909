import haversine from "haversine-distance";

export default class SearchUtility {
  static getDistanceBetweenTwoPoints(cord1, cord2) {
    const offset = 0.25;
    const maxRadius = 50000;
    const distanceInMeters = haversine(cord1, cord2) * offset;
    return distanceInMeters < maxRadius ? distanceInMeters : maxRadius;
  }

  static calculateMapRadius = (bounds) => {
    const southWestCords = {
      latitude: bounds?.getSouthWest().lat(),
      longitude: bounds?.getSouthWest().lng(),
    };
    const northEastCords = {
      latitude: bounds?.getNorthEast().lat(),
      longitude: bounds?.getNorthEast().lng(),
    };
    return this.getDistanceBetweenTwoPoints(southWestCords, northEastCords);
  };
}
