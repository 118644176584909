import { BadgeRating } from "../Badge/BadgeRating";
import { BadgeType } from "../Badge/BadgeType";

export const CardImageInnerTop = ({
  classification = 1,
  compact = false,
  reviewScore = 0.0,
  typeName = "",
}) => {
  return (
    <div
      className={`${
        compact ? "p-3 pt-4" : "p-5"
      } pb-0 z-20 text-white h-auto w-full items-start justify-between flex`}
    >
      {!compact && typeName !== "" && <BadgeType label={typeName} type={classification} />}
      {reviewScore > 0 && (
        <div className="ml-auto">
          <BadgeRating reviewScore={reviewScore} />
        </div>
      )}
    </div>
  );
};
