import { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "../../utils/onClickOutside";
import { ButtonSecondary } from "../Elements/Button/ButtonSecondary";
import { SingleCheckboxFilter } from "../Elements/Fields/SingleCheckboxFilter";
import { IconDownArrowRotating } from "../Elements/Icon/IconDownArrowRotating";

const options = [
  { name: "AUD", value: "AUD" },
  { name: "USD", value: "USD" },
];

export const CurrencySelector = ({
  displayAsChild = true,
  selected = "",
  selectionChanged = () => "",
}) => {
  const ref = useRef();
  const [displayDropdown, setDisplayDropdown] = useState(false);
  useOnClickOutside(ref, () => setDisplayDropdown(false));


  return (
    <div ref={ref}>
      {displayAsChild && (
        <ButtonSecondary
          icon={
            <IconDownArrowRotating
              rotate={displayDropdown}
              classes="text-peakahBlue"
              width={24}
              height={24}
              strokeWidth={2}
            />
          }
          iconLeft={true}
          label={selected}
          onClick={() => setDisplayDropdown(!displayDropdown)}
        />
      )}
      {!displayAsChild && (
        <SingleCheckboxFilter
          options={options}
          selectionChanged={(selection) => selectionChanged(selection.value)}
          selected={selected}
        />
      )}
      {displayDropdown && (
        <div className="absolute z-30 right-0 mx-10 bg-white border border-blue-300 rounded-4xl shadow-xl xl:mt-0 w-56">
          <div className="z-30 flex">
            <div className="w-full text-navyBlue">
              <SingleCheckboxFilter
                options={options}
                selectionChanged={(selection) =>
                  selectionChanged(selection.value)
                }
                selected={selected}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
