import React from 'react';
import { CarouselControlButton } from './CarouselControlButton';

interface ICarouselControls {
  clicked: () => void;
  direction: 'right' | 'left';
  darkMode?: boolean;
}
export const CarouselControls: React.FC<ICarouselControls> = ({
  clicked,
  direction = 'right',
  darkMode = false,
}) => {
  return (
    <CarouselControlButton direction={direction} clicked={() => clicked()} darkMode={darkMode}/>
  );
};
