import React from "react";
import { IconDownArrowRotating } from "../../Elements/Icon/IconDownArrowRotating";
export const ButtonBasicSmall = ({
  displayDownArrow = false,
  children,
  label = "",
  open = false,
  position = "",
  smallText = true,
  toggle,
}) => {
  return (
    <div
      onClick={toggle}
      className={`${smallText ? 'text-xs' : 'font-medium'} sm:text-md text-blue flex ${position} space-x-1 items-center cursor-pointer sm:w-full`}
    >
      {children}
      <span className="flex-shrink-0 flex">{label}</span>
      {displayDownArrow && <IconDownArrowRotating rotate={open} />}
    </div>
  );
};
