export const TopBarSticky = ({
  onClickLeft = () => true,
  onClickRight = () => true,
  display = true,
  leftIcon,
  leftText = "",
  rightIcon,
  rightText = "",
}) => {
  return (
    <div className="w-full flex justify-center">
      <div
        className={`${
          display
            ? "translate-y-0 opacity-100 shadow-straight"
            : "-translate-y-14 opacity-0 shadow-none"
        } transform transition-all ease-in-out flex flex-col top-0 fixed xl:hidden bg-white text-blue-500 rounded-bl-4xl rounded-br-4xl w-full md:max-w-3xl px-5`}
      >
        <div className="justify-between items-center flex w-full py-3">
          <div
            onClick={onClickLeft}
            className="flex space-x-2 cursor-pointer w-full"
          >
            <div>{leftIcon}</div> <div className="line-clamp-1">{leftText}</div>
          </div>
          <div className="w-full flex items-center justify-end space-x-4 font-light">
            <div onClick={onClickRight}>{rightText}</div>
            {rightIcon && (
              <div
                onClick={onClickRight}
                className="cursor-pointer flex space-x-4 items-center"
              >
                <div className="w-px h-5 bg-blue-300"></div>
                {rightIcon}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
