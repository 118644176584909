let colors = {
	background_primary: '#fff',
	background_secondary: '#aaa',
	background_tertiary: '#efefef',
	background_footer: '#4a4a4a',
	foreground_primary: '#000',
	foreground_secondary: '#fff',
	foreground_tertiary: '#aaa',
	accent_primary: '#00FF00',
	accent_secondary: '#fcaf01',
	accent_tertiary: '#fd00fd',
	brand_primary: '#00ff6d',
	brand_primary_foreground: '#61B516',
	brand_primary_extra: '#00600a',
	brand_secondary: '#00ffeb',
	brand_secondary_foreground: '#236477',
	brand_secondary_extra: '#4646d2',
	action_primary: '#0000ff',
	spacer_primary: '#777',
	error: '#CC2222'
};

let sizes = {
	font_title: 60,
	font_subtitle: 24,
	font_important: 18,
	font_body: 14,
	font_small: 13,
	font_section_heading: 36,
	header_height: 100,
	search_width: 942,
	max_width: 1440,
	spacing_standard: 80,
	max_content_width: 800,
	layout_tablet: 1280,
	layout_tablet_portrait: 1024,
	layout_mobile: 600,

	mobile_font_title: 40,
	mobile_font_subtitle: 16,
	mobile_font_important: 12,
	mobile_font_section_heading: 24,
	mobile_header_height: 90,
	mobile_spacing_standard: 40
};

let responsive = {
	tablet: `@media (max-width: ${sizes.layout_tablet}px)`,
	tablet_portrait: `@media (max-width: ${sizes.layout_tablet_portrait}px)`,
	mobile: `@media (max-width: ${sizes.layout_mobile}px)`
}

let mixins = {
	brand_text: `
		background: linear-gradient(to right, ${colors.brand_primary} 0%, ${colors.brand_secondary} 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	`,
	main_font: `
		
	`,
	title_font: `
		font-size: ${sizes.font_title}px;
		${responsive.mobile} {
			font-size: ${sizes.mobile_font_title}px;
		}
	`,
	subtitle_font: `
		font-size: ${sizes.font_subtitle}px;
		${responsive.mobile} {
			font-size: ${sizes.mobile_font_subtitle}px;
		}
	`,
	important_font: `
		font-size: ${sizes.font_important}px;
		${responsive.mobile} {
			font-size: ${sizes.mobile_font_important}px;
		}
	`,
	section_heading_font: `
		font-size: ${sizes.font_section_heading}px;
		${responsive.mobile} {
			font-size: ${sizes.mobile_font_section_heading}px;
		}
	`
};


export const theme = {
	colors: colors,
	sizes: sizes,
	responsive: responsive,
	mixins: mixins
};