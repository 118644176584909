import { IconClose } from "../../Elements/Icon/IconClose";
import { IconCurrency } from "../../Elements/Icon/IconCurrency";
import { IconMap } from "../../Elements/Icon/IconMap";
import { IconSliders } from "../../Elements/Icon/IconSliders";
import { IconSorting } from "../../Elements/Icon/IconSorting";
import { ToggleButton } from "./ToggleButton";

export const MobileToggleButtons = ({
  enabled = false,
  onSortByClick,
  onFiltersClick,
  onMapClick,
  onCurrencyClick,
  filtersOpen = false,
  sortingOpen = false,
  sticky = true,
  mapOpen = false,
  currencyOpen = false,
}) => {
  const toggleOptions = [
    {
      icon: <IconCurrency width={18} height={18} />,
      label: "Currency",
      onClick: onCurrencyClick,
      enabled: currencyOpen,
    },
    {
      icon: <IconSorting width={18} height={18} />,
      label: "Sort By ",
      onClick: onSortByClick,
      enabled: sortingOpen,
    },
    {
      icon: <IconMap width={18} height={18} />,
      iconActive: <IconClose width={18} height={18} />,
      label: "View Map",
      labelActive: "Close Map",
      onClick: onMapClick,
      enabled: mapOpen,
    },
    {
      icon: <IconSliders width={18} height={18} />,
      label: "Filters",
      onClick: onFiltersClick,
      enabled: filtersOpen,
    },
  ];
  return (
    <div
      className={`${enabled ? "translate-y-0" : "translate-y-20 sm:translate-y-24"} ${
        sticky ? "fixed bottom-0 pb-8 sm:pb-10" : "block py-4 sm:py-5"
      } transform transition-transform ease-in-out xl:hidden w-full flex justify-between items-center z-20 px-3 xs:px-8`}
    >
      <div className={`rounded-4xl w-full sm:max-w-sm sm:mx-auto shadow-straight bg-white flex justify-between`}>
        {toggleOptions.map((toggle, index) => {
          const { enabled, icon, iconActive, label, labelActive } = toggle;
          return (
            <ToggleButton
              key={index}
              index={index}
              icon={enabled && iconActive ? iconActive : icon}
              label={enabled && labelActive ? labelActive : label}
              onClick={toggle.onClick}
              enabled={toggle.enabled}
            />
          );
        })}
      </div>
    </div>
  );
};
