import { IconChevronRight } from "../Icon/IconChevronRight";
import { IconMap } from "../Icon/IconMap";

export const CardFeaturedLocationPrimary = ({
  location = { name: "", description: "", image: "" },
  clicked = () => true,
}) => {
  // Change text for marketing material
  const marketingPlaceholder = location.site === "placeholder";
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7)), url(${location.image}`,
      }}
      className="bg-cover bg-center bg-lightGray flex justify-between rounded-4xl h-full md:p-4 lg:p-6 shadow-light cursor-pointer h-56"
      onClick={() => clicked()}
    >
      {!marketingPlaceholder && (
        <div className="flex space-x-2 space-y-40 place-items-end">
          <div className="flex place-items-center space-x-2 p-3 bg-transparentBlack rounded-4xl ">
            <IconMap />
            <span className="text-md xl:text-md text-white">
              {location.name}
            </span>
            <div className="text-white flex justify-end">
              <IconChevronRight classes="text-peakahGreen" />
            </div>
          </div>
        </div>
      )}
      {marketingPlaceholder && (
        <div className="flex flex-col items-left pl-0 xl:pl-4 pr-0 lg:pr-8 xl:pr-12 justify-center w-1/2 gap-4">
          <span className="text-xl text-wrap text-navyBlue font-medium">
            Free travel rewards for everyone
          </span>
          <div className="flex">
            <div className="p-2 px-5 bg-peakahGreen rounded-4xl ">
              <span className="text-md text-white font-medium">
                Enter Peaky Island
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
