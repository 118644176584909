export default class FilterOption {
  label: string;
  value: number | string;
  stars: number;
  color: string;
  enabled: boolean;
  constructor(label = '', value = 0, stars = 0, color = '', enabled = false) {
    this.label = label;
    this.value = value;
    this.stars = stars;
    this.color = color;
    this.enabled = enabled;
  }
}
