import { Stars } from "../../Listing/Stars";

export const CardImageInnerBottom = ({
  clickImage = () => true,
  compact = false,
  name = "",
  stars = 0.0,
}) => {
  return (
    <div
      onClick={clickImage}
      className={`${
        compact ? "p-3 items-end" : "p-5 items-start"
      } text-white pt-0 h-auto w-full justify-end flex flex-col z-10`}
    >
      {stars > 0 && <Stars stars={stars} />}
      {!compact && (
        <h2 className="lg:hidden text-xl font-medium leading-relaxed line-clamp-2">
          {name}
        </h2>
      )}
    </div>
  );
};
