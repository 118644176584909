export const BadgeType = ({ type = 1, label = "" }) => {
  return (
    <span
      className={`uppercase px-3 py-1 rounded-4xl text-xs font-semibold shadow-sm
      ${
        type === 1
          ? "bg-hotelLight text-peakahGreen"
          : "text-peakahBlue bg-alternateLight"
      }`}
    >
      {label}
    </span>
  );
};
