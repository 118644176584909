import IArticlePageResponse from './IArticlePageResponse';
import ArticlePage from './ArticlePage';

export default class ArticlePageResponse {
  public data: ArticlePage;

  constructor(
    public status: number,
    data: IArticlePageResponse,
    public error?: Error
  ) {
    this.data = new ArticlePage(
      data.id,
      data.title,
      data.heading,
      data.status,
      data.category,
      data.url,
      data.content,
      data.date_created,
      data.date_updated,
      data.user_updated,
      data.meta_description,
      data.meta_title,
      data.heroImageUrl,
      data.heroImageAlt,
      data.galleryImageUrl,
      data.galleryImageAlt,
    );
  }
}
