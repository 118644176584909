import { ButtonBasic } from "../Elements/Button/ButtonBasic";
import { IconClose } from "../Elements/Icon/IconClose";

export const TopDrawer = ({
  enabled = false,
  fullScreen = false,
  children,
  leftIcon,
  rightIcon = <IconClose darkMode={true} />,
  leftText = "",
  onClickLeft = () => true,
  onClickRight = () => true,
}) => (
  <div className="w-full flex justify-center">
    <div
      className={`
    ${
      enabled
        ? "translate-y-0 shadow-straight"
        : "-translate-y-full shadow-none"
    }
    ${fullScreen ? "top-0 mt-5 h-screen" : "top-0 h-auto"}
     xl:hidden transform transition-all ease-in-out duration-300 w-full fixed md:max-w-3xl bg-white rounded-4xl rounded-tl-none rounded-tr-none z-80`}
    >
      <div className="flex justify-between items-center sticky top-0 bg-white py-1 mx-5">
        <ButtonBasic
          fullWidth={false}
          align="left"
          icon={leftIcon}
          label={leftText}
          onClick={onClickLeft}
        />
        <ButtonBasic
          fullWidth={false}
          align="right"
          icon={rightIcon}
          label="Close"
          onClick={onClickRight}
        />
      </div>
      <div
        className={`${fullScreen ? "pb-20" : "pb-6"} p-2 px-6 text-blue-500`}
      >
        {children}
      </div>
    </div>
  </div>
);
