import React, {useState, useContext, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { GuestSelector } from '../GuestSelector';
import { MobileDatePicker } from "../DatePicker/MobileDatePicker"
import { DatePickerCheckIn } from "../DatePicker/DatePickerCheckIn"
import { DatePickerCheckOut } from "../DatePicker/DatePickerCheckOut"
import { Heading3 } from '../Elements/Heading/Heading3';
import { SearchContext } from '../../context/SearchContext/SearchContext';
import { today, endDateMin } from '../../utils/defaultDates'
import { ButtonPrimary } from '../Elements/Button/ButtonPrimary';
import { IconSearch } from '../Elements/Icon/IconSearch';
import { UrlContext } from '../../context/UrlContext/UrlContext';
import { SiteParams } from '../../config/constants/SiteParams';

export const SearchSidebar = (props) => {

	// Context
	const [searchContext, setSearchContext] = useContext(SearchContext);
	const [toSearchResults, setToSearchResults] = useState(false)
	const [urlContext, setUrlContext] = useContext(UrlContext);
	const [url, setUrl] = useState("");
	// Deconstruct Context
	const { startDate, endDate, adults, children, rooms } = searchContext
	const handleStartChange = (date) => {
		let newEndDateMin = new Date(date.getTime() + 1000 * 60 * 60 * 24)
		if (endDate <= date) {
			setSearchContext({...searchContext,endDate:newEndDateMin})
		}
		setSearchContext({...searchContext, endDate: endDate <= date ? newEndDateMin : endDate, startDate: date})
	};

	const updateEndDate = (date) => {
		setSearchContext({...searchContext,endDate: date})
	};

	const updateCount = (type,count) => {
		if (count < 0) return;
		setSearchContext({...searchContext,[type]:count})
	};
	
	useEffect( () => {
		if (urlContext !== "/" ) {
		  setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url)
			}
	}, [urlContext])

	if (toSearchResults) {
		return <Redirect push to={{ pathname: `${url}/search` }}  />
	}
		return <div className="sm:px-3 lg:px-2 xl:px-10 pt-2 mt-2 md:mt-0">
			<div className="bg-white rounded-md rounded-bl-none rounded-br-none p-4 sm:p-10 shadow-xl">
				<Heading3 classes='w-full leading-normal mb-2 md:mb-5 text-navyBlue text-base lg:text-xl'>
				<span>See all accommodation options for</span> <span className="text-peakahGreen">{props.name}</span>?
				</Heading3>
				<div className="w-full mb-2">
					<label className="text-peakahBlue text-xs">Guests</label>
					<GuestSelector adults={adults} children={children} rooms={rooms} updateCount={updateCount} />
				</div>
				<div className="w-full md:hidden">
						<MobileDatePicker
							displayLabelMobile={true}
							label="Dates"
							minDate={endDateMin}
							startDate={startDate}
							endDate={endDate}
							startDateChange={(date) => setSearchContext({...searchContext,startDate:date})}
							endDateChange={(date) => setSearchContext({...searchContext,endDate:date})}
							reset={() => setSearchContext({...searchContext,endDate:null})}
							darkMode={true}
						/>
					</div>
					<div className="hidden md:block">
						<DatePickerCheckIn
							label="Check in"
							minDate={today}
							startDate={startDate}
							endDate={endDate}
							selected={startDate}
							displayErrors={false}
							onChange={handleStartChange}
						/>
					</div>
					<div className="hidden md:block">
						<DatePickerCheckOut
							label="Check out"
							startDate={startDate}
							endDate={endDate}
							minDate={endDateMin}
							selected={startDate}
							displayErrors={false}
							onChange={updateEndDate}
						/>
					</div>
			</div>
			

			<div className="w-full">
				<div className="bg-veryLightBlue shadow-lg rounded-bl-md rounded-br-md w-full flex-col items-center md:items-end flex p-4 md:p-10 justify-between">
					<div className="w-full">
						<ButtonPrimary label="Search" icon={<IconSearch classes="text-white"  />} onClick={() => setToSearchResults(true)} />
					</div>
				</div>
			</div>
		</div>
	}




