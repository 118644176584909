import { useRef, createRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Elements/Logo/Logo";
import { Headline } from "./Headline";
import { TagLine } from "./TagLine";
import { MainNavigation } from "../Navigation/MainNavigation";
import Links from "../../config/links.json";
import { SearchBar } from "../SearchBar";
import { SearchBarMobile } from "../SearchBar/SearchBarMobile";
import { UrlContext } from "../../context/UrlContext/UrlContext";

export const HeaderMobile = ({
  classes = "",
  collapseSearchFieldsInitially = false,
  freeCancellationFilterEnabled = false,
  displayFreeCancellation = false,
  searchWithoutButton = true,
  heroBg = "",
  headline = "",
  subline = "",
  tagline = false,
  displayPartnerLogos = true,
  onSearch,
  toggleFreeCancellation,
  site = "",
}) => {
  const searchBarRef = useRef();
  const ref = createRef();
  const gradientImage = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 50%), url(${heroBg}`;
  const [urlContext, setUrlContext] = useContext(UrlContext);
  return (
    <div
      className={`bg-cover ${
        collapseSearchFieldsInitially ? "bg-top-30" : "bg-top-100"
      } px-3 sm:px-10 pt-8 lg:pt-16 bg-gradient-to-b from-peakahBlue to-white bg-opacity-25 bg-no-repeat ${classes}`}
      style={{
        backgroundImage: gradientImage,
      }}
      ref={ref}
    >
      <div className="max-w-xxl mx-auto">
        <div className="flex justify-center xl:justify-start">
          <div>
            <Link to={`${urlContext}`}>
              <Logo />
            </Link>
          </div>
          <div className="hidden xl:flex">
            <MainNavigation links={Links} />
          </div>
        </div>
        {headline && (
          <div className="hidden lg:block text-center max-w-screen-lg mx-auto lg:pt-10 xl:pt-16">
            <Headline classes="font-semibold text-white text-4xl xl:text-6xl">
              {headline}
            </Headline>
            {subline && (
              <Headline classes="font-semibold text-white leading-tight text-4xl xl:text-6xl mt-3">
                {subline}
              </Headline>
            )}
          </div>
        )}
        {tagline && (
          <div className="lg:py-6 xl:pb-28 text-white text-center">
            <TagLine classes="text-base font-normal">
              <div className="hidden lg:block lg:text-2xl xl:text-3xl">
                <span> {site === 'US' ? 'America': 'Australia'}'s largest accommodation platform</span>
              </div>
              <div className="flex lg:hidden w-full justify-center">
                <span className="text-xl font-light">
                  Compare everything from
                </span>
              </div>
              <div className="flex lg:hidden flex-row items-end w-full justify-center leading-8 text-2xl">
                <span className="font-semibold">
                  Holiday Homes
                  <span className="font-light">&nbsp;to&nbsp;</span>
                  Hotels
                </span>
              </div>
            </TagLine>
          </div>
        )}
      </div>
      <div
        className={`${
          collapseSearchFieldsInitially ? "sm:max-w-2xl" : "max-w-xxl"
        } pt-5 mx-auto`}
      >
        <div ref={searchBarRef}>
          <div className="hidden xl:flex flex-col bg-white rounded-4xl p-10 shadow-xl">
            <SearchBar
              searchFocused={true}
              displayPartnerLogos={displayPartnerLogos}
              onSearch={onSearch}
            />
          </div>
          <SearchBarMobile
            freeCancellationFilterEnabled={freeCancellationFilterEnabled}
            displayAllInputsOnLoad={!collapseSearchFieldsInitially}
            displayFreeCancellation={displayFreeCancellation}
            searchWithoutButton={searchWithoutButton}
            searchFocused={true}
            onSearch={onSearch}
            displayPartnerLogos={displayPartnerLogos}
            toggleFreeCancellation={toggleFreeCancellation}
          />
        </div>
      </div>
    </div>
  );
};