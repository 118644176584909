import React from 'react';
export const SearchMessage = (props) => {
    return (
        <div>
            {props.text && 
                <div className="max-w-xxl p-5 m-auto flex items-center justify-center text-sm md:text-base">
                    <div className="w-full text-center">
                        <div className="text-peakahBlue">{props.text}</div>
                    </div>
                </div>
            }
        </div>
    )
}