import React from "react";
import { CardImageContainer } from "./CardImageContainer";
import { CardImageInner } from "./CardImageInner";

export const CardImage = (props) => {
  return (
    <CardImageContainer
      id={props.id}
      compact={props.compact}
      source={props.image}
      loaded={props.loaded}
      onClick={props.clickImage}
      partner={props.partner}
      partnerId={props.partnerId}
    >
      <CardImageInner {...props} />
    </CardImageContainer>
  );
};
