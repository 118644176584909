import React from "react";
import FilterOption from "../../../../models/view/Filter/FilterOption";
import { Label } from "../../Label/Label";
import { SelectionOption } from "./SelectionOption";
export const MultiCheckboxFilter = ({
  displayValues = false,
  label = "",
  options = [FilterOption],
  selectionChanged = () => true,
}) => {
  return (
    <div>
      <Label title={label} />
      <ul className="space-y-2 flex flex-col">
        {options.map((option, optionId) => {
          return (
            <SelectionOption
              key={optionId}
              optionId={optionId}
              name={option.label}
              value={option.value}
              stars={option.stars}
              color={option.color}
              handleCheckboxChange={(change) => selectionChanged(change)}
              checked={option.enabled}
              displayValues={displayValues}
            />
          );
        })}
      </ul>
    </div>
  );
};
