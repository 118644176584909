import { SelectionOption } from "./SelectionOption";
export const SingleCheckboxFilter = ({
  options = [{ name: "", value: "" }],
  selected = "",
  selectionChanged = () => "",
}) => (
  <ul>
    {options.map((option, optionId) => (
      <SelectionOption
        first={optionId === 0}
        last={optionId === options.length - 1}
        key={optionId}
        optionId={optionId}
        name={option.name}
        value={option.value}
        stars={option.stars}
        handleCheckboxChange={(selection) => selectionChanged(selection)}
        selected={selected}
      />
    ))}
  </ul>
);
