import Coordinates from '../../availability/Coordinates';
import IContentBlock from './IContentBlock';
import IFeaturedLocation from './IFeaturedLocation';

export default class LandingPage {
  public destination: Coordinates;

  constructor(
    public id: number,
    public status: string,
    public sort: null,
    public date_created: Date,
    public date_updated: Date,
    public campaign_category: string,
    public url: string,
    public name: string,
    public heading: string,
    public subHeading: string,
    public heroImageUrl: string,
    public heroImageAlt: string,
    public carouselImage: string,
    public carouselImageUrl: string,
    public carouselImageAlt: string,
    public introduction: string | null,
    public featuredLocations: IFeaturedLocation[],
    public content_blocks: IContentBlock[],
    public meta_description: string,
    public meta_title: string,
    destinationCoordinates?: {
      type: string;
      coordinates: number[];
    },
    public destinationSearchRadius?: number
  ) {
    this.destination = new Coordinates(destinationCoordinates?.coordinates) ?? null
  }
}
