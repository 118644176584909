import React from "react";
import PartnerRate from "../../../models/availability/PartnerRate";
import { MorePrices } from "./MorePrices";
export const MorePricesDrawer = ({
  raise = false,
  display = false,
  rates = [PartnerRate],
  compact = false,
  nights = 0,
}) => {
  return (
    <div
      className={`${raise ? "z-20 " : "z-0"} ${
        display
          ? "transform -translate-y-10"
          : !display && rates.length === 2
          ? "transform -translate-y-36"
          : "transform -translate-y-48"
      }
        absolute right-0 motion-safe:transition-transform duration-500 ease-in-out w-full lg:w-auto`}
    >
      <div
        className={`${
          display ? "shadow-straight" : "shadow-none"
        } transition-shadow ease-in-out duration-200 mx-4 lg:mx-0 p-5 pt-12 lg:pb-0 bg-white rounded-br-4xl rounded-bl-4xl`}
      >
        <MorePrices
          display={display}
          compact={compact}
          rates={rates}
          nights={nights}
        />
      </div>
    </div>
  );
};
