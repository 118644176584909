import React from 'react';
import { useLottie, Lottie } from "react-lottie-hook";
import animationData from "../../../config/animations/loading-animation.json";
 
export const AnimationBus = (props) => {
  const [lottieRef] = useLottie({
    renderer: "svg",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      progressiveLoad: false,
    },
    animationData,
  });
  return <Lottie lottieRef={lottieRef} width="auto" height="auto" className="w-full" />;
}