import React from "react";
import { Icon } from "./Icon";
export const IconWedge = (props) => (
  <Icon {...props}>
    <path
      d="M5.3,5.2L0,0h19l-5.3,5.2
		C12.6,6.3,11.1,7,9.5,7C7.9,7,6.4,6.3,5.3,5.2z"
    />
  </Icon>
);
