import React, { useContext, useEffect, useRef, useState } from "react";
import ArticleGalleryCard from "../../models/api/cms/ArticleGalleryCard";
import { Card } from "./Card";
import { ButtonPrimary } from "../Elements/Button/ButtonPrimary";
import { useHistory } from "react-router-dom";
import { UrlContext } from "../../context/UrlContext/UrlContext";
import { SiteParams } from "../../config/constants/SiteParams";

interface ICarousel {
  heading: string;
  images: ArticleGalleryCard[];
  exclude?: string;
}

export const ArticleCarousel: React.FC<ICarousel> = ({
  heading,
  images,
  exclude,
}) => {
  const [contentImages, setContentImages] = useState<ArticleGalleryCard[]>();
  const [urlContext, setUrlContext] = useContext(UrlContext);
  const [url, setUrl] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (urlContext !== "/") {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url);
    }
  }, [urlContext]);

  useEffect(() => {
    if (!images) return undefined;
    const slides = images.filter((image) => image.url !== exclude);
    setTimeout(() => {
      setContentImages(slides);
    }, 1000);
    return () => {
      setContentImages(undefined);
    };
  }, [exclude, images]);

  return (
    <div className="relative">
      <div
        className="overflow-x-scroll scrolling-touch carousel"
      >
        <div className="text-xl md:text-2xl text-blue-500 font-medium pb-8 text-center md:px-10 pb-5 pt-5 lg:py-10 lg:px-0 leading-normal">
          {heading}
        </div>
        <div className="flex flex-col gap-4 h-96">
          {contentImages &&
            contentImages
              .slice(0, 6)
              .map((item, index) => (
                <Card
                  alt={item.galleryImageAlt}
                  url={`/articles/${item.url}`}
                  image={item.galleryImageUrl}
                  name={item.heading}
                  published={item.date_created}
                  key={index}
                  carousel={true}
                />
              ))}
          <div className="flex justify-center">
            <ButtonPrimary
              onClick={() => history.push(`${url}/articles`)}
              fullWidth={false}
              label={"View Blog Articles"}
              classes="h-20 font-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
