import { ReactNode } from 'react';

interface IHeading3 {
  classes: string;
  children: ReactNode;
}
export const Heading3: React.FC<IHeading3> = ({ classes = '', children }) => {
  return (
    <h3 className={`${classes} text-2xl lg:text-4xl font-semibold`}>
      {children}
    </h3>
  );
};
