import React from 'react'
import { IconMap } from '../../Elements/Icon/IconMap'

export const ListingDistance = (props) => {
    const { destination="", distance=0 } = props
    return (
        <div className="items-center space-x-3 flex">
            <IconMap width={20} />
            <span className="text-peakahBlue text-xs sm:text-sm">{distance} to {destination}</span>
        </div>
    )
}