import React from "react";
import { LoadingSpinner } from "../Elements/Icon/LoadingSpinner";
import { ImageContainer } from "./ImageContainer";
import { useState } from "react";
import { useEffect } from "react";
import SearchImagesService from "../../services/SearchApi/SearchImagesService";
export const ImageGalleryMobile = ({ partner = "", partnerId = "" }) => {
  const [imagesArray, setImagesArray] = useState();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [message, setMessage] = useState(null);

  const getImages = async () => {
    const imagesResponse = await SearchImagesService.getImages(partner, partnerId);
    try {
      if (imagesResponse.images.length > 1 && imagesResponse.success) {
        setImagesArray(imagesResponse.images);
        setImagesLoaded(true);
      } else {
        throw new Error("Sorry, no more images to display");
      }
    } catch (error) {
      setMessage("Sorry, no more images to display");
      setImagesLoaded(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (partner !== "" && partnerId !== "") getImages();
    return () => {
      setImagesArray([]);
      setImagesLoaded(false);
      setMessage(null);
    };
  }, [partner, partnerId]);

  return (
    <>
      <div className="h-full flex flex-col space-y-5 px-5 pt-5">
        {!imagesLoaded && (
          <div className="w-full h-screen flex flex-col items-center justify-center">
            {message}
            <LoadingSpinner loading={!imagesLoaded} />
          </div>
        )}
        {imagesLoaded &&
          imagesArray.map((image, index) => (
            <ImageContainer image={image} key={index} />
          ))}
      </div>
    </>
  );
};
