import { CardImageInnerBottom } from "./CardImageInnerBottom";
import { CardImageInnerTop } from "./CardImageInnerTop";

export const CardImageInner = ({
  classification = 1,
  clickClose = () => true,
  compact = false,
  maxGuests = 0,
  name = "",
  reviewScore = 0.0,
  roomCount = 0,
  showClose = false,
  stars = 0.0,
  typeName = "",
}) => (
  <div
    className={`${
      compact ? "h-full w-40" : "h-68 xl:h-60 w-full"
    } flex flex-col justify-between transition-opacity duration-500 ease-in-out`}
  >
    <CardImageInnerTop
      classification={classification}
      clickClose={clickClose}
      reviewScore={reviewScore}
      typeName={typeName}
      showClose={showClose}
      compact={compact}
    />
    <CardImageInnerBottom
      maxGuests={maxGuests}
      name={name}
      roomCount={roomCount}
      stars={stars}
      compact={compact}
    />
  </div>
);
