import { ButtonCircleArrow } from "../Elements/Button/ButtonCircleArrow";

export const GuestSelectorInputRow = ({
  title,
  count,
  selector,
  updateCount = () => 1,
  darkMode=false,
}) => {
  return (
    <div className="z-30 flex">
      <div className={`w-1/2 ${darkMode ? "text-darkModeLightGray" : "text-navyBlue"} text-md font-semibold flex items-center`}>
        {title}
      </div>
      <div className="w-1/2 flex justify-between items-center">
        <ButtonCircleArrow
          direction="down"
          onClick={() => updateCount(selector, count - 1)}
        />
        <span className={`${darkMode ? "text-darkModeLightGray" : "text-navyBlue"} text-center`}>{count}</span>
        <ButtonCircleArrow
          direction="up"
          onClick={() => updateCount(selector, count + 1)}
        />
      </div>
    </div>
  );
};
