import styled, { keyframes } from 'styled-components';
import { lighten }from 'polished';
import { theme } from '../../themes/current';

export const StyledContactForm = styled.div`
	background-color: ${theme.colors.background_tertiary};
	padding: 20px;
	border-radius: 10px;
`;

export const StyledInput = styled.input`
	${theme.mixins.main_font}
	font-size: ${theme.sizes.font_body}px;
	border: none;
	border-radius: 5px;
	padding: 10px;
	margin: 5px 10px;
	background-color: ${theme.colors.background_primary};
	width: calc(${props=> props.fullwidth ? 100 : 50 }% - 20px);
	box-sizing: border-box;
	&:focus {
		outline: none;
	}
	${theme.responsive.mobile} {
		margin: 5px 0;
		width: 100%;
		box-sizing: border-box;
	}
`;

export const StyledSubmit = styled(StyledInput)`
	background-color: ${theme.colors.action_primary};
	color: ${theme.colors.foreground_secondary};
	cursor: pointer;
	&:hover {
		background-color: ${lighten(0.2, theme.colors.action_primary)};
	}
`;

export const StyledError = styled.p`
	color: ${theme.colors.error};
`;


export const StyledLoading = styled.div`
	text-align: center;
	&:after {
		content: '';

	}
`;
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const StyledLoaderCircle = styled.div`
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	&:before, &:after {
		content: '';
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid ${theme.colors.brand_primary_foreground};
		border-radius: 50%;
		animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${theme.colors.brand_primary_foreground} transparent transparent transparent;
		animation-delay: -0.45s;
	}
`;
export const StyledLoaderCircleInner = styled.div`
	&:before, &:after {
		content: '';
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid ${theme.colors.brand_secondary_foreground};
		border-radius: 50%;
		animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${theme.colors.brand_secondary_foreground} transparent transparent transparent;
		animation-delay: -0.3s;
	}
	&:after {
		animation-delay: -0.15s;
	}
`;