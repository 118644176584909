class FeaturedLocation {
  image: string;
  name: string;
  site: string;
  description: string;
  order: number;
  latitude: number;
  longitude: number;
  primary: boolean;
  radius: number;
  constructor({
    name = '',
    site= '',
    description = '',
    image = '',
    latitude = 0.0,
    longitude = 0.0,
    order = 0,
    primary = false,
    radius = 5000,
  }) {
    this.image = image;
    this.name = name;
    this.site = site;
    this.description = description;
    this.order = order;
    this.latitude = latitude;
    this.longitude = longitude;
    this.primary = primary;
    this.radius = radius;
  }
}

export default FeaturedLocation;
