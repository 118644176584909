import { Link } from "react-router-dom";
import { Heading3 } from "../Elements/Heading/Heading3";
import { IconChevronRight } from "../Elements/Icon/IconChevronRight";
import { IconCalendar } from "../Elements/Icon/IconCalendar";
import { isMobile, isTablet } from "react-device-detect";

interface ICard {
  alt: string;
  image: string;
  name: string;
  url: string;
  published: Date;
  carousel: Boolean;
}

export const Card: React.FC<ICard> = ({
  alt = "",
  image = "",
  name = "",
  url = "",
  published = new Date(),
  carousel = false,
}) => {
  const publishedDate = new Date(published);
  const stringDate = publishedDate.toLocaleString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
  });

  return (
    <>
      <div
        className={`flex flex-row ${
          carousel ? "bg-white rounded-2xl p-3 md:p-5 md:mx-5 md:px-5" : ""
        }`}
      >
        <Link to={url}>
          <div
            className={`${
              carousel
                ? "h-24 w-24 rounded-2xl "
                : "h-48 lg:h-60 w-48 lg:w-60 rounded-4xl items-end"
            } ml-0 mr-5 bg-gray-400 flex bg-cover py-6 px-4`}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7)), url(${image}`,
            }}
            role="img"
            aria-label={alt}
          ></div>
        </Link>
        <div className="relative w-full my-2">
          <div className="absolute left-0 top-0">
            <div
              className={`flex gap-2 ${
                carousel ? "text-peakahGreen text-sm md:text-base" : "text-sm md:text-md lg:text-lg "
              }`}
            >
              <IconCalendar classes={`${carousel? "h-5 md:h-7 w-5 md:w-6" : "text-peakahGreen"} `}/>{" "}
              {isMobile || isTablet
                ? stringDate.split(", ").slice(1).join(", ")
                : stringDate}
            </div>

            {!carousel && (
              <Heading3 classes="leading-normal text-navyBlue text-base text-lg md:text-md lg:text-2xl sm:pt-5">
                {name}
              </Heading3>
            )}

            {carousel && (
              <div className="text-xs sm:text-sm md:text-base md:text-sm text-blue-500 font-medium leading-normal pt-2 xl:pt-5">
                {name}
              </div>
            )}
          </div>

          {!carousel && (
            <div className="absolute bottom-0">
              <Link
                className="flex text-black text-lg md:text-md lg:text-2xl font-medium space-x-3 items-center"
                to={url}
              >
                Read More
                <IconChevronRight
                  classes="text-black"
                  height={22}
                  width={22}
                  strokeWidth={2}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
