import ImageCallout from "../Elements/Callout/ImageCallout";
import { TagLine } from "../Header/TagLine";
import partners from "../../config/partners.json";

interface IPartnerships {
  imageUrl: string;
  description: string;
}

const Partnerships: React.FC<IPartnerships> = ({ imageUrl, description }) => {
  const gradientImage = `linear-gradient(rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.3) 50%), url(${imageUrl}`;
  return (
    <div
      className="bg-cover bg-bottom bg-center bg-no-repeat w-full lg:h-100 flex place-items-center"
      style={{
        backgroundImage: gradientImage,
      }}
    >
      <div className="px-5 sm:px-10 xl:px-0 max-w-xxl mx-auto mt-10 lg:mt-0 mb-20 lg:mb-0">
        <div className="flex flex-col sm:px-20 md:justify-between lg:flex-row items-center">
          <div className="flex flex-col lg:w-1/2 lg:mr-10">
            <p className="text-5xl sm:my-5">
              <span className="text-black font-medium">
                {"Current & Future "}
              </span>
              <span className="text-peakahBlue font-medium">{"Brand "}</span>
              <span className="text-peakahGreen font-medium">
                {"Partnerships"}
              </span>
            </p>
            <TagLine classes="text-peakahBlue font-normal leading-6 lg:leading-8 lg:text-xl sm:mb-5">
              {description}
            </TagLine>
          </div>
          <div className="flex lg:w-1/2 grid md:grid-cols-2 items-center gap-5 ">
            {partners
              .filter((partner) => partner.web3)
              .map((partner) => (
                <ImageCallout
                  heading={partner.reference}
                  imageURL={partner.logoLarge}
                  key={partner.reference}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnerships;
