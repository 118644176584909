import { MultiCheckboxFilter } from "../Elements/Fields/MultiCheckboxFilter";
import ListingType from "../../models/availability/ListingType";
import FilterOption from "../../models/view/Filter/FilterOption";
export const AccommodationTypeFilter = ({
  listingTypes = [ListingType],
  listingTypesFromContext,
  onTypeChange = () => ListingType,
}) => {
  if (!listingTypes) return null;
  const filterOptions = listingTypes?.map((listingType) => {
    const optionFromContext = listingTypesFromContext?.find(
      (type) => type.id === listingType.id
    );
    return new FilterOption(
      listingType.label,
      listingType.id,
      0,
      "",
      optionFromContext ? optionFromContext.enabled : false
    );
  });
  const handleTypeChange = (checked = false, value = 0) => {
    const listingTypeChanged = listingTypes.find(
      (listingType) => listingType.id === +value
    );
    listingTypeChanged.enabled = checked;
    return onTypeChange(listingTypeChanged);
  };
  return (
    <div className="w-full text-navyBlue">
      <MultiCheckboxFilter
        label={"Accommodation Type"}
        options={filterOptions}
        selectionChanged={({ checked = false, value = 0 }) =>
          handleTypeChange(checked, value)
        }
        displayValues={false}
      />
    </div>
  );
};
