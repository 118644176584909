export const ButtonSecondary = ({
  fullWidth = true,
  label = "",
  onClick = () => true,
  icon = null,
  iconLeft = false
}) => (
  <button
    className={`${
      fullWidth ? "w-full" : "w-auto"
    } font-medium border border-blue-400 py-2 px-4 rounded-4xl`}
    label={label}
    onClick={onClick}
  >
    <span className="text-base font-semibold flex justify-center items-center text-blue-400 space-x-2">
      {iconLeft && icon}
      <span>{label}</span>
      {!iconLeft && icon}
    </span>
  </button>
);
