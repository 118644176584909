import React, {useState} from 'react'
import { IconInfo } from "../Elements/Icon/IconInfo"
import { ToolTipRounded } from "../Elements/ToolTip/ToolTipRounded"

export const ListingToolTip = (props) => {
    const [displayToolTip, setDisplayToolTip] = useState(false);
    return (
        <div className="relative">
            <div className={`${displayToolTip ? 'absolute top-0 transform -translate-y-13 opacity-100 mb-2' : 'hidden translate-y-0 opacity-0'} transition-all ease-in-out duration-200 z-60 text-center w-42 -ml-24 sm:-ml-20 cursor-pointer`}>
                <ToolTipRounded message={props.message} />
            </div>
            <div className="cursor-pointer hover:opacity-75 transition-opacity ease-in-out" onMouseEnter={() => setDisplayToolTip(true) } onMouseLeave={() => setDisplayToolTip(false) } >
                <IconInfo color="peakah-blue" width={17} height={17} />
            </div>
        </div>
    )
}
