import { useEffect, useState } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { Label } from "../Elements/Label/Label";
import { LabelSmall } from "../Elements/Label/LabelSmall";

export const PriceSlider = ({
  price = 0,
  priceMin = 1,
  priceMax = 200,
  priceChange = () => 0,
}) => {
  const [sliderValue, setSliderValue] = useState(1);
  const [sliderMax, setSliderMax] = useState(200);
  const [sliderMin, setSliderMin] = useState(1);
  const [userHasChanged, setUserHasChanged] = useState(false)
  
  const handleChange = (value) => {
    priceChange(value)
    setUserHasChanged(true)
  }

  useEffect(() => {
    setSliderMax(priceMax);
    setSliderMin(priceMin);
  }, [priceMax, priceMin]);

  useEffect(() => {
    !userHasChanged && setSliderValue(sliderMax)
  }, [price, userHasChanged, sliderMax])
  
  return (
    <>
      <Label title="Pricing" />
      <div className="flex space-x-1">
        <LabelSmall hiddenMobile={false} title="Max Per Night AUD" />
        <span className="text-blue-600 text-xs">
          ${" "}
          {sliderValue > 1
            ? formatPrice(sliderValue)
            : formatPrice(sliderMax)}
        </span>
      </div>
      <div className="mt-4">
        <input
          value={sliderValue > 1 ? sliderValue : sliderMax}
          onChange={(event) => setSliderValue(parseInt(event.target.value))}
          onMouseUp={(event) => handleChange(event.target.value)}
          onTouchEnd={(event) => handleChange(event.target.value)}
          min={sliderMin}
          max={sliderMax}
          type="range"
        />
      </div>
    </>
  );
};
