import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Heading3 } from '../Elements/Heading/Heading3';
import { Waypoint } from 'react-waypoint';
import { isMobile } from 'react-device-detect';
import { ButtonPrimary } from '../Elements/Button/ButtonPrimary';
import { SearchContext } from '../../context/SearchContext/SearchContext';
import { twoWeeks, twoWeeksTomorrow } from '../../utils/defaultDates';
import { UrlContext } from '../../context/UrlContext/UrlContext';
import { SiteParams } from '../../config/constants/SiteParams';

const FeaturedLocationBlock = React.forwardRef((props, ref) => {
  // Context
  const [searchContext, setSearchContext] = useContext(SearchContext);
  const [urlContext, setUrlContext] = useContext(UrlContext);
  // State
  const [toSearchResults, setToSearchResults] = useState(false);
  const [url, setUrl] = useState("");
  // Router
  const history = useHistory();

  useEffect( () => {
    if (urlContext !== "/" ) {
      setUrl(SiteParams.find((obj) => obj.url === urlContext)?.url || url)
		}
	}, [urlContext])

  useEffect(() => {
    const search = () => history.push({ pathname: `${url}/search` });
    if (toSearchResults) search();
    return () => setToSearchResults(false);
  }, [toSearchResults, history]);

  const searchLocation = (location) => {
    const { latitude, longitude, radius, name } = location;
    setSearchContext({
      ...searchContext,
      coordinates: { lat: latitude, lng: longitude },
      radius: radius,
      destination: name,
      startDate: twoWeeks,
      endDate: twoWeeksTomorrow,
    });
    setToSearchResults(true);
  };

  const ctaLabel = (name) => {
    if (name?.length <= 9) return `Discover stays in ${name}`;
    if (name?.length >= 9 && name.length < 19) return `Stays in ${name}`;
    return props.name;
  };

  return (
    <div>
      <Heading3 classes="leading-normal my-5 sm:my-10 text-sm text-navyBlue lg:text-2xl cursor-pointer">
        <span onClick={() => searchLocation(props.location)}>
          {props.index + 1}. {props.name}
        </span>
      </Heading3>
      <img
        onClick={() => searchLocation(props.location)}
        src={props.imageUrl}
        alt={props.name + ' image'}
        className="rounded shadow mb-5 cursor-pointer"
      />
      <div className="text-sm text-navyBlue leading-neat mb-5 sm:mb-10">
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
        <div className="lg:hidden pb-2">
          <ButtonPrimary
            onClick={() => searchLocation(props.location)}
            label={ctaLabel(props.name)}
          />
        </div>
      </div>
    </div>
  );
});

export const FeaturedLocation = (props) => {
  return (
    <Waypoint
      onEnter={() => props.locationChange(props.index)}
      bottomOffset={isMobile ? 200 : 800}
    >
      <div ref={props.innerRef}>
        <FeaturedLocationBlock
          index={props.index}
          name={props.location.name}
          imageUrl={props.imageUrl}
          description={props.location.description}
          location={props.location}
        />
      </div>
    </Waypoint>
  );
};
