import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../utils/onClickOutside";
import { IconDownArrowRotating } from "../Elements/Icon/IconDownArrowRotating";
import { IconUsers } from "../Elements/Icon/IconUsers";
import { GuestSelectorInputRow } from "./GuestSelectorInputRow";

export const GuestSelector = ({
  adults,
  children,
  rooms,
  updateCount = () => 1,
  darkMode = false,
}) => {
  const container = useRef();
  const [open, setOpen] = useState(false);
  useOnClickOutside(container, () => setOpen(false));

  const toggle = () => {
    setOpen(!open);
  };

  const labelFromState = (adults = 0, children = 0, rooms = 0) => {
    const stringsFromState = {
      adults: {
        label: `${adults} Adult${adults > 1 ? "s" : ""}`,
        value: adults,
      },
      children: {
        label: ` ${children} ${children > 1 ? "children" : "child"}`,
        value: children,
      },
      rooms: {
        label: ` ${rooms} Room${rooms > 1 ? "s" : ""}`,
        value: rooms,
      },
    };

    return Object.keys(stringsFromState)
      .map((key, index) => {
        if (stringsFromState[key].value > 0) return stringsFromState[key].label;
        return false;
      })
      .filter((string) => string);
  };

  return (
    <div className="relative cursor-pointer" ref={container}>
      <div className="relative mt-2.5 md:mt-2" onClick={toggle}>
        <div className="z-20 absolute inset-y-0 left-0 w-13 flex justify-center items-center pointer-events-none">
          <IconUsers />
        </div>
        <div className="z-20 absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none md:hidden lg:flex">
          <IconDownArrowRotating
            classes={`${darkMode ? "text-peakahGreen" : "text-peakahBlue"}`}
            strokeWidth={2}
            width={24}
            height={24}
            rotate={open}
          />
        </div>
        <input
          className={`${
            open
              ? "rounded-br-none rounded-bl-none border-b-0"
              : "bg-veryLightBlue"
          } ${
            darkMode
              ? "bg-darkModeGray text-darkModeLightGray"
              : "bg-veryLightBlue outline-none text-peakahBlue placeholder-peakahBlue"
          } rounded-4xl h-10 overflow-hidden flex items-center text-sm pl-12 outline-none truncate ... cursor-pointer w-full`}
          readOnly={true}
          value={labelFromState(adults, children, rooms)}
        />
      </div>
      {open && (
        <div
          className={`absolute z-30 ${
            darkMode ? "bg-darkModeGray" : "bg-white border-r border-l border-b border-blue-300"
          } flex flex-col space-y-2 w-full shadow-xl px-5 pb-5 rounded-br-4xl rounded-bl-4xl`}
        >
          <GuestSelectorInputRow
            title="Adults"
            count={adults}
            selector="adults"
            updateCount={(selector, value) => updateCount(selector, value)}
            darkMode={darkMode}
          />
          <GuestSelectorInputRow
            title="Children"
            count={children}
            selector="children"
            updateCount={(selector, value) => updateCount(selector, value)}
            darkMode={darkMode}
          />
          <GuestSelectorInputRow
            title="Rooms"
            count={rooms}
            selector="rooms"
            updateCount={(selector, value) => updateCount(selector, value)}
            darkMode={darkMode}
          />
        </div>
      )}
    </div>
  );
};
