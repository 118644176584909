interface ICalloutImage {
  heading: string;
  imageURL: string;
}

const ImageCallout: React.FC<ICalloutImage> = ({ heading, imageURL }) => {
  return (
    <div className={`border-b-4 p-4 flex gap-2 justify-center rounded-2xl hover:scale-110 hover:opacity-90 hover:shadow-lg duration-500`}>
      <p className="text-peakahBlue">
        <img src={imageURL} alt={heading} width="300" />
      </p>
    </div>
  );
};

export default ImageCallout;
