export const Logo = ({ darkMode = true, small = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 174.18 30.07"
    className={`fill-current ${
      small ? "h-6 w-34 md:h-8 md:w-40" : "h-6 w-34 md:h-10 md:w-56 "
    } ${darkMode ? "text-white" : "text-peakahBlue"} `}
  >
    <path d="M4.23,6.63V8.52a8,8,0,0,1,2.5-1.75,7.31,7.31,0,0,1,2.88-.59,7.41,7.41,0,0,1,5.65,2.54,9.25,9.25,0,0,1,2.33,6.52,9,9,0,0,1-2.4,6.41,7.55,7.55,0,0,1-5.7,2.58,7,7,0,0,1-2.74-.53A8.36,8.36,0,0,1,4.23,22v8.09H0V6.63Zm4.49,3.53a4.45,4.45,0,0,0-3.35,1.39A5.05,5.05,0,0,0,4,15.18a5.19,5.19,0,0,0,1.33,3.7,4.41,4.41,0,0,0,3.35,1.41A4.31,4.31,0,0,0,12,18.85a5.18,5.18,0,0,0,1.33-3.66A5.13,5.13,0,0,0,12,11.57a4.34,4.34,0,0,0-3.31-1.41Z" />
    <path d="M38.16,16.45H24.45A4.6,4.6,0,0,0,26,19.36a5,5,0,0,0,3.29,1.09,5.66,5.66,0,0,0,4.11-1.69L37,20.46a8.44,8.44,0,0,1-3.21,2.84,10,10,0,0,1-4.46.93,8.68,8.68,0,0,1-9-8.29c0-.21,0-.43,0-.64a9,9,0,0,1,2.51-6.52,8.37,8.37,0,0,1,6.29-2.6,8.69,8.69,0,0,1,6.53,2.6,9.38,9.38,0,0,1,2.52,6.85ZM33.9,13.06a4.32,4.32,0,0,0-1.67-2.33,4.74,4.74,0,0,0-2.89-.9,5.05,5.05,0,0,0-3.13,1,5.61,5.61,0,0,0-1.56,2.22Z" />
    <path d="M54.16,6.63h4.27V23.79H54.16V22a8.27,8.27,0,0,1-2.5,1.72,6.88,6.88,0,0,1-2.73.53,7.52,7.52,0,0,1-5.7-2.58,9.08,9.08,0,0,1-2.4-6.41,9.27,9.27,0,0,1,2.33-6.51,7.35,7.35,0,0,1,5.65-2.54,7,7,0,0,1,2.87.58,7.91,7.91,0,0,1,2.49,1.75Zm-4.48,3.53a4.29,4.29,0,0,0-3.29,1.41,5.13,5.13,0,0,0-1.31,3.62,5.15,5.15,0,0,0,1.34,3.66,4.31,4.31,0,0,0,3.27,1.44A4.39,4.39,0,0,0,53,18.88a5.2,5.2,0,0,0,1.32-3.7A5,5,0,0,0,53,11.55a4.43,4.43,0,0,0-3.35-1.39Z" />
    <path d="M62.77,0H67V13.7l6.28-7.08h5.36l-7.32,8.23,8.2,8.94h-5.3L67,15.88v7.91H62.77Z" />
    <path
      className="text-peakahGreen fill-current"
      d="M93.86,6.63h4.26V23.79H93.86V22a8.19,8.19,0,0,1-2.51,1.72,6.87,6.87,0,0,1-2.72.53,7.51,7.51,0,0,1-5.7-2.58,9,9,0,0,1-2.4-6.41,9.26,9.26,0,0,1,2.32-6.51,7.35,7.35,0,0,1,5.66-2.54,7.12,7.12,0,0,1,2.87.58,8,8,0,0,1,2.48,1.75Zm-4.49,3.53a4.32,4.32,0,0,0-3.28,1.41,5.68,5.68,0,0,0,0,7.28,4.31,4.31,0,0,0,3.28,1.44,4.41,4.41,0,0,0,3.33-1.41,5.22,5.22,0,0,0,1.33-3.7,5,5,0,0,0-1.33-3.63,4.39,4.39,0,0,0-3.34-1.39Z"
    />
    <path
      className="text-peakahGreen fill-current"
      d="M102.45,0h4.24V8.36a9.84,9.84,0,0,1,2.51-1.63,6.52,6.52,0,0,1,2.57-.55A5.75,5.75,0,0,1,116,8a6.22,6.22,0,0,1,1.49,4.5V23.79h-4.2V16.26a18.77,18.77,0,0,0-.28-4,2.83,2.83,0,0,0-1-1.58,2.68,2.68,0,0,0-1.68-.52,3.09,3.09,0,0,0-2.23.87,4.59,4.59,0,0,0-1.29,2.36,19.92,19.92,0,0,0-.19,3.54v6.89h-4.23Z"
    />
    <path d="M122.63,20.64a1.72,1.72,0,0,1,1.72,1.72h0a1.75,1.75,0,0,1-.5,1.23,1.71,1.71,0,0,1-2.41,0l0,0a1.67,1.67,0,0,1-.5-1.23,1.71,1.71,0,0,1,1.71-1.72Z" />
    <path d="M138.92,14.2l-2.49,1.38a4.1,4.1,0,0,0-1.38-1,4.16,4.16,0,0,0-1.6-.29,3.73,3.73,0,0,0-2.71,1,3.49,3.49,0,0,0-1,2.59,3.36,3.36,0,0,0,1,2.5,3.62,3.62,0,0,0,2.62,1A3.79,3.79,0,0,0,136.44,20l2.36,1.63a7.21,7.21,0,0,1-10.16.78l-.16-.14a6.35,6.35,0,0,1-.92-7.61A6.15,6.15,0,0,1,130,12.3a7.23,7.23,0,0,1,3.47-.84,7.14,7.14,0,0,1,3.18.71A6.08,6.08,0,0,1,138.92,14.2Z" />
    <path d="M147.2,11.46a6.31,6.31,0,0,1,3.17.86,6.15,6.15,0,0,1,2.31,2.3,6.42,6.42,0,0,1,0,6.33,6,6,0,0,1-2.28,2.31,6.23,6.23,0,0,1-3.18.84,5.92,5.92,0,0,1-4.37-1.84,6.41,6.41,0,0,1,.13-9.07l.1-.09A6,6,0,0,1,147.2,11.46Zm.05,2.84a3.08,3.08,0,0,0-2.32,1,3.51,3.51,0,0,0-.93,2.5,3.55,3.55,0,0,0,.92,2.54,3.26,3.26,0,0,0,4.65,0,3.89,3.89,0,0,0,0-5,3.1,3.1,0,0,0-2.34-1Z" />
    <path d="M155.93,11.77h3v1.38a5.2,5.2,0,0,1,1.7-1.27,5.1,5.1,0,0,1,2-.42,3.74,3.74,0,0,1,2,.56,4,4,0,0,1,1.45,1.61A4.8,4.8,0,0,1,167.86,12a4.85,4.85,0,0,1,2.26-.56,4.28,4.28,0,0,1,2.24.6,3.48,3.48,0,0,1,1.38,1.56,8.32,8.32,0,0,1,.42,3.13v7h-3v-6.1a5.34,5.34,0,0,0-.5-2.76,1.72,1.72,0,0,0-1.51-.73,2.34,2.34,0,0,0-1.38.44,2.73,2.73,0,0,0-.9,1.22,8.16,8.16,0,0,0-.3,2.5v5.43h-3V18a8.35,8.35,0,0,0-.24-2.34,2.11,2.11,0,0,0-.71-1.07,1.8,1.8,0,0,0-1.14-.36,2.26,2.26,0,0,0-1.35.45,2.64,2.64,0,0,0-.91,1.25,7.83,7.83,0,0,0-.3,2.54v5.35h-3Z" />
  </svg>
);
