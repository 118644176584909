import { useState } from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import TagManager from "react-gtm-module";
import { Home } from "./views/Home";
import { Search } from "./views/Search";
import { ScrollToTop } from "./components/ScrollToTop";
import { LocationPage } from "./components/LocationPage";
// Context
import { SearchContext } from "./context/SearchContext/SearchContext";
import { SearchState } from "./context/SearchContext/SearchState";
import { FilterContext } from "./context/FilterContext/FilterContext";
import { FilterState } from "./context/FilterContext/FilterState";
import ContentPage from "./views/ContentPage";
import { Contact } from "./components/Content/Contact.js";
import NFTPage from "./views/NFTPage";
import CityPage from "./views/CityPage";
import SitemapHTML from "./views/Sitemap";
import { ArticleGallery } from "./views/ArticleGallery";
import { ArticleContentPage } from "./views/ArticleContentPage";
import { UrlContext } from "./context/UrlContext/UrlContext";
import { SiteParams } from "./config/constants/SiteParams";
import { DarkModeProvider } from "./context/DarkModeContext/DarkModeContext";

FilterContext.displayName = "FilterContext";
SearchContext.displayName = "SearchContext";
UrlContext.displayName = "UrlContext";

require("typeface-poppins");

const tagManagerArgs = {
  gtmId: "GTM-T6D5CB7",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const route = useLocation();
  const URINoSlash = route.pathname.slice(1);
  const URI = URINoSlash.split("/")[0];
  const targetSite  = SiteParams.find(obj => obj.URI === URI.toLowerCase())
  const [urlContext, setUrlContext] = useState(targetSite?.url || "/");
  const [filterContext, setFilterContext] = useState(FilterState);  
  const [searchContext, setSearchContext] = useState(SearchState(targetSite?.code || "AUS"));
  
  return (
    <>
      <ScrollToTop />
      <div className="font-body bg-veryLightBlue">
        <UrlContext.Provider value={[urlContext, setUrlContext]}>
          <SearchContext.Provider value={[searchContext, setSearchContext]}>
            <FilterContext.Provider value={[filterContext, setFilterContext]}>
              <DarkModeProvider>
                <Switch>
                  <Route exact path={["/", "/us"]} component={Home} />
                  <Route exact path={["/search", "/us/search"]} component={Search} />
                  <Route
                    exact
                    path="/contact"
                    component={() => (
                      <ContentPage>
                        <Contact />
                      </ContentPage>
                    )}
                  />
                  <Route exact path={["/staycations/:locationId", "/us/staycations/:locationId"]} component={LocationPage} />
                  <Route path="/cities/:cityId" component={CityPage} />
                  <Route exact path="/sitemap" component={SitemapHTML} />
                  <Route exact path={["/web3", "/us/web3"]} component={NFTPage} />
                  <Route exact path={["/articles", "/us/articles"]} component={ArticleGallery} />
                  <Route exact path={["/articles/:articleId", "/us/articles/:articleId"]} component={ArticleContentPage} />
                  <Route path="/:slug" component={ContentPage} />
                </Switch>
              </DarkModeProvider>
            </FilterContext.Provider>
          </SearchContext.Provider>
        </UrlContext.Provider>
      </div>
    </>
  );
};

export default App;
